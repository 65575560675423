import react, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import {
  DataGrid,
  GridColDef,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton,
  GridValueGetterParams,
} from "@mui/x-data-grid";
import styled from "styled-components";
import Drawer from "../../components/common/drawer/drawer";
import { Typography, Button, Checkbox, Fab } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import { ERROR_COLOR, ROLES } from "../../constants";
import { Add, Save, SaveAlt } from "@mui/icons-material";
import AddNewRole from "../../components/modals/AddNewRole"
import OpaqueLoading from "../../components/opaqueLoading/opaqueLoading";

const isMobile = window.innerWidth < 900;

const StaffEditorPageContainer = styled.div`
  height: ${isMobile ? `auto` : `calc(100vh - 105px)`};
  width: 100%;
  background-color: white;
  padding: 10px 15px;
  ${isMobile &&
    `
      position: relative;
  
      `}
`;

const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: ${isMobile ? "flex-start" : "space-between"};
  margin-bottom: 10px;
  flex-direction: ${isMobile ? "column" : "row"};
`;

const HeaderButtonsContainer = styled.div`
  ${isMobile && "margin-top:10px;"}
`;

const TableContainer = styled.div`
  // height: calc(
  //   100vh - ${isMobile ? "56px - 20px - 82.23px" : "105px - 20px - 43.77px"}
  // );
  width: 100%;
  border: solid 1px lightGrey;
  border-radius: 8px;
`;
const AvatarContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const DataGridContainer = styled.div`
  max-width: 100%; /* Add this line to limit the width */
  margin: 0 auto; /* Center the content horizontally */
  height: 100%;
  width: 100%;
  // border: solid 1px lightGrey;
  display: ${isMobile ? 'flex' : 'grid'};

  ${isMobile &&
  `
    flex-direction: column;
  `}
`;

const LoadingContainer = styled.div`
  height: calc(100% - 49px);
  display: flex;
  justify-content: center;
  align-items: center;
`;

const TabsContainer = styled.div`
  ${isMobile &&
  `
    height: calc(100vh - 56px - 20px);
  `}
`;

const GenerateReportButton = styled(Button)`
  margin-right: 10px !important;
`;
const BulkChangePinCodeButton = styled(Button)`
  margin-right: 10px !important;
`;

const PinCodeChangeButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const FabStyle = styled(Fab)`
  && {
    z-index: 1};
  }
`;

const StyledHeader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export default function RoleManagement() {
  const label = { inputProps: { "aria-label": "Checkbox demo" } };

  const [loading, setLoading] = useState(false);
  const [accessChanges, setAccessChanges] = useState("");
  const [newRole, setNewRole] = useState(false)
const [rows, setRows] = useState([])
const [modules, setModules] = useState()

const onSubmitData = async() => {
  try{
    setLoading(true)
    // console.log("PLANTCODE",plantcode[0].plant_code__c)
    let results = await window.Platform.database.updateRoleAccess(rows);
    console.log("PRODUCT RESULTS: " ,rows)
    
    window.NotificationUtils.showSuccess("Role access updated successfully");
// setProduct(results.data)
// setRows(results.data)
// setModules(results?.moduleData?.results)

setLoading(false)
  }
  catch(err){
    console.log(err);
    window.NotificationUtils.showError("Something went wrong");
setLoading(false)
 
  }
}
  // const rows = [
  //   {
  //     id: 1,
  //     role: "Admin",
      
      
      
  //   },
  //   {
  //     id: 2,
  //     role: "SE",
      
    
  //   },
  //   {
  //     id: 3,
  //     role: "RSM",


  //   },
  //   {
  //     id: 4,
  //     role: "IT",
    
  //   },
  // ];

  
  const fetchRole =async()=>{
    try{
      setLoading(true)
      // console.log("PLANTCODE",plantcode[0].plant_code__c)
      let results = await window.Platform.database.fetchRoles();
  console.log("PRODUCT RESULTS: " ,results.data)
  
  // setProduct(results.data)
  setRows(results.data)
  setModules(results?.moduleData?.results)

  setLoading(false)
    }
    catch(err){
      console.log(err);
  setLoading(false)
   
    }
  }
  
  useEffect(()=>{
    console.log("VALUEs result", rows)
  },[rows])
  useEffect(()=>{
    fetchRole()
  },[])
  useEffect(() => {
    setAccessChanges(rows);
  }, []);
  useEffect(() => {
    console.log("checking...", accessChanges);
  }, [accessChanges]);
  const getEmployeeColumns = () => {
    let result = [
      // { field: 'id', headerName: 'ID', width: 90 },
      {
        field: "roleName",
        headerName: "Roles",
        sortable: false,
        width: 150,
        valueGetter: (params) => params.row.roleName || 'N/A',
        renderCell: (params) => {
          const value = params.row.roleName || 'N/A';
          return <Tooltip title={value}>{value}</Tooltip>;
        }
      },
      ...((modules || []).map((module,index) => ({
        field: `component_${module?.moduleId}`,
        headerName: module?.moduleName,
        width: 200,
        headerAlign: 'center',
        sortable: false,
        renderCell: (params) =>{ 
          let check = false;
          const inputString = params.row.roleModuleId;
          const resultArray = inputString.split(',');
          
          console.log("moduleidcheck",resultArray)
if(resultArray.includes(module?.moduleId))
{
  console.log("mmmm",resultArray, "checked")
  check =true
}
         return  (
          
          <>
            <div style={{ margin: "0 auto", width: "auto" }}>
              <Checkbox
                defaultChecked={check}
                // disabled={!params.row.changeable}
                // checked={check}
                onClick={(event) => {
                  // Your checkbox click handling logic
                  
                  console.log("check check",event.target.checked);
                  const inputString = params.row.roleModuleId;
                  const resultArray = inputString.split(',');
                  
                  // if(event.target.checked === true)
                  // {
                  // resultArray.push(module?.moduleId)
                  // let val = {...params.row, roleModuleId: resultArray[0] ? resultArray.join(',') : resultArray.join('')}}
                  // else{
                  
                  // }
                  if (event.target.checked === true) {
                    // If checkbox is checked, add the module ID to resultArray
                    resultArray.push(module?.moduleId);
                  } else {
                    // If checkbox is unchecked, remove the module ID from resultArray
                    const indexToRemove = resultArray.indexOf(module?.moduleId);
                    if (indexToRemove !== -1) {
                      resultArray.splice(indexToRemove, 1);
                    }
                  }
                  
                  // Update the state with the new value
                  let val = { ...params.row, roleModuleId: resultArray[0] ? resultArray.join(',') : resultArray.join('') };
                  rows.map((row, i) =>{
                    // console.log(rows[i])
                    

                    // const index = rows.indexOf(row);
                    // console.log(i, row)
                    if(params.row.roleId === row.roleId )
                    {
                    const newArray = [...rows];
                    newArray[i] = val;
                console.log("check newArray", newArray)
                setRows(newArray);
                    }
                    
                  
                  
                  // console.log(rows,{...params.row, roleModuleId: resultArray[0] ? resultArray.join(',') : resultArray.join('')})
                  })   }}
              />
              {/* <Typography>View</Typography> */}
            </div>
          </>
        )},
      })))
      

    ];
    result.push({
      field:"  ",
      headerName: 'Actions',
      headerAlign: 'center',
      editable: false,
      minWidth:200,
      disableColumnMenu:true,
      disableExport:true,
      disableReorder:true,
      hideSortIcons:true,
      renderCell: (params) => {
        return (
          // <PinCodeChangeButtonContainer>
          <div style={{margin: '0 auto', width:'auto'}}>
  
          
          <Tooltip title="Click to Edit">
  
            <FabStyle variant="extended" color="primary" 
             onClick={() => setNewRole(params.row)}
              //  onClick={() => {
                 
              //    window.NotificationUtils.showSuccess(
              //      "Changes successfully made"
              //      );
              //     }}
                   >
    {/* <Send  /> */}
  EDIT
  </FabStyle>
          </Tooltip>
                     
              </div>
  
          // </PinCodeChangeButtonContainer>
        );
  }
  })
return result
}
    

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
        <GridToolbarExport
          csvOptions={{
            fileName: "fos-list",
          }}
        />
      </GridToolbarContainer>
    );
  }

  const onClose = () => {
    fetchRole()
    setNewRole(false);
  }

  const discardModal = () => {
    setNewRole(false);
  }

  const getRowId = (row) => row.roleId; // Assuming _id is the unique identifier

  const renderContent = () => {
    return (

      <DataGridContainer style={{ height: !rows.length ? '200px' : 'auto' }}>

        <DataGrid
          className="payrollGrid"
          checkboxSelection={true}
          rows={rows || []}
          getRowId={getRowId}
// onRowClick={(val)=> setNewRole(val.row)}
          columns={getEmployeeColumns() || []}
          rowHeight={60}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 10,
              },
            },
          }}
          pageSizeOptions={[10]}
          disableSelectionOnClick
          disableRowSelectionOnClick
          disableDensitySelector
          // autoPageSize
          components={{
            Toolbar: CustomToolbar,
          }}
        />
      </DataGridContainer>
    );
  };

  return (
    <>
    
      <StaffEditorPageContainer>
      {loading && <OpaqueLoading/>}

       { newRole &&  <AddNewRole onCancel={()=>setNewRole(false)} discardModal={discardModal} setLoading={setLoading} rows ={rows} newRole = {newRole} fetchRoles={fetchRole}/>}
        <HeaderContainer>
          <Typography variant="h5"></Typography>

          <div style={{display: "flex"}}>
            {/* <FabStyle> */}
            <Button variant="contained" color="primary" 
             onClick={() => {
                 console.log(accessChanges);
                 setNewRole(true)
                 
                    }} sx={{ mr: 1, borderRadius: '10px' }}>
  <Add sx={{ mr: 1 }}  />
  Add New Role
</Button>

            <Button variant="contained" color="primary" 
             onClick={() => onSubmitData()} sx={{ borderRadius: '10px' }}>
  <SaveAlt sx={{ mr: 1 }} />
  Save Changes
</Button>
                  {/* </FabStyle> */}
            {/* )} */}
          </div>
        </HeaderContainer>
        <TableContainer>{renderContent()}</TableContainer>
      </StaffEditorPageContainer>
    </>
  );
}

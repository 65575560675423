import { useEffect, useState } from "react";
import {
  DataGrid,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarFilterButton,
  getGridStringOperators,
} from "@mui/x-data-grid";
import styled from "styled-components";
import {
  Typography,
  Button,
  Stack,
  FormControl,
  Autocomplete,
  TextField,
} from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import OpaqueLoading from "../../components/opaqueLoading/opaqueLoading";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

const isMobile = window.innerWidth < 900;

const StaffEditorPageContainer = styled.div`
  height: ${isMobile ? `auto` : `calc(100vh - 105px)`};
  width: 100%;
  background-color: white;
  padding: 10px 15px;
  ${isMobile &&
  `
    position: relative;
  `}
`;

const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: ${isMobile ? "flex-start" : "space-between"};
  margin-bottom: 10px;
  flex-direction: ${isMobile ? "column" : "row"};
`;

const TableContainer = styled.div`
  width: 100%;
  border: solid 1px lightGrey;
  border-radius: 8px;
`;

const DataGridContainer = styled.div`
  max-width: 100%;
  margin: 0 auto;
  height: 100%;
  width: 100%;
  border-radius: 8px;
  display: ${isMobile ? "flex" : "grid"};

  ${isMobile &&
  `
    flex-direction: column;
  `}
`;

export default function PipelineReport() {
  const [loading, setLoading] = useState(false);
  const [accessChanges, setAccessChanges] = useState([]);

  const [selectedTab, setSelectedTab] = useState('Series');
  const [filter, setFilter] = useState(false);
  const [filters, setFilters] = useState(['Series', 'State', 'Board', 'Medium']);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [dateRange1, setDateRange1] = useState(false);

  const [startDate1, setStartDate1] = useState();
  const [endDate1, setEndDate1] = useState();
  const [minDate, setMinDate] = useState();

  useEffect(() => {
    setSelectedTab('Series');
    fetchPipelineReport();
  }, []);

  useEffect(() => {
    if (selectedTab) {
      fetchPipelineReport(selectedTab);
    } else {
      setAccessChanges([]);
    }
  }, [selectedTab])

  const finalDateRangeFilter = async (data) => {
    try {
      let endpoint;
      switch (selectedTab) {
        case 'Series':
          endpoint = "fetchPipelineReportBySeries";
          break;
        case 'State':
          endpoint = "fetchPipelineReportByState";
          break;
        case 'Board':
          endpoint = "fetchPipelineReportByBoard";
          break;
        case 'Medium':
          endpoint = "fetchPipelineReportByMedium";
          break;
        default:
          endpoint = "fetchPipelineReportBySeries";
      }

      setLoading(true);
      setFilter(true);

      const datePickerResponse = new Date(data);

      const year = datePickerResponse.getFullYear();
      const month = String(datePickerResponse.getMonth() + 1).padStart(2, "0");
      const day = String(datePickerResponse.getDate()).padStart(2, "0");
      const formattedDate = `${year}-${month}-${day}`;

      if (data) {
        setEndDate(formattedDate);
        setEndDate1(formattedDate);
      }

      const response = await window.Platform.database.getPipelineReport(
        endpoint,
        {
          startDate: startDate,
          endDate: data ? formattedDate : endDate,
        }
      );
      const jsonArrayWithId = response?.data?.map((obj, index) => ({
        ...obj,
        id: index + 1,
      }));
      setAccessChanges(jsonArrayWithId);
      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
      window.NotificationUtils.showError(
        "Error While Recieving Data Please Wait and try again"
      );

      fetchPipelineReport();
    }
  };

  const formatDate = async (data) => {
    setMinDate(data);
    setDateRange1(true);
    setEndDate1(null);
    setEndDate(null);
    const datePickerResponse = new Date(data.$d);

    const year = datePickerResponse.getFullYear();
    const month = String(datePickerResponse.getMonth() + 1).padStart(2, "0");
    const day = String(datePickerResponse.getDate()).padStart(2, "0");
    const formattedDate = `${year}-${month}-${day}`;

    setStartDate1(formattedDate);
    setStartDate(formattedDate);
  };

  const clearDateFilter = async () => {
    setStartDate(null);
    setEndDate(null);
    setStartDate1(null);
    setEndDate1(null);
    setDateRange1(false);
    setFilter(false);
    setSelectedTab('Series');
    fetchPipelineReport();
  };

  const handleTabChange = async (value) => {
    setSelectedTab(value);
    setStartDate(null);
    setEndDate(null);
    setStartDate1(null);
    setEndDate1(null);
    setDateRange1(false);
    setFilter(false);
  };

  const downloadCSV = () => {
    const csvRows = [];
    let headers = [];

    switch (selectedTab) {
      case 'Series':
        headers = ["Series Name", "Total Tentative Amount"];
        break;
      case 'State':
        headers = ["State Name", "Total Tentative Amount"];
        break;
      case 'Board':
        headers = ["Board Name", "Total Tentative Amount"];
        break;
      case 'Medium':
        headers = ["Medium Name", "Total Tentative Amount"];
        break;
      default:
        headers = ["Series Name", "Total Tentative Amount"];
        break;
    }

    csvRows.push(headers.join(","));

    switch (selectedTab) {
      case 'Series':
        accessChanges.forEach((item) => {
          const row = [
            item.seriesName || "",
            item.total_tentative_amount
          ];
          csvRows.push(row.join(","));
        });
        break;
      case 'State':
        accessChanges.forEach((item) => {
          const row = [
            item.state_name || "",
            item.total_tentative_amount
          ];
          csvRows.push(row.join(","));
        });
        break;
      case 'Board':
        accessChanges.forEach((item) => {
          const row = [
            item.board_name || "",
            item.total_tentative_amount
          ];
          csvRows.push(row.join(","));
        });
        break;
      case 'Medium':
        accessChanges.forEach((item) => {
          const row = [
            item.medium_name || "",
            item.total_tentative_amount
          ];
          csvRows.push(row.join(","));
        });
        break;
    }

    const csvString = csvRows.join("\n");
    const blob = new Blob([csvString], { type: "text/csv" });
    const url = window.URL.createObjectURL(blob);

    const a = document.createElement("a");
    a.setAttribute("href", url);
    a.setAttribute("download", `${selectedTab}.csv`);
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  const fetchPipelineReport = async (value) => {
    try {
      setLoading(true);
      let results;

      switch (value) {
        case 'Series':
          results = await window.Platform.database.getForecastReport(
            "fetchPipelineReportBySeries"
          );;
          break;
        case 'State':
          results = await window.Platform.database.getForecastReport(
            "fetchPipelineReportByState"
          );
          break;
        case 'Board':
          results = await window.Platform.database.getForecastReport(
            "fetchPipelineReportByBoard"
          );
          break;
        case 'Medium':
          results = await window.Platform.database.getForecastReport(
            "fetchPipelineReportByMedium"
          );;
          break;
        default:
          results = await window.Platform.database.getForecastReport(
            "fetchPipelineReportBySeries"
          );
      }

      const jsonArrayWithId = results?.data?.map((obj, index) => ({
        ...obj,
        id: index + 1,
      }));
      setAccessChanges(jsonArrayWithId);
      setLoading(false);
    } catch (error) {
      console.error(error);
      window.NotificationUtils.showError("Error Fetching Data");
      setLoading(false);
    }
  };

  const getColumns = () => {
    switch (selectedTab) {
      case 'Series':
        return getSeriesColumns();
      case 'State':
        return getStateColumns();
      case 'Board':
        return getBoardColumns();
      case 'Medium':
        return getMediumColumns();
      default:
        return getSeriesColumns();
    }
  }

  const getSeriesColumns = () => {
    const stringOperators = getGridStringOperators().filter((op) =>
      ["contains"].includes(op.value)
    );
    let result = [
      {
        field: "seriesName",
        headerName: "Series Name",
        sortable: false,
        width: 150,
        editable: false,
        filterOperators: stringOperators,
        renderCell: (params) => {
          let name = params.row?.seriesName || "N/A";
          return (
            <Tooltip title={name || "N/A"}>
              <Typography variant="inherit">{name}</Typography>
            </Tooltip>
          );
        },
      },
      {
        field: "totalTentativeAmount",
        headerName: "Total Tentative Amount",
        width: 250,
        sortable: false,
        filterable: false,
        renderCell: (params) => {
          let name = params.row.total_tentative_amount;
          return (
            <Tooltip title={name}>
              <Typography variant="inherit">{name}</Typography>
            </Tooltip>
          );
        },
      }
    ];
    return result;
  };

  const getStateColumns = () => {
    const stringOperators = getGridStringOperators().filter((op) =>
      ["contains"].includes(op.value)
    );
    let result = [
      {
        field: "stateName",
        headerName: "State Name",
        sortable: false,
        width: 150,
        editable: false,
        filterOperators: stringOperators,
        renderCell: (params) => {
          let name = params.row?.state_name || "N/A";
          return (
            <Tooltip title={name || "N/A"}>
              <Typography variant="inherit">{name}</Typography>
            </Tooltip>
          );
        },
      },
      {
        field: "totalTentativeAmount",
        headerName: "Total Tentative Amount",
        width: 250,
        sortable: false,
        filterable: false,
        renderCell: (params) => {
          let name = params.row.total_tentative_amount;
          return (
            <Tooltip title={name}>
              <Typography variant="inherit">{name}</Typography>
            </Tooltip>
          );
        },
      }
    ];
    return result;
  };

  const getBoardColumns = () => {
    const stringOperators = getGridStringOperators().filter((op) =>
      ["contains"].includes(op.value)
    );
    let result = [
      {
        field: "boardName",
        headerName: "Board Name",
        sortable: false,
        width: 150,
        editable: false,
        filterOperators: stringOperators,
        renderCell: (params) => {
          let name = params.row?.board_name || "N/A";
          return (
            <Tooltip title={name || "N/A"}>
              <Typography variant="inherit">{name}</Typography>
            </Tooltip>
          );
        },
      },
      {
        field: "totalTentativeAmount",
        headerName: "Total Tentative Amount",
        width: 250,
        sortable: false,
        filterable: false,
        renderCell: (params) => {
          let name = params.row.total_tentative_amount;
          return (
            <Tooltip title={name}>
              <Typography variant="inherit">{name}</Typography>
            </Tooltip>
          );
        },
      }
    ];
    return result;
  };

  const getMediumColumns = () => {
    const stringOperators = getGridStringOperators().filter((op) =>
      ["contains"].includes(op.value)
    );
    let result = [
      {
        field: "mediumName",
        headerName: "Medium Name",
        sortable: false,
        width: 150,
        editable: false,
        filterOperators: stringOperators,
        renderCell: (params) => {
          let name = params.row?.medium_name || "N/A";
          return (
            <Tooltip title={name || "N/A"}>
              <Typography variant="inherit">{name}</Typography>
            </Tooltip>
          );
        },
      },
      {
        field: "totalTentativeAmount",
        headerName: "Total Tentative Amount",
        width: 250,
        sortable: false,
        filterable: false,
        renderCell: (params) => {
          let name = params.row.total_tentative_amount;
          return (
            <Tooltip title={name}>
              <Typography variant="inherit">{name}</Typography>
            </Tooltip>
          );
        },
      }
    ];
    return result;
  };

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
      </GridToolbarContainer>
    );
  }

  return (
    <>
      <StaffEditorPageContainer>
        <HeaderContainer>
          {loading && <OpaqueLoading />}
          <div
            style={{
              width: "60%",
              display: "flex",
              flexDirection: "row",
              gap: "10px",
            }}
          >
            <FormControl style={{ minWidth: 150 }}>
              <Autocomplete
                id="filter"
                disablePortal
                value={selectedTab}
                onChange={(event, newValue) => handleTabChange(newValue)}
                options={filters || []}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Select Filter"
                    variant="outlined"
                    fullWidth
                  />
                )}
              />
            </FormControl>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label="Start Date"
                value={startDate1}
                disabled={loading}
                format="YYYY/MM/DD"
                onChange={(data) => formatDate(data)}
                disableFuture={true}
              />
            </LocalizationProvider>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label="End Date"
                minDate={minDate}
                value={endDate1}
                disabled={!dateRange1 || loading}
                format="YYYY/MM/DD"
                onChange={(data) => finalDateRangeFilter(data.$d)}
                disableFuture={true}
              />
            </LocalizationProvider>
            <Button
              variant="text"
              onClick={() => clearDateFilter()}
              disabled={!dateRange1 || loading}
            >
              Clear
            </Button>
          </div>
          <Stack spacing={2} direction={"row"}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => downloadCSV()}
              sx={{ mr: 1, borderRadius: "10px" }}
            >
              Export
            </Button>
          </Stack>
        </HeaderContainer>
        <TableContainer>
          <DataGridContainer
            style={{ height: !accessChanges?.length ? "200px" : "auto" }}
          >
            <DataGrid
              className="payrollGrid"
              density="compact"
              rows={accessChanges}
              columns={getColumns()}
              disableSelectionOnClick
              disableRowSelectionOnClick
              rowHeight={60}
              disableDensitySelector
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 10,
                  },
                },
              }}
              pageSizeOptions={[10]}
              filterMode="server"
              components={{
                Toolbar: CustomToolbar,
              }}
            />
          </DataGridContainer>
        </TableContainer>
      </StaffEditorPageContainer>
    </>
  );
}

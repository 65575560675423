import React, { useState } from "react";
import {
  Autocomplete,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogContent,
  FormControl,
  FormControlLabel,
  FormGroup,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { CloseOutlined } from "@mui/icons-material";
import { Stack } from "@mui/material";
import styled from "styled-components";
import { ACTIVITY_STATUS } from "../../constants";
import OpaqueLoading from "../opaqueLoading/opaqueLoading";
import { useEffect } from "react";

const StyledDialogContent = styled(DialogContent)`
  padding: 16px;
`;

const StyledDialog = styled(Dialog)`
  // position: relative;
  // max-width: 700px;
  
`;  

  const ModalHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
    `;
  
  
  const SpecimenModal = ({ onClose,status,fetchData,propData }) => {
    console.log("propData",propData)
    const [errors, setErrors] = useState({});
    const [form, setForm] = useState( {id:propData?.specimenId||"",
        
     itemId:propData?.skuId||"",
     discountedPrice: propData?.unitPrice || 0,
     mrp:propData?.Mrp||""
    });
  const [loading,setLoading]=useState(false)


    const [product,setProduct]=useState()
    const [selectedProduct,setSelectedProduct]=useState(null)

    useEffect(()=>{
        console.log(loading)
        fetchProduct()
      },[])

      const fetchProduct=async(data)=>{
        const results = await window.Platform.database.fetchProduct(data);
        const jsonArrayWithId = results?.data?.map((obj, index) => ({ ...obj, id: index + 1 }));
    
        setProduct(jsonArrayWithId)
        if (!propData.itemId) {
          setSelectedProduct(null)
        } else {
          setSelectedProduct(propData?.skuId)
        }
        if(!status){
            const filteredEmp=results?.data?.filter((data)=>data.skuId===propData.skuId)
            console.log("PROPDATA",filteredEmp)
            setSelectedProduct(filteredEmp[0])
            
           }
    
       }
  
    const changeHandler = (fieldName, value) => {
      setForm((prevForm) => ({ ...prevForm, [fieldName]: [value] }));
    };

    const validateForm = (data) => {
      const errors = {};
   
      if (!data.itemId?.toString()?.trim()) {
        errors.itemId = "SKU is required";
      }
        
      return errors;
    };

    const submitHandler = async() => {
      // console.log("creating activity...", form);
      try{

        const errors = validateForm(form);
        setErrors(errors);

        if (Object.keys(errors).length === 0) {
          console.log("PRODUCT RESULTS: " , form)
          setLoading(true)
          // console.log("PLANTCODE",plantcode[0].plant_code__c)
          let results = await window.Platform.database.addSpecimen({...form});
      
          // setProduct(results.data)
          window.NotificationUtils.showSuccess("Specimen added successfully")
          setLoading(false)
          fetchData()
          onClose()
        } else {
          window.NotificationUtils.showError("Please fill all required fields.")
          setLoading(false)
        }
      }
      catch(err){
        console.log(err);
    setLoading(false)
     
      }
console.log(form)
    };

    console.log("FORMMMMM",form)

  
    return (
      <StyledDialog disableEnforceFocus maxWidth="sm" fullWidth={true} open={true}>
      <StyledDialogContent>
        <ModalHeaderContainer>
          <Typography variant="h5">{status?"Add Specimen":"Edit Specimen"}</Typography>
          <IconButton  onClick={onClose}>
            <CloseOutlined />
          </IconButton>
        </ModalHeaderContainer>
        {/* <DialogContent style={{ padding: "10px", width: 400 }}> */}
          <Stack gap="20px" style={{ padding: "10px" }}>
            {loading && <OpaqueLoading />}
           

            <Stack spacing={2} padding={1}>
            <Autocomplete
          options={product}
          // getOptionLabel={}
          // getOptionLabel={(option) => `${option?.nameSku} (${option?.skuId})`}
          getOptionLabel={(option) => `${option?.nameSku}`}
            getOptionValue={(option) => option?.skuId || ''}
          style={{ width: "100%" }}
          value={selectedProduct}
          // disabled={loading 
            // || !addNewMode || props.isManager
          // }
          noOptionsText={loading ? "Loading..." : "No option"}
            onChange={(event, value) => {
              console.log("TEST1212",value)
              setSelectedProduct(value)
              changeHandler("itemId",value?.skuId)
              changeHandler("mrp",value?.unitPrice)

              //  updateDataGrid(value);
              
              // if(selectedCity.warehouseCountry === 'India' && warehouseData.attributes.warehouseCountry === 'China')
              // {
              //     setDisableTransfer(false)
              // }
              // else
              // setDisableTransfer(true)

             
              
            }}
            onInputChange={(e, value) => {
              // Check if the user's input matches any option
             
            }}
          renderInput={(params) => (
            <TextField
              {...params}
              // disabled={
              //   loading 
                // || !addNewMode || props.isManager || props.isDeleted
              // }
              label={"Choose SKU"}
              required
              variant="outlined"
              error={errors.itemId ? true : false}
            />
          )}/>
              
            </Stack>
    <Stack direction={"row"} spacing={2} padding={1}>
          
<TextField label="MRP" disabled  value={selectedProduct?.unitPrice||""} type="text" fullWidth  onChange={(e)=>changeHandler("mrp",e.target.value)}/>
<TextField label="Discounted Price"  value={form.discountedPrice|| 0} type="text" fullWidth onChange={(e)=>changeHandler("discountedPrice",e.target.value)} />
           </Stack>
           <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>

              <Button
                color="primary"
                variant="contained"
                onClick={submitHandler}
              >
                Create
              </Button>
            </div>
          </Stack>
          </StyledDialogContent>
    </StyledDialog>
    );
  };
  
  export default SpecimenModal;
  
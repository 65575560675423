import React, { useEffect, useState } from "react";
import styled from "styled-components";
// import OpaqueLoading from "../../components/opaqueLoading/OpaqueLoading";
import OpaqueLoading from "../../components/opaqueLoading/opaqueLoading";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CircularProgress from "@mui/material/CircularProgress";

import { ROLES } from "../../constants";

  const StyledCard = styled(Card)`
    position: relative;
    width: ${window.innerWidth<500 ? "100%":"500px"};
  `;
  
  const StyledCardContent = styled(CardContent)`
    padding: 16px !important;
  `;
  
  const PageContainer = styled.div`
    height: 100vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color:white;
    flex-direction:column;
  `;
  
  const FieldsContainer = styled.div``;
  
  const CardHeaderContainer = styled.div`
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  `;
  const InputField = styled(TextField)`
    margin-bottom: 10px !important;
  `;
  
  const OrganisationHeading = styled(Typography)`
    font-weight: 500;
  `;
  
  const RoleHeading = styled(Typography)`
    font-weight: 500;
    text-transform: none;
  `;
  const ActionButtonsContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
  `;
  
  const StyledImage = styled.img`
    height:120px;
    position:absolute;
    top:30px;
  `
  
  const Invite = (props) => {
    const [loading, setLoading] = useState(true);
    const [inviteDetails, setInviteDetails] = useState(null);
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [registering, setRegistering] = useState(false);
  
    const getInviteId = () => {
      let queryParams = "";
      try {
        const url = new URL(window.location.href);
        const queryParams = url.search.toString().slice(1);
        // const queryParams = searchParams.get("id");
        // return id;
        return queryParams;
        // }
        // console.log(queryParams)

      } catch (e) {
        console.error(e);
      }
      // console.log(queryParams)
     
    };
    const inviteId = getInviteId();
  
    useEffect(async () => {
      console.log("inviteID", inviteId)
      setLoading(true);
      if (inviteId) {
        let results = await window.Platform.database.getInviteData(inviteId);
        if (!results || [ROLES.BD_FOS, ROLES.RETENTION_FOS, ROLES.FOS_SPECIAL].includes(results.role)) {
          window.NotificationUtils.showError("Invalid or expired invite link");
          routeToLogin();
        }
        console.log("results", results)
        setInviteDetails(results);
        setLoading(false);
      } else {
        routeToLogin();
      }
    }, []);
  
    const routeToLogin = () => {
      props.history.replace("/login");
      // window.location.href = "/login";
    };
  
    const renderLoading = () => {
      return (
        <div className="loadingCentered">
          <CircularProgress color="primary" />
        </div>
      );
    };
  
    const checkErrors = () => {
      if (!password || !confirmPassword) {
        window.NotificationUtils.showError(
          "Please enter and confirm the password"
        );
        return true;
      }
      if (password !== confirmPassword) {
        window.NotificationUtils.showError("Passwords do not match");
        return true;
      }
      return false;
    };
  
    const onClickRegister = async () => {
      if (checkErrors()) {
        return;
      }
      try {
        setRegistering(true);
        let data = {
          ...inviteDetails,
          password: password,
        };
        // console.log(data)
        await window.Platform.database.confirmMemberInvite(data);
        window.NotificationUtils.showSuccess("Successfully registered");
        setRegistering(false);
        routeToLogin();
      } catch (error) {
        window.NotificationUtils.showError("Error trying to register");
        setRegistering(false);
      }
      // console.log(inviteDetails)
    };
  
    const renderContent = () => {
      return (
        <PageContainer>
          <StyledImage src="logo1.png" />
          <StyledCard raised>
            {registering && <OpaqueLoading />}
            <StyledCardContent>
              <CardHeaderContainer>
                <OrganisationHeading variant="h5">
                  Maxx
                </OrganisationHeading>
                <RoleHeading variant="h6">Role: {inviteDetails?.role || ''}</RoleHeading>
              </CardHeaderContainer>
              <FieldsContainer>
                <InputField
                  value={inviteDetails?.email || ""}
                  disabled
                  fullWidth
                  variant="outlined"
                  label="Email"
                  required
                />
                <InputField
                  value={password || ""}
                  type="password"
                  fullWidth
                  onChange={(e) => setPassword(e.target.value)}
                  variant="outlined"
                  label="Password"
                  required
                  disabled={registering}
                />
                <InputField
                  value={confirmPassword || ""}
                  type="password"
                  fullWidth
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  variant="outlined"
                  label="Confirm Password"
                  required
                  disabled={registering}
                />
              </FieldsContainer>
              <ActionButtonsContainer>
                <Button
                  disabled={registering}
                  onClick={onClickRegister}
                  variant="contained"
                  color="primary"
                >
                  Register
                </Button>
              </ActionButtonsContainer>
            </StyledCardContent>
          </StyledCard>
        </PageContainer>
      );
    };
  
    return (
      <div className="AppContainer">
        {loading ? renderLoading() : 
     renderContent()
        } 
      </div>
    );
  };
  
  export default Invite;
  
import react, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import {
  DataGrid,
  GridColDef,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton,
  GridValueGetterParams,
  getGridStringOperators,
} from "@mui/x-data-grid";
import styled from "styled-components";
import Drawer from "../../components/common/drawer/drawer";
import { Typography, Button, Checkbox, Fab, IconButton, Stack } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import { ERROR_COLOR } from "../../constants";
import { Add, Save, SaveAlt } from "@mui/icons-material";
import OrderLineItemModal from "../../components/modals/orderLineItemModal";
import OpaqueLoading from "../../components/opaqueLoading/opaqueLoading";
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import PartyList from "../../components/modals/partyListModal";
import AddRoutePlan from "../../components/modals/addRoutePlan";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import SpecimenLineItemsModal from "../../components/modals/specimenLineItemsModal";
import AllotSpecimenModal from "../../components/modals/allotSpecimen";
const isMobile = window.innerWidth < 900;

const StaffEditorPageContainer = styled.div`
  height: ${isMobile ? `auto` : `calc(100vh - 105px)`};
  width: 100%;
  background-color: white;
  padding: 10px 15px;
  ${isMobile &&
    `
      position: relative;
  
      `}
`;

const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: ${isMobile ? "flex-start" : "space-between"};
  margin-bottom: 10px;
  flex-direction: ${isMobile ? "column" : "row"};
`;

const HeaderButtonsContainer = styled.div`
  ${isMobile && "margin-top:10px;"}
`;

const TableContainer = styled.div`
// height: calc(
//   100vh - ${isMobile ? "56px - 20px - 82.23px" : "112px - 34px - 43.77px"}
// );
  width: 100%;
  // border: solid 1px lightGrey;
  border-radius: 8px;
`;
const AvatarContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const DataGridContainer = styled.div`
  max-width: 100%; /* Add this line to limit the width */
  margin: 0 auto; /* Center the content horizontally */
  height: 100%;
  width: 100%;
  border: solid 1px lightGrey;
  border-radius: 8px;
  display: ${isMobile ? 'flex' : 'grid'};

  ${isMobile &&
  `
    flex-direction: column;
  `}
`;

const LoadingContainer = styled.div`
  height: calc(100% - 49px);
  display: flex;
  justify-content: center;
  align-items: center;
`;

const TabsContainer = styled.div`
  ${isMobile &&
  `
    height: calc(100vh - 56px - 20px);
  `}
`;

const GenerateReportButton = styled(Button)`
  margin-right: 10px !important;
`;
const BulkChangePinCodeButton = styled(Button)`
  margin-right: 10px !important;
`;

const PinCodeChangeButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const FabStyle = styled(Fab)`
  && {
    z-index: 1};
  }
`;

const StyledHeader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export default function RoutePlanManagement() {
  const label = { inputProps: { "aria-label": "Checkbox demo" } };

  const [loading, setLoading] = useState(false);
  const [accessChanges, setAccessChanges] = useState("");
  const [showWarehouseFormModal, setShowWarehouseFormModal] = useState();
  const [modal,setModal]=useState(false)
  const [modalData,setModalData]=useState()
  const [addModal,setAddModal] = useState(false)
  const [rowCount,setRowCount] = useState()
const [currentPage, setCurrentPage] = useState(1);
const [paginationModel, setPaginationModel] = useState({
  page: currentPage - 1,
  pageSize: 11,
});
const [filter,setFilter]=useState(false)
const [searchTerm, setSearchTerm]=useState(null)
const [startDate,setStartDate]=useState()
const [endDate,setEndDate]=useState()
const [dateRange1,setDateRange1]=useState(false)

const [startDate1,setStartDate1]=useState()
const [endDate1,setEndDate1]=useState()
const [minDate,setMinDate]=useState()


  const formatDateTime=(inputDateString)=> {
    const inputDate = new Date(inputDateString);

  // Check if the input date is valid
  if (isNaN(inputDate.getTime())) {
    return 'Invalid Date';
  }

  // Extract date and time components
  const year = inputDate.getUTCFullYear();
  const month = String(inputDate.getUTCMonth() + 1).padStart(2, '0');
  const day = String(inputDate.getUTCDate()).padStart(2, '0');
  const hours = String(inputDate.getUTCHours()).padStart(2, '0');
  const minutes = String(inputDate.getUTCMinutes()).padStart(2, '0');
  const seconds = String(inputDate.getUTCSeconds()).padStart(2, '0');

  // Format the date string without 'T' and 'Z'
  const formattedDateString = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;

  return formattedDateString;

  }
  

 

  useEffect(() => {
    // setAccessChanges(rows);
    if(!filter){
    fetchOrder()}
  }, [paginationModel.page]);
  useEffect(() => {
    if(filter){
      onFilterChange(searchTerm)
    }
  }, [paginationModel.page]);

  const fetchOrder= async () => {
    try {
      setLoading(true);
      let results = await window.Platform.database.getSpecimenToUser({pageNumber:paginationModel.page});
      console.log("results", results);
      // if(auth?.user?.role === ROLES.ADMIN){
      const jsonArrayWithId = results?.data?.map((obj, index) => ({ ...obj, id: index + 1 }));
setRowCount(results.data1)
        setAccessChanges(jsonArrayWithId);
      // }
      // setVisibleEmployees(results);
      // setLoading(false);
    } catch (error) {
      console.error(error);
      window.NotificationUtils.showError("Error Fetching Data");
      // setLoading(false);
    }
    finally{
      setLoading(false)
    }
  }

  const onFilterChange= async(filterModel) => {    
    try{  
      setLoading(true);
      setFilter(true)
      console.log(filterModel,'filterModel')
      if(filterModel){
      const response = await window.Platform.database.filterSpecimenToUser({filterField:filterModel.field,filterValue:filterModel.value,pageNumber:paginationModel.page,startDate:startDate,endDate:endDate})
      console.log("respponse",response);
      const jsonArrayWithId = response?.data?.map((obj, index) => ({ ...obj, id: index + 1 }));
      setAccessChanges(jsonArrayWithId)
      setRowCount(response.data1 )
      }
      else{
        const response = await window.Platform.database.filterSpecimenToUser({filterField:"date",pageNumber:paginationModel.page,startDate:startDate,endDate:endDate})
        console.log("respponse",response);
        const jsonArrayWithId = response?.data?.map((obj, index) => ({ ...obj, id: index + 1 }));
        setAccessChanges(jsonArrayWithId)
        setRowCount(response.data1 )
       
      }
      
      setLoading(false);
    }
    catch(err){
  console.error(err)
  setLoading(false);
  window.NotificationUtils.showError("Error While Recieving Data Please Wait and try again");  
    }
    
  };

  const getEmployeeColumns = () => {
    const stringOperators = getGridStringOperators().filter((op => ['contains'].includes(op.value)));

  let result = [
    // {
    //     field: "Request ID",
    //     headerName: " ID",
    //     sortable: false,
    //     width: 150,
    //   filterable: false,

        
    //     editable: false,
    //     renderCell: (params) => {
  
    //       let name = params.row?.allottedSpecimenId||"N/A"
  
    //       return (
  
    //         <Tooltip title={name || "N/A"}>
  
    //           <Typography variant="inherit">{name}</Typography>
  
    //         </Tooltip>
  
    //       )
  
    //     },
    //   },
    {
      field: "name",
      headerName: "Created By",
      sortable: false,
      width: 150,
      filterOperators: stringOperators ,
      
      editable: false,
      valueGetter: (params) => {
        return (params.row?.name || "N/A")
      },
      renderCell: (params) => {

        let name = params.row?.name||"N/A"

        return (

          <Tooltip title={name || "N/A"}>

            <Typography variant="inherit">{name}</Typography>

          </Tooltip>

        )

      },
    },
    {
      field: "createdAt",
      headerName: "Created At",
      width: 250,
      // height: 10,
      // headerAlign: 'center',
      sortable: false,
      filterable: false,

      // editable: true,
      valueGetter: (params) => {
        return (params.row?.createdAt ? formatTimeStamp(params.row.createdAt) : "N/A")
      },
      renderCell: (params) => {

        let name =formatTimeStamp( params.row.createdAt)||"N/A"

        return (

          <Tooltip title={name || "N/A"}>

            <Typography variant="inherit">{name}</Typography>

          </Tooltip>

        )

      },
    },
    // {
    //     field: "updatedAt",
    //     headerName: "Updated At",
    //     width: 250,
    //     // height: 10,
    //     // headerAlign: 'center',
    //     sortable: false,      filterable: false,

    //     // editable: true,
    //     valueGetter: (params) => {
    //       return (params.row?.updatedAt ? formatTimeStamp(params.row?.updatedAt) : "N/A")
    //     },
    //     renderCell: (params) => {
  
    //       let name =formatTimeStamp( params.row.updatedAt)||"N/A"
  
    //       return (
  
    //         <Tooltip title={name || "N/A"}>
  
    //           <Typography variant="inherit">{name}</Typography>
  
    //         </Tooltip>
  
    //       )
  
    //     },
    //   },
      
   
      {
        field: "approvalStatus",
        headerName: "Approval Status",
        width: 200,      filterable: false,

        // height: 10,
        // headerAlign: 'center',
        sortable: false,
        // editable: true,
        valueGetter: (params) => {
          return (params.row?.approvalStatus === 0 ? "Pending" : params.row?.approvalStatus === 1 ? "Approved" : "Rejected" || "N/A")
        },
        renderCell: (params) => {
  
          let name = params.row?.approvalStatus===0?"Pending": params.row?.approvalStatus===1?"Approved":"Rejected"||"N/A"
  
          return (
  
            <Tooltip title={name || "N/A"}>
  
              <Typography variant="inherit">{name}</Typography>
  
            </Tooltip>
  
          )
  
        },
      },

      {
        field: "Approve Order",
        headerName: "Actions",
        width: 200,      filterable: false,

        // height: 10,
        // headerAlign: 'center',
        sortable: false,
        // editable: true,
        disableExport: true,
        renderCell: (params) => {
  
          let value =params.row?.allottedSpecimenId

  
          return (
  <Stack direction={"row"} spacing={1}>
    <Tooltip title="Click to view specimen allotment details"><IconButton variant="extended" color="primary" onClick={(e) => {
              e.stopPropagation();
              getRouteplanById(value);}} >
             <RemoveRedEyeIcon/>
            </IconButton></Tooltip>
            <Tooltip title="Click to approve specimen allotment"><IconButton disabled={params.row?.approvalStatus===1||params.row?.approvalStatus===2} variant="extended" sx={{color:"#008000"}}  onClick={(e) => {
              e.stopPropagation();
              approveRejectOrder({id:value,approvalStatus:"1"});}} >
             <ThumbUpIcon/>
            </IconButton></Tooltip>
            <Tooltip title="Click to reject specimen allotment"><IconButton disabled={params.row?.approvalStatus===1||params.row?.approvalStatus===2} variant="extended" sx={{color:"red"}} onClick={(e) => {
              e.stopPropagation();
              approveRejectOrder({id:value,approvalStatus:"2"});}} >
             <ThumbDownIcon/>
            </IconButton></Tooltip>
            </Stack>
          )
  
        },
      },
 
  ];


  return result
}

const approveRejectOrder = async (val) => {
  const confirmText = (val.approvalStatus === '1') ? 'approve' : 'reject';
  const isConfirmed = window.confirm(`Are you sure you want to ${confirmText} this specimen?`);
  
  if (!isConfirmed) {
    return;
  }

  try {
    setLoading(true);
    const results = await window.Platform.database.approveRejectSpecimenToUser(val);

    if (results.status) {
      window.NotificationUtils.showError(results?.message);
      setLoading(false);
      return;
    }

    if (val.approvalStatus === '1') {
      window.NotificationUtils.showSuccess("Specimen Approved");
    } else {
      window.NotificationUtils.showSuccess("Specimen Rejected");
    }

    setLoading(false);
    fetchOrder();
  } catch (err) {
    console.log(err);
    window.NotificationUtils.showError("Something Went Wrong!");
    setLoading(false);
  }
};

const formatTimeStamp = (timestampFromDB) => {
  const options = {
    timeZone: 'Asia/Kolkata',
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,  // Use 12-hour clock
  };

  const formattedDate = new Date(timestampFromDB).toLocaleString('en-US', options);

  return formattedDate;
};



const formatToOnlyDate=(val)=>{
    const concertDate = new Date(val);

// Extracting date components
const year = concertDate.getFullYear();
const month = concertDate.getMonth() + 1; // Months are zero-indexed, so we add 1
const day = concertDate.getDate();

// Creating a formatted date string
const formattedDate = `${year}-${month < 10 ? '0' : ''}${month}-${day < 10 ? '0' : ''}${day}`;

console.log(formattedDate);
return formattedDate;
}

const getRouteplanById=async(val)=>{
try{
    setLoading(true)
    setModalData()
let sentData={id:val}
    let results = await window.Platform.database.getSpecimenLineItemsById(sentData);
    const jsonArrayWithId = results?.data?.map((obj, index) => ({ ...obj, id: index + 1 }));

setModalData({data:jsonArrayWithId,id:val})
setModal(true)
    setLoading(false)

}
catch(error){
    console.log(error)
    window.NotificationUtils.showError("Something Went Wrong!")
    setLoading(false)



}



}








  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
        <GridToolbarExport
          csvOptions={{
            fileName: "export-specimens",
          }}
        />
      </GridToolbarContainer>
    );
  }

  const renderContent = () => {
    return (
      <DataGridContainer style={{ height: !accessChanges?.length ? '200px' : 'auto' }}>
        <DataGrid
          className="payrollGrid"
        //   checkboxSelection={true}
        density="compact"
          rows={accessChanges}
          columns={getEmployeeColumns()}
          disableSelectionOnClick
          disableRowSelectionOnClick
          rowHeight={60}
          disableDensitySelector
          onFilterModelChange={(val)=>
              {
          //       console.log("vvvv",val);
            
            
            
            if(val?.items[0]?.value?.length>0){
              onFilterChange(val.items[0])
              setSearchTerm(val.items[0])
            }
            else if(!val.items[0]?.value   && startDate && endDate){
              setSearchTerm(null)
              onFilterChange()
              console.log("CHECKING...1")

            }
            else{
              fetchOrder()
              setSearchTerm(null)
              setFilter(false)
            }
          //   else if(!val.items?.value && !endDate && !startDate && !territoryFilter){
          //     setSearchTerm(null)
          //     fetchData()
          //     console.log("CHECK1")
          //   }
          //   else if(!val.items?.value && endDate && startDate && territoryFilter){
          //     setSearchTerm(null)
  
          //     onFilterChange1()
          //     console.log("checkterr")
          //   }
          //   else if(!val.items?.value && endDate && startDate && !territoryFilter){
          //     setSearchTerm(null)
          //     console.log("check2")
          //     onFilterChange1()
  
  
          //   }
          //   else{
          //     setSearchTerm(null)
          //     console.log("check2")
          //     onFilterChange1()
          //   }
          }
            
          }
          filterMode="server"

rowCount={rowCount}
          
          paginationModel={paginationModel}
          paginationMode="server"
          onPaginationModelChange={setPaginationModel}
          pageSizeOptions={[10]}
          
          
          components={{
            Toolbar: CustomToolbar,
          }}
        />
      </DataGridContainer>
    );
  };

  const formatDate=async(data)=>{
    setMinDate(data)
    setDateRange1(true)
    setEndDate1(null)
    setEndDate(null)
    const datePickerResponse = new Date(data.$d);
  
  const year = datePickerResponse.getFullYear();
  const month = String(datePickerResponse.getMonth() + 1).padStart(2, '0');
  const day = String(datePickerResponse.getDate()).padStart(2, '0');
  const formattedDate = `${year}-${month}-${day}`;
  
  setStartDate1(formattedDate)
  setStartDate(formattedDate)
  console.log("CHECK!!")
  console.log("CHECKENDDATE",endDate)
  
  }
  const finalDateRangeFilter=async(data)=>{
    try{
      setLoading(true)
      setFilter(true)
  
    const datePickerResponse = new Date(data);
  
    const year = datePickerResponse.getFullYear();
    const month = String(datePickerResponse.getMonth() + 1).padStart(2, '0');
    const day = String(datePickerResponse.getDate()).padStart(2, '0');
  const formattedDate = `${year}-${month}-${day}`;
    
    if (data){
    setEndDate(formattedDate)
    setEndDate1(formattedDate)
  }
      if(searchTerm){
      const response = await window.Platform.database.filterSpecimenToUser({filterField:searchTerm.field,filterValue:searchTerm?.value,pageNumber:paginationModel.page,startDate:startDate,endDate:data?formattedDate:endDate })
      const jsonArrayWithId = response?.data?.map((obj, index) => ({ ...obj, id: index + 1 }));
      setAccessChanges(jsonArrayWithId)
      setRowCount(response.data1 )
       
    }
      else{
      const response = await window.Platform.database.filterSpecimenToUser({filterField:"date",filterValue:searchTerm?.value,pageNumber:paginationModel.page,startDate:startDate,endDate:data?formattedDate:endDate })
      const jsonArrayWithId = response?.data?.map((obj, index) => ({ ...obj, id: index + 1 }));
      setAccessChanges(jsonArrayWithId)
      setRowCount(response.data1 )
      
      }
      setLoading(false)
  
    }
    catch(err){
      console.log(err)
      setLoading(false)
      window.NotificationUtils.showError("Error While Recieving Data Please Wait and try again");
  
      fetchOrder()
    
    }
  }
  const clearDateFilter=async()=>{
    setStartDate(null)
    setEndDate(null)
    setStartDate1(null)
    setEndDate1(null)
    setDateRange1(false)
    if(searchTerm){
      try{
      setLoading(true)
      const response = await window.Platform.database.filterSpecimenToUser({filterField:searchTerm?searchTerm.field:"date",filterValue:searchTerm?.value,pageNumber:paginationModel.page,startDate:'',endDate:'' })
      const jsonArrayWithId = response?.data?.map((obj, index) => ({ ...obj, id: index + 1 }));
      setAccessChanges(jsonArrayWithId)
        setRowCount(response.data1 )
        
      setLoading(false)
    }
    catch(e){
      console.log(e)
      window.NotificationUtils.showError("Error While Recieving Data Please Wait and try again");
      fetchOrder()  
  
      }
    }
  else{
    setFilter(false)
    fetchOrder()
  }
  }



  return (
    <>
    
      <StaffEditorPageContainer>
        <HeaderContainer>
          {/* <Typography variant="h5">Order Management</Typography> */}
          {loading && <OpaqueLoading/>}
          <div style={{width:"60%",display:"flex",flexDirection:"row",gap:"10px"}}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker label="Start Date" value={startDate1} disabled={loading} format="YYYY/MM/DD" onChange={(data)=>formatDate(data)} disableFuture={true} />
            </LocalizationProvider>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker label="End Date"  minDate={minDate} value={endDate1} disabled={!dateRange1||loading} format="YYYY/MM/DD" onChange={(data)=>finalDateRangeFilter(data.$d)} disableFuture={true} />
            </LocalizationProvider>
            <Button variant="text" onClick={()=>clearDateFilter()} disabled={!dateRange1||loading} >Clear</Button>


            </div>
            {/* <FabStyle variant="extended" color="primary" 
            onClick={()=> setAddModal(true)} size="small"
  //  onClick={}
             >
  <Add sx={{ mr: 1 }}  />
  Allot New Specimen 
</FabStyle>  */}

  <Button variant="contained" color="primary" 
    onClick={()=> setAddModal(true)} sx={{ mr: 1, borderRadius: '10px' }}>
    <Add sx={{ mr: 1 }}  />
    Allot New Specimen
  </Button>

            {/*  */}
                  {/* </FabStyle> */}
            {/* )} */}
        </HeaderContainer>
        <TableContainer>{renderContent()}</TableContainer>
      </StaffEditorPageContainer>
      {
      modal &&
      <SpecimenLineItemsModal
      onCancel={() => setModal(null)}
      loading={loading}
      data={modalData}
    //   onSubmit={updateWarehouseData}
      />
    }
    {
      addModal && <AllotSpecimenModal onClose={()=>setAddModal(false)} fetchData={()=>fetchOrder()} />
    }
    </>
  );
}

import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogContent,
  FormControl,
  FormControlLabel,
  FormGroup,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  Fab,
  DialogActions,
  Tabs,
  Tab,
  Autocomplete,
  Divider,
  Chip
} from "@mui/material";
import ForwardToInboxIcon from '@mui/icons-material/ForwardToInbox';
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import DeleteIcon from '@mui/icons-material/Delete';
import Tooltip from "@mui/material/Tooltip";
import { Check as CheckIcon, Clear as ClearIcon, Close, CropSharp, Details } from "@mui/icons-material";
import { CloseOutlined } from "@mui/icons-material";
import { Stack } from "@mui/material";
import styled from "styled-components";
import { ACTIVITY_STATUS, ROLES } from "../../constants";
import moment from "moment";
import OpaqueLoading from "../opaqueLoading/opaqueLoading";
import { DataGrid, GridToolbarColumnsButton, GridToolbarContainer, GridToolbarDensitySelector, GridToolbarExport, GridToolbarFilterButton } from "@mui/x-data-grid";
import { AuthContext } from "../contextAPI/ContextAPI";
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

 
const isMobile = window.innerWidth < 900;
 
const TotalAmountTypography = styled(Typography)`
 
  position: absolute;
 
  bottom: 10px;
 
  right: 10px;
 
  font-size: ${isMobile ? "14px" : "inherit"};
 
  bottom: ${isMobile ? "85px" : "70px"};
 
  right: ${isMobile ? "15px" : "70px"};
 
`;
const ModalHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
  padding: 10px;
`;
const TableContainer = styled.div`
  // height: calc(
  //   100vh - ${isMobile ? "106px - 20px - 82.23px" : "100px - 20px - 23.77px"}
  // );
  width: ${isMobile?"100%":"98%"};
  border: solid 1px lightGrey;
  border-radius: 8px;
  margin-left:${isMobile?"0px":"10px"};
  margin-bottom:${isMobile?"0px":"10px"};

`;
const InputFieldContainer = styled.div`
  margin: 20px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  label {
    margin-bottom: 0px;
  }
`;
const TabsContainer = styled.div`
  ${isMobile && `
    height: calc(100vh - 176px - 20px - 43.77px);
  `}
`
const StyledTabs = styled(Tabs)`
  border-bottom: solid 1px lightGrey;
`;
 
const ModalActionsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 20px;
`;
 
const FirstButton = styled(Button)`
  margin-right: 10px !important;
`;
 
const StyledDialogContent = styled(DialogContent)`
  padding: 16px;
`;
 
const StyledDialog = styled(Dialog)`
  position: relative;
`;
 
const FabStyle = styled(Fab)`
  && {
    z-index: 1;
  }
`;
 
const DataGridContainer = styled.div`
  width: 100%;
 
 
`;
const DataGridContainer1 = styled.div`
  width: 100%;
  margin-bottom:${!isMobile&& "20px"};
 
//   height: 500px;
`;
 
const NoteText = styled(Typography)`
  margin-top: 10px !important;
`;
 
const Input = styled.input`
  display: none;
`;
 
 
const AddOrderModalDistributor = ({ props,onClose1,onClose,remarks,dataProp,id,distributorId,fetchData,status}) => {
  console.log("DATA0PROPA",dataProp);
  const [changePassword, setChangePassword] = useState(false)
  const [tabValue, setTabValue] = useState(0);
  const { auth, setAuth } = useContext(AuthContext);
  // console.log("DATAAUTH",auth.user.plant_master__c );
 
  // const [details, setDetails] = useState(data);

 const [roles, setRoles] = useState([])
  const [plantCode,setPlantCode]=useState("")
  const [loading,setLoading]=useState(false)
const [product,setProduct]=useState([])
  // const [dynamicFields, setDynamicFields] = useState(data?.orderDetails?.map((obj, index) => ({pricebookentryid:'',name:obj.product_name__c,quantity:obj.quantity,unitprice:obj.unitprice,totalprice:obj.totalprice,oid:obj.orderitemnumber, product2id:obj.product2id,id: index + 1 })));
 const [deletedField,setDeletedFields]=useState([])
 const [hasChanges, setHasChanges] = useState(false);
 const [remark,setRemrak]=useState(remarks)
 const [form,setForm]=useState({
  ownerId:""||dataProp?.ownerId,
  orderType:""||dataProp?.orderType,
  partyType1:""||"0",
  partyId:""||dataProp?.partyId,
  discount:""||dataProp?.Discount,
  transport:""||dataProp?.Transport,
  email:""||dataProp?.emailId,
  mobileNo:""||dataProp?.mobileNo,
  address:""||dataProp?.Address,
  remark:""||dataProp?.remark,
  approvalStatus:""||dataProp?.approvalStatus,
  partyType:1
 })
 const [employee,setEmployee]=useState([])
 const [assignedEmp,setAssignedEmp]=useState(null)
 const [party,setParty]=useState()
 const [selectParty,setSelectParty]=useState(null)

 const [product1,setProduct1]=useState([])
 

 const [board,setBoard]=useState()
 const [medium,setMedium]=useState()
 const [className,setClassName]=useState()
 const [series,setSeries]=useState([])
 const [selectedSeries,setSelectedSeries]=useState(null)

 const [selectDataFilter,setSelectDataFilter]=useState({
     board:'',class:'',medium:'',series:''
 })
 const [selectDataFilter1,setSelectDataFilter1]=useState({
  board:'',class:'',medium:''
})

 const [disableSeries,setDisableSeries]=useState(false)
 const [disableOther,setDisableOther]=useState(false)

 const [selectedProduct,setSelectedProduct]=useState(null)
 const [dataGrid,setDataGrid]=useState([])
const [deletedIds,setDeletedIds]=useState([])
const [specimen,setSpecimen]=useState([])
const [specimen1,setSpecimen1]=useState([])

const [selectSpecimen,setSelectedSpecimen]=useState(null)
const [getSeriesCategory,setgetSeriesCategory]=useState()
const [bookType,setBookType]=useState(null)
const [seriesAdd,setSeriesAdd]=useState([])

const [productGroupMittsure, setProductGroupMittsure] = useState();
  const [productGroupMittplus, setProductGroupMittplus] = useState();
  const [productGroups, setProductGroups] = useState();
  const [productGroup, setProductGroup] = useState({});
  const [transporter, setTransporter] = useState([]);
  const [accessChanges, setAccessChanges] = useState("");


  const handleChangeTab = (event, newValue) => {
    console.log("newValue: " , newValue);
    setTabValue(newValue);
  };
   
 const [orderType,setOrderType]=useState(0) 

  console.log("FORMM",form)

  const getTransporters = async () => {
    try {

      setLoading(true);
      let results = await window.Platform.database.getTransporters({forDD:true});
      setTransporter(results.data);
      
      const jsonArrayWithId = results?.data?.map((obj, index) => ({
        ...obj,
        id: index + 1,
      }));

      setAccessChanges(jsonArrayWithId);
      setLoading(false);

    } catch (error) {
      window.NotificationUtils.showError("Error Fetching Data");
      setLoading(false);
    }
  };
 
useEffect(()=>{

  getProductGroups();
fetchEmployees()
getBoard()
getMedium()
getClass()
getTransporters();
// fetchProduct()
// fetchSeries()
fetchSpecimen()
getSeriesCategoryData()

console.log("currentTab", tabValue)
},[])
console.log("DATAFILTER",selectDataFilter1)
 
// useEffect(()=>{
// updateDataGrid1()
// console.log("DATAFILTERUSE")

// },[selectDataFilter1.medium,selectDataFilter1.class,selectDataFilter1.board])

useEffect(()=>{
  updateDataGrid()
    },[selectDataFilter.medium,selectDataFilter.class,selectDataFilter.board,selectDataFilter.series,form.qty,bookType])
useEffect(()=>{
if(!status){
  fetchOrderLineItem(dataProp.orderId)
  getDistributor({ownerId:dataProp.ownerId})
}
},[])
const getSeriesCategoryData=async(data)=>{
  let results = await window.Platform.database.getSeriesCategory();
  setgetSeriesCategory(results.data)


}

const getProductGroups = async (data) => {
  let results = await window.Platform.database.getProductGroups();
  setProductGroups(results.data);
  results.data.find((productGroup) => {
    if (productGroup.name.includes("MittSure")) {
      setProductGroup(productGroup);
      setProductGroupMittsure(productGroup);
      return productGroup;
    } else {
      setProductGroupMittplus(productGroup);
    }
  });
};

const fetchOrderLineItem=async(val)=>{
  try{
  
  let sentData={id:val}
      let results = await window.Platform.database.fetchOrderLineItem(sentData);
      const jsonArrayWithId = results?.data?.map((obj, index) => ({ ...obj, id: index + 1 }));

  setDataGrid(jsonArrayWithId)
      setLoading(false)
  
  }
  catch(error){
      console.log(error)
      window.NotificationUtils.showError("Something Went Wrong!")
      setLoading(false)
  
  }
  }
  

  const fetchSpecimen=async()=>{
    let results = await window.Platform.database.getSpecimen();
    setSpecimen(results.data)
    setSpecimen1(results.data)

  }
console.log("datafilter",specimen)
const fetchSeries=async()=>{
  let results = await window.Platform.database.fetchSeries();
  setSeries(results.data)

}
const updateDataGrid = (selectedContainer) => {
  if (!selectDataFilter.board&&!selectDataFilter.medium&&!selectDataFilter.class&&!selectDataFilter.series) {
    // If no container is selected, display all data
    setProduct(product1);
  } else {
    // Filter the data based on the selected container
  //   const filteredData = product.filter(item => item.mediumTableId === selectDataFilter.medium || item.classId===selectDataFilter.class || item.boardId===selectDataFilter.board );
  const filteredData = product1.filter(item => {
      const boardMatch = !selectDataFilter.board || item.boardId === selectDataFilter.board;
      const mediumMatch = !selectDataFilter.medium || item.mediumTableId === selectDataFilter.medium;
      const classMatch = !selectDataFilter.class || item.classId === selectDataFilter.class;
      const seriesMatch = !selectDataFilter.series || item.seriesCategory === selectDataFilter.series;
      console.log("Series123: " + seriesMatch)
      
            // Return true only if the filter conditions are met based on filter presence
            return (
              (!selectDataFilter.board || boardMatch) &&
              (!selectDataFilter.medium || mediumMatch) &&
              (!selectDataFilter.class || classMatch)&&
              (!selectDataFilter.series || seriesMatch)
      );
    });
  setProduct(filteredData);
  }
};



const changeHandler = (fieldName, value) => {
    // console.log(value)
    setForm((prevForm) => ({ ...prevForm, [fieldName]: value }));
  };

  const getDistributor=async(details)=>{
    try{
      setLoading(true)
      // console.log("PLANTCODE",plantcode[0].plant_code__c)
      const results = await window.Platform.database.getDistributor(details);
//   console.log("pincode: " ,results.data[0].StateName)
setParty(results.data)
setSelectParty(null)
  if(!status){
    const filteredEmp=results?.data?.filter((data)=>data.distributorID===dataProp.partyId)
    console.log("FILTERDATASCHOOl",dataProp.partyId)
    setSelectParty(filteredEmp[0])
    
   }
    setLoading(false)
  
  // setDetails(results.data[0]);
  // setProduct(results.data)
  }
  catch(err){
    console.log(err);
  setLoading(false)
   
    }
  }
const orderTypeHandler=(data)=>{
  if(data==="Sales"){
  setOrderType(1)
  setDataGrid([])
  changeHandler("orderType",data)

  }
  else{
setOrderType(2)
setDataGrid([])
changeHandler("orderType",data)
  }

}

const renderContent1=()=>{
  return (
    <div style={{ maxHeight:"510px",height:"505px", overflowY:"auto" }}>
   
        
          <Stack direction={"row"} spacing={2} padding={2}>
          <Autocomplete
            options={employee}
            // getOptionLabel={}
            // getOptionLabel={(option) => `${option?.name} (${option?.id})`}
            getOptionLabel={(option) => `${option?.name}`}
              getOptionValue={(option) => option?.id || ''}
            style={{ width: "100%" }}
            value={assignedEmp}
            // disabled={loading 
              // || !addNewMode || props.isManager
            // }
            noOptionsText={loading ? "Loading..." : "No option"}
              onChange={(event, value) => {
                console.log("TEST1212",value)
                changeHandler("ownerId", value?.id)
                setAssignedEmp(value)
                getDistributor({ownerId: value?.id})

                //  updateDataGrid(value);
                
                // if(selectedCity.warehouseCountry === 'India' && warehouseData.attributes.warehouseCountry === 'China')
                // {
                //     setDisableTransfer(false)
                // }
                // else
                // setDisableTransfer(true)

               
                
              }}
              onInputChange={(e, value) => {
                // Check if the user's input matches any option
                const matchingOption = employee.find((option) => option.name === value.name);

                
              }}
            renderInput={(params) => (
              <TextField
                {...params}
                // disabled={
                //   loading 
                  // || !addNewMode || props.isManager || props.isDeleted
                // }
                label={"Assign To"}
                required
                variant="outlined"
              />
            )}/>
            <FormControl variant="outlined" style={{ width: "100%" }}>
          <InputLabel >Order Type</InputLabel>
          <Select
            labelId={`Product`}
            label="Order Type"
            value={form?.orderType||""}
            onChange={(e) => orderTypeHandler(e.target.value)}
          >
         
    <MenuItem  value={"Sales"}>
      Sales
    </MenuItem>


          </Select>
        </FormControl>
          </Stack>
          <Stack direction={"row"} spacing={2} padding={2}>
          {/* <FormControl fullWidth>
      <InputLabel id="select-label">Party Type</InputLabel>
      <Select
        labelId="select-label"
        id="select"
        value={form.partyType1}
        onChange={(e)=>changeHandler("partyType1",e.target.value)}
        label="Select Option"
      >
        {/* {partyType?.map((data) => ( */}
    {/* <MenuItem  value={"0"}>
     School
    </MenuItem>
  {/* ))} */}
      {/* </Select> */}
    {/* </FormControl>  */}
    <Autocomplete
            options={party}
            // getOptionLabel={}
            // getOptionLabel={(option) => `${option?.DistributorName} (${option?.distributorID})`}
            getOptionLabel={(option) => `${option?.DistributorName}`}
              getOptionValue={(option) => option?.distributorID || ''}
            style={{ width: "100%" }}
            value={selectParty}
            disabled={loading||!form.ownerId}
            // disabled={loading 
              // || !addNewMode || props.isManager
            // }
            noOptionsText={loading ? "Loading..." : "No option"}
              onChange={(event, value) => {
                console.log("TEST1212",value)
                changeHandler("partyId", value.distributorID)
                setSelectParty(value)
                //  updateDataGrid(value);
                
                // if(selectedCity.warehouseCountry === 'India' && warehouseData.attributes.warehouseCountry === 'China')
                // {
                //     setDisableTransfer(false)
                // }
                // else
                // setDisableTransfer(true)

               
                
              }}
              onInputChange={(e, value) => {
                // Check if the user's input matches any option
                const matchingOption = party.find((option) => option.schoolName === value.schoolName);

               
              }}
            renderInput={(params) => (
              <TextField
                {...params}
                // disabled={
                //   loading 
                  // || !addNewMode || props.isManager || props.isDeleted
                // }
                label={"Select Party"}
                required
                variant="outlined"
              />
            )}/>
             <FormControl variant="outlined" style={{ width: isMobile?"100%":"100%" }}>
          <InputLabel >Order Status</InputLabel>
          <Select
            labelId={`Product`}
            label="Order Status"
            value={form?.approvalStatus}
            onChange={(e) => changeHandler("approvalStatus",e.target.value)}
          >
         
    <MenuItem value={1}>
      Approved
    </MenuItem>
    <MenuItem value={0} >
      Pending
    </MenuItem>
    <MenuItem value={2}>
      Reject
    </MenuItem>

          </Select>
        </FormControl>
        </Stack>
        <Stack direction={"row"} spacing={2} padding={2}>
            
<TextField label="Discount" value={form?.discount} type="text" fullWidth onChange={(e)=>changeHandler("discount",e.target.value)}/>
{/* <TextField label="Transport" value={form?.transport} type="text" fullWidth onChange={(e)=>changeHandler("transport",e.target.value)}/> */}
      <FormControl fullWidth variant="outlined" disabled={loading}>
        <InputLabel>Select Transporter</InputLabel>
        <Select
          value={form.transport || ''}
          onChange={(event) => {
            const selectedValue = event.target.value;
            changeHandler("transport", selectedValue);
          }}
          label="Select Transporter"
        >
          {transporter.length > 0 ? (
            transporter.map((option) => (
              <MenuItem key={option.transporterId} value={option.transporterId}>
                {option.transporter_name}
              </MenuItem>
            ))
          ) : (
            <MenuItem disabled>{loading ? "Loading..." : "No option"}</MenuItem>
          )}
        </Select>
      </FormControl>
            
            
            </Stack>  
        <Stack direction={"row"} spacing={2} padding={2}>
<TextField label="Email" value={form?.email} type="text" fullWidth onChange={(e)=>changeHandler("email",e.target.value)}/>
<TextField label="Contact No" value={form?.mobileNo} type="text" fullWidth onChange={(e)=>changeHandler("mobileNo",e.target.value)}/>
           
        </Stack>
        <Stack direction={"row"} spacing={2} padding={2}>
        <TextField label="Send Address" value={form?.address} type="text" fullWidth onChange={(e)=>changeHandler("address",e.target.value)}/>
        <TextField label="Remark" multiline value={form?.remark} type="text" fullWidth onChange={(e)=>changeHandler("remark",e.target.value)}/>

        </Stack>
        <Stack direction={"row"} spacing={2} padding={2}>
       

        </Stack>
         
     
      
</div>
)}

const bookTypeHandler=(data)=>{
  if(data===1){
    setBookType(data)
fetchSeries()
  }
  else if(data===2){
setBookType(data)
fetchProduct()

  }
}
const renderContent = (params) => {
  console.log("CheckTeabRenderContent",tabValue)
  return (
      <>{orderType===1?
      <div style={{ maxHeight:"510px",height:"505px", overflowY:"auto" }}>
      <Stack direction={"row"} spacing={1} padding={2}>
        <Stack direction={"row"} spacing={1} width={"100%"}>
        <FormControl variant="outlined" style={{ width: isMobile?"100%":"40%" }}>

<InputLabel id="select-label">Book Type</InputLabel>
<Select
disabled={disableOther}
  labelId="select-label"
  id="select"
  value={bookType||""}
  onChange={(e)=>bookTypeHandler(e.target.value)}
  label="Select Option"
>
  
<MenuItem  value={1}>
Choose from set
</MenuItem>
<MenuItem  value={2}>
Choose individual book
</MenuItem>

</Select>
  </FormControl>

  <FormControl variant="outlined" style={{ width: isMobile?"100%":"40%" }}>
  <TextField
  label="Quantity"
  value={form?.qty || ""}
  type="number"
  
  onChange={(e) =>
   changeHandler('qty', e.target.value)
  }
/>
</FormControl>


   


</Stack>

 <Button variant="text" onClick={()=>clearData()} >
     Clear 
  </Button>
</Stack>
<div style={bookType === 1 ? { display: 'none' } : { display: 'block' }}>
  <hr style={{width: '100%'}} />
  <Stack direction={"row"} spacing={2} padding={1.5}>
  <FormControl style={{ width: isMobile?"100%":"40%" }}>
        <InputLabel id="select-label">Filter By Series</InputLabel>
        <Select
          labelId="select-label"
          id="select"
          value={form?.series||""}
          onChange={(e)=>seriesHandler(e.target.value)}
          label="Select Option"
        >
           {getSeriesCategory?.map((option, optionIndex) => (
      <MenuItem key={option.seriesTableId} value={option.seriesTableId}>
        {option.seriesName}
      </MenuItem>
    ))}
        </Select>
      </FormControl>
      <FormControl variant="outlined" style={{ width: isMobile?"100%":"40%" }}>
          <InputLabel id={`select-label`}>Filter By Medium</InputLabel>
          <Select
          disabled={disableOther}
            labelId={`Product`}
            label=" Medium"
            value={form?.medium||""}
            onChange={(e) => mediumHandler( e.target.value)}
          >
            {medium?.map((option, optionIndex) => (
    <MenuItem key={option.mediumTableId} value={option.mediumTableId}>
      {option.mediumName}
    </MenuItem>
  ))}
          </Select>
    </FormControl>
    <FormControl variant="outlined" style={{ width: isMobile?"100%":"40%" }}>
  
      <InputLabel id="select-label">Filter By Class</InputLabel>
      <Select
      disabled={disableOther}
        labelId="select-label"
        id="select"
        value={form?.classId||""}
        onChange={(e)=>classHandler(e.target.value)}
        label="Select Option"
      >
         {className?.map((option, optionIndex) => (
    <MenuItem key={option.classId} value={option.classId}>
      {option.className}
    </MenuItem>
  ))}
      </Select>
    </FormControl>
  </Stack>
  <hr style={{width: '100%'}} />
</div>
    <Stack direction={"row"} spacing={2} padding={1.5}>
      {bookType===2?
    <Autocomplete
          options={product}
          // getOptionLabel={}
          // getOptionLabel={(option) => `${option?.nameSku} (${option?.skuId})`}
          getOptionLabel={(option) => `${option?.nameSku}`}
            getOptionValue={(option) => option?.skuId || ''}
          style={{ width: "60%" }}
          value={selectedProduct}
          disabled={!bookType || bookType===1 ||!form?.qty}
          // disabled={loading 
            // || !addNewMode || props.isManager
          // }
          noOptionsText={loading ? "Loading..." : "No option"}
            onChange={(event, value) => {
              console.log("TEST1212",value)
              setSelectedProduct(value)
              //  updateDataGrid(value);
              
              // if(selectedCity.warehouseCountry === 'India' && warehouseData.attributes.warehouseCountry === 'China')
              // {
              //     setDisableTransfer(false)
              // }
              // else
              // setDisableTransfer(true)

             
              
            }}
            onInputChange={(e, value) => {
              // Check if the user's input matches any option
              const matchingOption = employee.find((option) => option.name === value.name);

              if (!matchingOption ) {
                // If there's no matching option, set the otherValue to null
              //   setDeliveryCity(null);
              // setAssignedEmp();
              return;
              }
            }}
          renderInput={(params) => (
            <TextField
              {...params}
              // disabled={
              //   loading 
                // || !addNewMode || props.isManager || props.isDeleted
              // }
              label={"Choose SKU"}
              required
              variant="outlined"
            />
          )}/>
          :

          <Autocomplete
          options={series}
          // getOptionLabel={}
          disabled={!bookType||bookType===0||!form?.qty}

          // getOptionLabel={(option) => `${option?.nameSeries} (${option?.seriesId})`}
          getOptionLabel={(option) => `${option?.nameSeries}`}
            getOptionValue={(option) => option?.seriesId || ''}
          style={{ width: "60%" }}
          value={selectedSeries}
          // disabled={loading 
            // || !addNewMode || props.isManager
          // }
          noOptionsText={loading ? "Loading..." : "No option"}
            onChange={(event, value) => {
              console.log("TEST1212",value)
              setSelectedSeries(value)
              // setDisableOther(true)
              fetchProductById(value?.seriesId)
              //  updateDataGrid(value);
              
              // if(selectedCity.warehouseCountry === 'India' && warehouseData.attributes.warehouseCountry === 'China')
              // {
              //     setDisableTransfer(false)
              // }
              // else
              // setDisableTransfer(true)

             
              
            }}
            onInputChange={(e, value) => {
              // Check if the user's input matches any option
              const matchingOption = employee.find((option) => option.name === value.name);

             
            }}
          renderInput={(params) => (
            <TextField
              {...params}
              // disabled={
              //   loading 
                // || !addNewMode || props.isManager || props.isDeleted
              // }
              label={"Choose By Set"}
              required
              variant="outlined"
            />
          )}/>
}

{/* <TextField label="Unit  Price" disabled value={selectedProduct?.unitPrice||""} type="text" fullWidth /> */}

{/* <TextField label="Total Price" disabled value={selectedProduct?.totalPrice||""} type="text" fullWidth /> */}

<Button variant="text" onClick={()=>additem()} disabled={bookType===1 ? !seriesAdd?.length:!selectedProduct } >
     Add 
  </Button>
            </Stack>
           
            <Stack direction={"row"} spacing={2} padding={1}>
  
         
           <DataGrid
          className="payrollGrid"
        //   checkboxSelection={true}
        style={{ height: !dataGrid.length ? '200px' : 'auto' }}
        density="compact"
      //   checkboxSelection={true}
          rows={dataGrid||[]}
          columns={getColumns()}
          rowHeight={40}
          // onRowSelectionModelChange={handleSelectionModelChange}
          disableSelectionOnClick
          disableRowSelectionOnClick
          // autoPageSize  
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 10,
                  },
                },
              }}
              pageSizeOptions={[10]}          // components={{
          //   Toolbar: CustomToolbar,
          // }}
        />
               
           
      </Stack> 
   
  
  </div>
  :
  <div style={{ maxHeight:"510px",height:"505px", overflowY:"auto" }}>
  <Stack direction={"row"} spacing={2} padding={2}>

  <FormControl variant="outlined" style={{ width: isMobile?"100%":"30%" }}>
        <InputLabel >School Board</InputLabel>
        <Select
        disabled={disableOther}
          labelId={`Product`}
          label="School Board"
          value={form?.board||""}
          onChange={(e) => boardHandler(e.target.value)}
        >
          {board?.map((option, optionIndex) => (
  <MenuItem key={option.boardId} value={option.boardId}>
    {option.boardName}
  </MenuItem>
))}
        </Select>
      </FormControl>
      <FormControl variant="outlined" style={{ width: isMobile?"100%":"30%" }}>
        <InputLabel id={`select-label`}>School Medium</InputLabel>
        <Select
        disabled={disableOther}
          labelId={`Product`}
          label="School Medium"
          value={form?.medium||""}
          onChange={(e) => mediumHandler( e.target.value)}
        >
          {medium?.map((option, optionIndex) => (
  <MenuItem key={option.mediumTableId} value={option.mediumTableId}>
    {option.mediumName}
  </MenuItem>
))}
        </Select>
  </FormControl>
<FormControl variant="outlined" style={{ width: isMobile?"100%":"30%" }}>

    <InputLabel id="select-label">Class</InputLabel>
    <Select
    disabled={disableOther}
      labelId="select-label"
      id="select"
      value={form?.classId||""}
      onChange={(e)=>classHandler(e.target.value)}
      label="Select Option"
    >
       {className?.map((option, optionIndex) => (
  <MenuItem key={option.classId} value={option.classId}>
    {option.className}
  </MenuItem>
))}
    </Select>
  </FormControl>
  <FormControl style={{ width: isMobile?"100%":"50%" }}>
      <InputLabel id="select-label">Series</InputLabel>
      <Select
        labelId="select-label"
        id="select"
        value={form?.series||""}
        onChange={(e)=>seriesHandler(e.target.value)}
        label="Select Option"
      >
         {getSeriesCategory?.map((option, optionIndex) => (
    <MenuItem key={option.seriesTableId} value={option.seriesTableId}>
      {option.seriesName}
    </MenuItem>
  ))}
      </Select>
    </FormControl>

 <Button variant="contained" onClick={()=>clearData()} >
     Clear 
  </Button>
  </Stack>
  <Stack direction={"row"} spacing={2} padding={2}>
  <Autocomplete
          options={specimen}
          // getOptionLabel={}
          // getOptionLabel={(option) => `${option?.nameSku} (${option?.skuId})`}
          getOptionLabel={(option) => `${option?.nameSku}`}
            getOptionValue={(option) => option?.skuId || ''}
          style={{ width: "100%" }}
          value={selectSpecimen}
          // disabled={loading 
            // || !addNewMode || props.isManager
          // }
          noOptionsText={loading ? "Loading..." : "No option"}
            onChange={(event, value) => {
              console.log("TEST1212",value)
              setSelectedSpecimen(value)
              //  updateDataGrid(value);
              
              // if(selectedCity.warehouseCountry === 'India' && warehouseData.attributes.warehouseCountry === 'China')
              // {
              //     setDisableTransfer(false)
              // }
              // else
              // setDisableTransfer(true)

             
              
            }}
            onInputChange={(e, value) => {
              // Check if the user's input matches any option
              // const matchingOption = employee.find((option) => option.name === value.name);

              // if (!matchingOption ) {
              //   // If there's no matching option, set the otherValue to null
              // //   setDeliveryCity(null);
              // // setAssignedEmp();
              // return;
              // }
            }}
          renderInput={(params) => (
            <TextField
              {...params}
              // disabled={
              //   loading 
                // || !addNewMode || props.isManager || props.isDeleted
              // }
              label={"Choose SKU"}
              required
              variant="outlined"
            />
          )}/>
          <TextField
  label="Quantity"
  value={selectSpecimen?.qty || ""}
  type="text"
  fullWidth
  onChange={(e) =>
    setSelectedSpecimen((prevForm) => ({ ...prevForm, "qty": e.target.value,"totalPrice":e.target.value*selectSpecimen?.unitPrice }))
  }
/>
<TextField label="Unit  Price" disabled value={selectSpecimen?.unitPrice||""} type="text" fullWidth />

<TextField label="Total Price" disabled value={selectSpecimen?.totalPrice||""} type="text" fullWidth />

<Button variant="contained" onClick={()=>additem1()} disabled={bookType===1 ? seriesAdd.length===0:!selectedProduct } >
     Add 
  </Button>
    </Stack>
    <Stack direction={"row"} spacing={2} padding={1}>
  
         
  <DataGrid
 className="payrollGrid"
//   checkboxSelection={true}
style={{ height: !dataGrid.length ? '200px' : 'auto' }}
density="compact"
//   checkboxSelection={true}
 rows={dataGrid||[]}
 columns={getColumns()}
 rowHeight={40}
 // onRowSelectionModelChange={handleSelectionModelChange}
 disableSelectionOnClick
 disableRowSelectionOnClick
 autoPageSize
    initialState={{
      pagination: {
        paginationModel: {
          pageSize: 10,
        },
      },
    }}
    pageSizeOptions={[10]} // components={{
 //   Toolbar: CustomToolbar,
 // }}
/>
      
  
</Stack> 
    
    </div>}
    <div style={{width:"55%"}}>
            <ModalHeaderContainer>
<TextField label="Total Amount" value={tabValue===1&&calculateTotal(dataGrid)||0} type="text" disabled/>
  {status?
  <Button variant="contained" onClick={()=>onsubmit(form)} >
     Place Order
  </Button>:
  <Button variant="contained" onClick={()=>onEdit(form)} >
  Update Order
</Button>
}</ModalHeaderContainer>
</div>
  </>
  )}



const onEdit=async()=>{
  try{
    setLoading(true)
  const filteredArray = dataGrid.filter(element => Object.keys(element).every(key => key !== "orderLineItemId"));
const finalData={
  ...form,deletedRows:deletedIds,newRows:filteredArray,orderId:dataProp.orderId,totalAmount:calculateTotal(dataGrid)
}
console.log("filteredArray",finalData)
const results = await window.Platform.database.updateOrderDetails(finalData);
window.NotificationUtils.showSuccess("Distributor order updated succesfully.");
setLoading(false)
onClose()
fetchData()
 
  }
  catch(err){
setLoading(false)

    console.log(err)
  }
}
console.log("TESTDATAGRID",seriesAdd?.length)

const additem=()=>{
  if(bookType===2){
  console.log("SELECTEDPRODUCT",selectedProduct)
  const length=dataGrid.length+1
  let pushedItem={"qty":form.qty,"price":selectedProduct.unitPrice,"total":form.qty*selectedProduct.unitPrice,"itemId":selectedProduct.skuId,id:length,"itemName":selectedProduct.nameSku, productGroup: productGroupMittsure.id}
  setSelectedProduct(null)
  setDataGrid([...dataGrid,pushedItem])
  }
  else if(bookType===1){
    setDataGrid([...dataGrid,...seriesAdd])
    setSelectedSeries(null)
    setSeriesAdd(null)

  }
}

const additem1=()=>{
  console.log("SELECTEDPRODUCT",selectSpecimen)
  const length=dataGrid.length+1
  let pushedItem={"qty":selectSpecimen.qty,"price":selectSpecimen.dicountedPrice,"total":selectSpecimen.totalPrice,"itemId":selectSpecimen.specimenId,id:length,"itemName":selectSpecimen.nameSku, productGroup: productGroupMittsure.id}
 console.log("PUSHEDITEM",pushedItem)
  setSelectedSpecimen(null)
  setDataGrid([...dataGrid,pushedItem])

}

const seriesHandler=(data)=>{

  if(orderType===1){
    setDisableSeries(true)
      changeHandler("series",data)
      console.log("Series",data)
      console.log("Series1234",selectDataFilter)
      setSelectDataFilter((prevForm) =>({...prevForm,series:data}))
    }
    else{
      console.log("Series",data)

      changeHandler("series",data)
      setSelectDataFilter1((prevForm) =>({...prevForm,series:data}))
    }

}


const boardHandler=(data)=>{
  if(orderType===1){
  setDisableSeries(true)
    changeHandler("board",data)
    setSelectDataFilter((prevForm) =>({...prevForm,board:data}))
  }
  else{
    changeHandler("board",data)
    setSelectDataFilter1((prevForm) =>({...prevForm,board:data}))
  }
}
const mediumHandler=async(data)=>{
  if(orderType===1){

  setDisableSeries(true)
    changeHandler("medium",data)
    setSelectDataFilter((prevForm) =>({...prevForm,medium:data}))
  }else{
    changeHandler("medium",data)
    setSelectDataFilter1((prevForm) =>({...prevForm,medium:data}))
  }
}
const classHandler=(data)=>{
  if(orderType===1){

  setDisableSeries(true)
    changeHandler("classId",data)
    setSelectDataFilter((prevForm) =>({...prevForm,class:data}))
  }
  else{
    changeHandler("classId",data)
    setSelectDataFilter1((prevForm) =>({...prevForm,class:data}))
  }
  }

const getBoard=async(data)=>{
    let results = await window.Platform.database.getBoard();
    setBoard(results.data)


  }
  const getMedium=async(data)=>{
    let results = await window.Platform.database.getMedium();
    const finalData=results?.data?.filter(medium=>medium.mediumTableId!="buCiAvMirI")

    setMedium(finalData)


  }



   const fetchProduct=async(data)=>{
    const results = await window.Platform.database.fetchProduct(data);
    const jsonArrayWithId = results?.data?.map((obj, index) => ({ ...obj, id: index + 1 }));

    setProduct(jsonArrayWithId)
    setProduct1(jsonArrayWithId)

   }

   const getClass=async(data)=>{
    const results = await window.Platform.database.getClass(data);
    const jsonArrayWithId = results?.data?.map((obj, index) => ({ ...obj, id: index + 1 }));

    setClassName(jsonArrayWithId)

   }

   const fetchProductById=async(data)=>{
    console.log("FECTHERING",data)
    const results = await window.Platform.database.fetchProductById({id:data});
    const length=dataGrid.length
    const mappedArray = results?.data?.map((selectSpecimen, index) => ({
      qty: form?.qty,
      price: selectSpecimen?.unitPrice,
      total: form?.qty*selectSpecimen?.unitPrice,
      itemId: selectSpecimen.skuId,
      id: index + length + 1, // assuming you want a unique identifier based on the array index
      itemName: selectSpecimen?.nameSku,
      productGroup: productGroupMittsure.id
    })); 
    setSeriesAdd([...mappedArray])
  }


  const fetchEmployees=async(data)=>{
    setLoading(true)
    const results = await window.Platform.database.getUsersForWork(data);
    setEmployee(results.data)
    setAssignedEmp(null)
    if(!status){
      const filteredEmp=results?.data?.filter((data)=>data.id===dataProp.ownerId)
      console.log("filteredEmp",filteredEmp);
      setAssignedEmp(filteredEmp[0])
     }
    setLoading(false)

   }
   const calculateTotal= (selectedRows) => {
    // console.log(selectedRows)
    if (!Array.isArray(selectedRows)) {
      return 0; // Default value if selectedRows is not an array
    }
    // console.log('Calculating total weight',selectedRows)
    const totalWeight = selectedRows.reduce(
      (sum, row) => sum + (row.total? row.total:row.Total),
      0
    );
    return Math.ceil(totalWeight);
  };
 
  const onsubmit=async(data)=>{
    try{
    setLoading(true)
    console.log("DATAAAAA",form)
    console.log("DATAAAAA1",dataGrid)
    const totalAmount=calculateTotal(dataGrid)
    console.log("DATAAAAA1",totalAmount)
    let finalData={...form,orders:dataGrid,totalAmount:totalAmount}
  //  const results = await window.Platform.database.createOrder({...form,orders:dataGrid,totalAmount:totalAmount});
  window.NotificationUtils.showSuccess("Distributor order added succesfully.");
  onClose1(finalData)
  setLoading(false)
    // fetchData()

  }
    catch(error){
    console.log(error)
    setLoading(false)

    }
      }
      const getColumns = () => {
        let result = [
          {
            field: "skuId",
            headerName: "SKU Id",
            sortable: false,
            width: 200,
            
            editable: false,
            renderCell: (params) => {
      
              let name = params.row?.itemId||"N/A"
      
              return (
      
                <Tooltip title={name || "N/A"}>
      
                  <Typography variant="inherit">{name}</Typography>
      
                </Tooltip>
      
              )
      
            },
          },
          {
            field: "nameSku",
            headerName: "SKU Name",
            width: 250,
            // height: 10,
            // headerAlign: 'center',
            sortable: false,
            // editable: true,
            renderCell: (params) => {
      console.log(params)
              let name =params.row.itemName?params.row.itemName:params.row.nameSku||"N/A"
      
              return (
      
                <Tooltip title={name || "N/A"}>
      
                  <Typography variant="inherit">{name}</Typography>
      
                </Tooltip>
      
              )
      
            },
          }
          ,
          {
            field: "qty",
            headerName: "Quantity",
            width: 150,
            // height: 10,
            // headerAlign: 'center',
            sortable: false,
            // editable: true,
            renderCell: (params) => {
      
              let name =params.row.qty?params.row.qty:params.row.QTY||0
      
              return (
      
                <Tooltip title={name || 0}>
      
                  <Typography variant="inherit">{name}</Typography>
      
                </Tooltip>
      
              )
      
            },
          },
          {
              field: "Price Unit",
              headerName: "Unit Price",
              width: 150,
              // height: 10,
              // headerAlign: 'center',
              sortable: false,
              // editable: true,
              renderCell: (params) => {
        
                let name =params.row.price?params.row.price:params.row.Price||0
        
                return (
        
                  <Tooltip title={name || 0}>
        
                    <Typography variant="inherit">{name}</Typography>
        
                  </Tooltip>
        
                )
        
              },
            },
            {
              field: "Price",
              headerName: "Total Price",
              width: 150,
              // height: 10,
              // headerAlign: 'center',
              sortable: false,
              // editable: true,
              renderCell: (params) => {
        
                let name =params.row.total?params.row.total:params.row.Total||0
        
                return (
        
                  <Tooltip title={name || 0}>
        
                    <Typography variant="inherit">{name}</Typography>
        
                  </Tooltip>
        
                )
        
              },
            },
            {
              field: "Actions",
              headerName: "Actions",
              width: 150,
              // height: 10,
              // headerAlign: 'center',
              sortable: false,
              // editable: true,
              renderCell: (params) => {
        
                let name =params.row.total||"N/A"
        
                return (
        
                 <IconButton onClick={()=>handleDeleteEmploye(params.row.id)}>
<DeleteIcon/>
                 </IconButton>
        
                )
        
              },
            },
      
           
      
       
        ];
      
      
        return result
      }

      console.log("RESPONSEDELETE",deletedIds)
      const handleDeleteEmploye = async (id) => {
    if(!status){
      let res1 = dataGrid.filter((el) => {
        return el.id === id;
      });
      if(res1[0]?.orderLineItemId){
      setDeletedIds([...deletedIds,res1[0].orderLineItemId])
      }
    }
        let res = dataGrid.filter((el) => {
          return el.id != id;
        });
    
        setDataGrid(res);
      };
    const clearData=()=>{
      console.log('clearData')
      if(orderType===1){

      setSelectedSeries(null)
      setDisableOther(false)
      setDisableSeries(false)
      setSelectDataFilter({board:null, medium:null, class:null,series:null})
      setForm((prevForm) => ({ ...prevForm, board:null, medium: null, classId:null,series:null,qty:null }));

      }
      else{
        setSelectDataFilter1({board:null, medium:null, class:null})
    setForm((prevForm) => ({ ...prevForm, board:null, medium: null, classId:null }));

      } 
  }
  return (
    <Dialog disableEnforceFocus maxWidth="lg" fullWidth={true} open={true} >
     {loading && <OpaqueLoading/>}
    <ModalHeaderContainer style={{marginBottom:2,paddingBottom:3}}>
       <Typography  variant={isMobile?"h6":"h5"}>
        Place Order
         <Tooltip title="Order Id">
 
           {id}
         </Tooltip>
           
           </Typography>
       <IconButton onClick={onClose} >
         <CloseOutlined />
       </IconButton>
     </ModalHeaderContainer>
     <TableContainer>
 
     <TabsContainer>
            <StyledTabs
              variant="fullWidth"
              value={tabValue}
              onChange={ handleChangeTab}
              indicatorColor="primary"
              textColor="primary"
            >
              <Tab label="Order Details" />
            { form?.orderType&& <Tab label="Product Details" />}
           
            </StyledTabs>
            {tabValue==1? renderContent() :renderContent1()}
 
          </TabsContainer>
   
     </TableContainer>
 
   </Dialog>
  );
};
 
export default AddOrderModalDistributor;
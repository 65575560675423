// material-ui
import { Box, Typography } from '@mui/material';

// project import
import NavGroup from './NavGroup';
// import menuItem from 'menu-items';
import pages from '../../../menu-items/pages';
import dashboard from '../../../menu-items/dashboard';
import utilities from '../../../menu-items/utilities';
import support from '../../../menu-items/support';
// ==============================|| DRAWER CONTENT - NAVIGATION ||============================== //
const menuItems = {
  items: [dashboard, pages]
};
const Navigation = (props) => {
  const navGroups = menuItems.items.map((item) => {
    // if(!props.moduleAccess.includes(item.children.title))
    // {
    //   return
    // }
    console.log("heyy",props.moduleAccess)
    switch (item.type) {
      case 'group':
        console.log("groupsqitch",item)
        
        return <NavGroup key={item.id} item={item} moduleAccess={props.moduleAccess} />;
      default:
        return (
          <Typography key={item.id} variant="h6" color="error" align="center">
            Fix - Navigation Group
          </Typography>
        );
    }
  });

  return <Box sx={{ pt: 2 }}>{navGroups}</Box>;
};

export default Navigation;

import react, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import {
  DataGrid,
  GridColDef,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton,
  GridValueGetterParams,
} from "@mui/x-data-grid";
import styled from "styled-components";
import Drawer from "../../components/common/drawer/drawer";
import { Typography, Button, Checkbox, Fab, Tab, Tabs, CircularProgress } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import { ERROR_COLOR, SHIPMENT_TYPES, SO_STATUS } from "../../constants";
import { Add, Inventory, Save, SaveAlt } from "@mui/icons-material";
import WarehouseModal from "../../components/modals/orderLineItemModal";
import InventoryItemsModal from "../../components/modals/inventoryItemsModal";
import PackagingListModal from "../../components/modals/packagingListModal";
import { useParams } from "react-router-dom";

const isMobile = window.innerWidth < 900;

const SHIPPING_ORDERS = [
  {
    id: "1",
    soId: "abcd",
    customer: {
      name: "Customer",
      mobile: "9876543210",
      city: "Kanpur",
    },
    supplier: {
      name: "Supplier",
      wechatNumber: "9876543210",
      deliveryDate: "01 July 2023",
      warehouseContact: "9876543210",
    },
    items: [
      {
        id: "1",
        item: "Item 1",
        category: "Category 1",
        volume: 100,
        qty: 20,
        ctn: 2,
        weight: 200,
        cbm: 50,
        unit: 'kg',
        rate: 20,
        amount: 4000

      }
    ],
    packagingList: {
      id: '1',
      shippingMark: 'abcd',
      chineseDescription: 'chinese description...',
      englishDescription: 'english description...',
      brandLogo: 'url...',
      'pcs/ctn': 23,
      ctns: 23,
      quantity: 20,
      'gw/ctns': '10',
      gw: 10,
      ctnSize: 5,
      cbm: 100,
      photo: 'url...'
    },
    status: SO_STATUS.ON_THE_WAY, 
    shipmentType: SHIPMENT_TYPES.SEA
  }
]

const AIR_SHIPPING_ORDERS = [
  {
    id: "1",
    soId: "abcd",
    customer: {
      name: "Customer",
      mobile: "9876543210",
      city: "Kanpur",
    },
    supplier: {
      name: "Supplier",
      wechatNumber: "9876543210",
      deliveryDate: "01 July 2023",
      warehouseContact: "9876543210",
    },
    items: [
      {
        id: "1",
        item: "Item 1",
        category: "Category 1",
        volume: 100,
        qty: 20,
        ctn: 2,
        weight: 200,
        cbm: 50,
        unit: 'kg',
        rate: 20,
        amount: 4000

      }
    ],
    packagingList: {
      id: '1',
      shippingMark: 'abcd',
      chineseDescription: 'chinese description...',
      englishDescription: 'english description...',
      brandLogo: 'url...',
      'pcs/ctn': 23,
      ctns: 23,
      quantity: 20,
      'gw/ctns': '10',
      gw: 10,
      ctnSize: 5,
      cbm: 100,
      photo: 'url...'
    },
    status: SO_STATUS.ON_THE_WAY,
    shipmentType: SHIPMENT_TYPES.AIR
  }
]

const TABS = {
  SEA:0,
  AIR:1
}

const StaffEditorPageContainer = styled.div`
  height: ${isMobile ? `auto` : `calc(100vh - 90px)`};
  width: 100%;
  background-color: white;
  padding: 10px 15px;
  ${isMobile &&
  `
      position: relative;
  
      `}
`;

const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: ${isMobile ? "flex-start" : "space-between"};
  margin-bottom: 10px;
  flex-direction: ${isMobile ? "column" : "row"};
`;

const HeaderButtonsContainer = styled.div`
  ${isMobile && "margin-top:10px;"}
`;

const TableContainer = styled.div`
  height: calc(
    100vh - ${isMobile ? "56px - 20px - 82.23px" : "90px - 20px - 43.77px"}
  );
  width: 100%;
  border: solid 1px lightGrey;
  border-radius: 8px;
`;
const TabsContainer = styled.div`
  ${isMobile && `
    height: calc(100vh - 56px - 20px - 43.77px);
  `}
`
const StyledTabs = styled(Tabs)`
  border-bottom: solid 1px lightGrey;
`;
const AvatarContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const DataGridContainer = styled.div`
  height: calc(100vh - 205px);
  overflow-x: auto;
`;

const LoadingContainer = styled.div`
  height: calc(100% - 49px);
  display: flex;
  justify-content: center;
  align-items: center;
`;

// const TabsContainer = styled.div`
//   ${isMobile &&
//   `
//     height: calc(100vh - 56px - 20px);
//   `}
// `;

const GenerateReportButton = styled(Button)`
  margin-right: 10px !important;
`;
const BulkChangePinCodeButton = styled(Button)`
  margin-right: 10px !important;
`;

const PinCodeChangeButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const FabStyle = styled(Fab)`
  && {
    z-index: 1};
  }
`;

const StyledHeader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export default function MasterManager(props) {
  const { id } = useParams();

  const [loading, setLoading] = useState(false);
  const [shippingOrders, setShippingOrders] = useState(SHIPPING_ORDERS);
  const [showPackagingListModal, setShowPackagingListModal] = useState();
  const [showItemsModal, setShowItemsModal] = useState();
  const [selectedTab, setSelectedTab] = useState(0);


  const getInventoryColumns = () => {
    let result = [
      {
        field: "soId",
        headerName: "SO Id",
        sortable: false,
        width: 150,

        editable: true,
      },
      {
        field: "customerName",
        headerName: "Customer Name",
        width: 200,
        sortable: false,
        dataGetter: params => params.row.customer?.name || 'N/A',
        renderCell: params => {
          const value = params.row.customer?.name || 'N/A'
          return <Tooltip title={value}>{value}</Tooltip>
        }

      },
      {
        field: "customerMobile",
        headerName: "Customer Mobile",
        width: 200,
        sortable: false,
        dataGetter: params => params.row.customer?.mobile || 'N/A',
        renderCell: params => {
          const value = params.row.customer?.mobile || 'N/A'
          return <Tooltip title={value}>{value}</Tooltip>
        }
      },
      {
        field: "customerCity",
        headerName: "Customer City",
        width: 200,
        sortable: false,
        dataGetter: params => params.row.customer?.city || 'N/A',
        renderCell: params => {
          const value = params.row.customer?.city || 'N/A'
          return <Tooltip title={value}>{value}</Tooltip>
        }
      },
      {
        field: "supplierName",
        headerName: "Supplier Name",
        width: 200,
        sortable: false,
        dataGetter: params => params.row.supplier?.name || 'N/A',
        renderCell: params => {
          const value = params.row.supplier?.name || 'N/A'
          return <Tooltip title={value}>{value}</Tooltip>
        }
      },
      {
        field: "supplierWechatNumber",
        headerName: "Supplier Wechat Number",
        width: 200,
        sortable: false,
        dataGetter: params => params.row.supplier?.wechatNumber || 'N/A',
        renderCell: params => {
          const value = params.row.supplier?.wechatNumber || 'N/A'
          return <Tooltip title={value}>{value}</Tooltip>
        }
      },
      {
        field: "deliveryDate",
        headerName: "Delivery Date",
        width: 200,
        sortable: false,
        dataGetter: params => params.row.supplier?.deliveryDate || 'N/A',
        renderCell: params => {
          const value = params.row.supplier?.deliveryDate || 'N/A'
          return <Tooltip title={value}>{value}</Tooltip>
        }
      },
      {
        field: "status",
        headerName: "Status",
        width: 200,
        sortable: false,
        dataGetter: params => params.row.status || 'N/A',
        renderCell: params => {
          const value = params.row.status || 'N/A'
          return <Tooltip title={value}>{value}</Tooltip>
        }
      },
      {
        field: "shipmentType",
        headerName: "Shipment Type",
        width: 200,
        sortable: false,
        dataGetter: params => params.row.shipmentType || 'N/A',
        renderCell: params => {
          const value = params.row.shipmentType || 'N/A'
          return <Tooltip title={value}>{value}</Tooltip>
        }
      },
      {
        field: "items",
        headerName: "Items",
        width: 200,
        sortable: false,
        disableExport: true,
        renderCell: params => {
          const value = params.row.items || []
          // return <Button onClick={() => setShowItemsModal(value)} >View Items</Button>
          return <FabStyle variant="extended" color="primary" onClick={() => setShowItemsModal(value)} >
            View Items
          </FabStyle>
        }
      },
      {
        field: "packagingList",
        headerName: "Packaging List",
        width: 200,
        sortable: false,
        disableExport: true,
        renderCell: params => {
          const value = params.row.packagingList || []
          // return <Button onClick={() => setShowItemsModal(value)} >View Items</Button>
          return <FabStyle variant="extended" color="primary" onClick={() => setShowPackagingListModal(value)} >
            View Packaging List
          </FabStyle>
        }
      },

    ];


    return result
  }

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
        <GridToolbarExport
          csvOptions={{
            fileName: "fos-list",
          }}
        />
      </GridToolbarContainer>
    );
  }

  const handleTabChange = async (value) => {
    setShippingOrders([]);
    setSelectedTab(value);
    if(value === TABS.SEA){
      setShippingOrders(SHIPPING_ORDERS)
    }
    else if(value === TABS.AIR)
    {
      setShippingOrders(AIR_SHIPPING_ORDERS)
    }
  }

  const renderLoading = () => {
    return(
      <LoadingContainer>
        <CircularProgress color='primary'/>
      </LoadingContainer>
    )
  }

  const renderTabContent = () => {
    if(loading){
      return renderLoading();
    }
    else
    return (
      <DataGridContainer>
        <DataGrid
          className="payrollGrid"
          checkboxSelection={true}
          rows={shippingOrders}
          columns={getInventoryColumns()}
          rowHeight={60}
          disableSelectionOnClick
          disableRowSelectionOnClick
          autoPageSize
          components={{
            Toolbar: CustomToolbar,
          }}
          pageSizeOptions={[10]}
        />
      </DataGridContainer>
    );
  };

  const renderModals = () => {
    return <>
      {showItemsModal && <InventoryItemsModal
        onClose={() => setShowItemsModal(null)}
        items={showItemsModal}
      />}
      {showPackagingListModal && <PackagingListModal
        onClose={() => setShowPackagingListModal(null)}
        list={showPackagingListModal}
      />}
    </>
  }

  return (
    <>
      {isMobile && (
        <Drawer />
      )}
      <StaffEditorPageContainer>
        <HeaderContainer>
          <Typography variant="h5">Master Manager</Typography>
        </HeaderContainer>
        <TableContainer>
          <TabsContainer>
            <StyledTabs
              variant="fullWidth"
              value={selectedTab}
              onChange={(event, value) => handleTabChange(value)}
              indicatorColor="primary"
              textColor="primary"
            >
              <Tab label="Customers" />
              <Tab label="Suppliers" />
              <Tab label="Items" />
              <Tab label="Templates" />
            </StyledTabs>
            {renderTabContent()}
          </TabsContainer>
        </TableContainer>
        {renderModals()}
      </StaffEditorPageContainer>

    </>
  );
}

import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogContent,
  FormControl,
  FormControlLabel,
  FormGroup,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  Fab,
  DialogActions,
  Tabs,
  Tab,
  Autocomplete
} from "@mui/material";
import ForwardToInboxIcon from '@mui/icons-material/ForwardToInbox';
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

import Tooltip from "@mui/material/Tooltip";
import { Check as CheckIcon, Clear as ClearIcon, Close, CropSharp, Details, Translate } from "@mui/icons-material";
import { CloseOutlined } from "@mui/icons-material";
import { Stack } from "@mui/material";
import styled from "styled-components";
import { ACTIVITY_STATUS, ROLES } from "../../constants";
import moment from "moment";
import OpaqueLoading from "../opaqueLoading/opaqueLoading";
import { DataGrid, GridToolbarColumnsButton, GridToolbarContainer, GridToolbarDensitySelector, GridToolbarExport, GridToolbarFilterButton } from "@mui/x-data-grid";
import { AuthContext } from "../contextAPI/ContextAPI";
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import dayjs from "dayjs";

import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';


 
const isMobile = window.innerWidth < 900;
 
const TotalAmountTypography = styled(Typography)`
 
  position: absolute;
 
  bottom: 10px;
 
  right: 10px;
 
  font-size: ${isMobile ? "14px" : "inherit"};
 
  bottom: ${isMobile ? "85px" : "70px"};
 
  right: ${isMobile ? "15px" : "70px"};
 
`;
const ModalHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
  padding: 10px;
`;
const TableContainer = styled.div`
  // height: calc(
  //   100vh - ${isMobile ? "106px - 20px - 82.23px" : "100px - 20px - 23.77px"}
  // );
  width: ${isMobile?"100%":"98%"};
  border: solid 1px lightGrey;
  border-radius: 8px;
  margin-left:${isMobile?"0px":"10px"};
  margin-bottom:${isMobile?"0px":"10px"};

`;
const InputFieldContainer = styled.div`
  margin: 20px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  label {
    margin-bottom: 0px;
  }
`;
const TabsContainer = styled.div`
  ${isMobile && `
    height: calc(100vh - 176px - 20px - 43.77px);
  `}
`
const StyledTabs = styled(Tabs)`
  border-bottom: solid 1px lightGrey;
`;
 
const ModalActionsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 20px;
`;
 
const FirstButton = styled(Button)`
  margin-right: 10px !important;
`;
 
const StyledDialogContent = styled(DialogContent)`
  padding: 16px;
`;
 
const StyledDialog = styled(Dialog)`
  position: relative;
`;
 
const FabStyle = styled(Fab)`
  && {
    z-index: 1;
  }
`;
 
const DataGridContainer = styled.div`
  width: 100%;
 
 
`;
const DataGridContainer1 = styled.div`
  width: 100%;
  margin-bottom:${!isMobile&& "20px"};
 
//   height: 500px;
`;
 
const NoteText = styled(Typography)`
  margin-top: 10px !important;
`;
 
const Input = styled.input`
  display: none;
`;
 
 
const VisitModal = ({ propData,onClose,remarks,data,id,distributorId,fetchData,status}) => {
  console.log("DATAAUTH",propData);
  const [changePassword, setChangePassword] = useState(false)
  const [tabValue, setTabValue] = useState(0);
  const { auth, setAuth } = useContext(AuthContext);
 

 const [roles, setRoles] = useState([])
  const [plantCode,setPlantCode]=useState("")
  const [loading,setLoading]=useState(false)
// const [product,setProduct]=useState()
 const [deletedField,setDeletedFields]=useState([])
 const [hasChanges, setHasChanges] = useState(false);
 const [remark,setRemrak]=useState(remarks)
 const [form,setForm]=useState({
  phoneNumber:""||propData?.PhoneNumber,
  extra:""||propData?.extra,
  partyType:""||propData?.partyTypeId,
  ownerId:""||propData?.ownerId,
  partyId:""||propData?.partyId,
  visitEntryType:""||propData?.visitEntryType,
  tagUser:""||propData?.tag_user1,
  contactPerson:""||propData?.ContactPerson  ,
  workDone:""||propData?.workDone  ,
  status:""||propData?.Status,
  tentativeAmount:""||propData?.tentativeAmount  ,
  noOfBook:""||propData?.numberOfBooks,
  explainedCat:""||propData?.explainedCategory,
  intrestedCat:""||propData?.interestedCategory  ,
  feedback:""||propData?.feedback  ,
  remark:""||propData?.remarks,
  nextStep:""||propData?.nextStep,
  followUpDate: propData?.followUpDate ? dayjs(propData?.followUpDate) : '',
  startTime: propData?.startTime ? dayjs(propData?.startTime) : null,
  endTime: propData?.endTime ? dayjs(propData?.endTime) : '',
  startMeetingTime: propData?.start_time_meeting ? dayjs(propData?.start_time_meeting) : '',
  endMeetingTime: propData?.end_time_meeting ? dayjs(propData?.end_time_meeting) : '',
  visitOutcome: '' || propData?.visitOutcome
 })
//  const [form,setForm]=useState({
//   contactPerson:""||propData?.ContactPerson  ,
//   workDone:""||propData?.workDone  ,
//   status:""||propData?.Status,
//   tentativeAmount:""||propData?.tentativeAmount  ,
//   noOfBook:""||propData?.numberOfBooks,
//   explainedCat:""||propData?.explainedCategory,
//   intrestedCat:""||propData?.interestedCategory  ,
//   feedback:""||propData?.feedback  ,
//   remark:""||propData?.remarks,
//   nextStep:""||propData?.nextStep,


//  })

 const [errors, setErrors] = useState({});

 const [employee,setEmployee]=useState([])
 const [assignedEmp,setAssignedEmp]=useState(null)
 const [partyType,setPartyType]=useState()
 const [party,setParty]=useState([])
 const [selectParty,setSelectParty]=useState(null)
 const [date,setDate]=useState()
const [product,setProduct]=useState()
const [product1,setProduct1]=useState()

const [selectProduct,setSelectProduct]=useState([])
const [selectProduct1,setSelectProduct1]=useState([])
const [fetchPartyTypes,setfetchedPartyType]=useState(0)
const [statusWork,setStatusWork]=useState([])

const [series,setSeries]=useState([])
const [selectedSeries,setSelectedSeries]=useState(null)
const [selectedSeries1,setSelectedSeries1]=useState(null)
const [workDone,setWorkDone]=useState([])
const [nextStep,setNextStep]=useState([])
const [feedback,setFeedback]=useState([])
const [visitType,setVisitType]=useState([])
const [visitTypeCheck,setVisitTypeCheck]=useState(false)
const [partyTypeCheck,setPartyTypeCheck]=useState(false)
const [colleagueUser,setColleagueUser]=useState([])

  const [visitOutcomes, setVisitOutcomes] = useState([]);
  const [startVisit, setStartVisit] = useState(null);
  const [startMeeting, setStartMeeting] = useState(null);
  const [endVisit, setEndVisit] = useState(null);
  const [endMeeting, setEndMeeting] = useState(null);
  const [startVisitP, setStartVisitP] = useState(null);
  const [startMeetingP, setStartMeetingP] = useState(null);
  const [endVisitP, setEndVisitP] = useState(null);
  const [endMeetingP, setEndMeetingP] = useState(null);




console.log("SELECTPRODUCT",selectProduct)

useEffect(()=>{
  // if(tabValue===1){
  //   fetchProduct(plantCode,distributorId)
  // }
  fetchProduct()
  // getSchool()
  getNextStep()
  getFeedback()
  getWorkDone()
  fetchPartyType()
  fetchEmployees()
  getStatus()
  getSeriesCategory()
  getVisitType()
  getVisitOutcomes();
  if (propData) {
    setStartVisit(dayjs(propData?.startTime));
    setEndVisit(dayjs(propData?.endTime));
    setStartMeeting(dayjs(propData?.start_time_meeting));
    setEndMeeting(dayjs(propData?.end_time_meeting));
  }
  console.log("currentTab", tabValue, propData)
  },[])

  useEffect(()=>{
    // if(tabValue===1){
    //   fetchProduct(plantCode,distributorId)
    // }
    
    if(!status){
      fetchColleague(propData?.ownerId)
      const newDate=new Date(propData.followUpDate)
      formatDate(dayjs(newDate))
      if(propData?.partyTypeId==="cQpLw8vwZf"){
        setfetchedPartyType(1)
  getSchool({ownerId:propData?.ownerId
  })
      }else{
        getDistributor({ownerId:propData?.ownerId
        })
        setfetchedPartyType(2)
  
      }
  
      
    }
    console.log("currentTab", tabValue)
    },[])

  /* Date Pickers Validation logic */
  const now = dayjs().endOf('day');;

  const handleStartVisitChange = (newValue) => {
    const dayjsNewValue = dayjs(newValue);
    const unixTimestamp = formatDate('startTime', newValue);
    setStartVisit(dayjsNewValue);
    setStartVisitP(unixTimestamp);
    // setForm((prev) => {
    //   return {...prev, startTime: dayjsNewValue}
    // })
    if (endVisit && newValue && dayjs(endVisit).isBefore(dayjsNewValue)) {
      setEndVisit(null);
    }
  };

  const handleStartMeetingChange = (newValue) => {
    const startVisitTime = dayjs(startVisit);

    if (newValue && startVisitTime && newValue.isBefore(startVisitTime)) {
      return;
    }

    const unixTimestamp = formatDate('startMeetingTime', newValue);
    setStartMeeting(startVisitTime);
    setStartMeetingP(unixTimestamp);
    // setForm((prev) => {
    //   return {...prev, startMeetingTime: startVisitTime}
    // })
    if (endMeeting && newValue && dayjs(endMeeting).isBefore(startVisitTime)) {
      setEndMeeting(null);
    }
  };

  const handleEndVisitChange = (newValue) => {
    const endTime = dayjs(newValue);

    if (newValue && endTime && newValue.isBefore(endTime)) {
      return;
    }

    const unixTimestamp = formatDate('endTime', newValue);
    setEndVisit(endTime);
    setEndVisitP(unixTimestamp);
    // setForm((prev) => {
    //   return {...prev, endTime: endTime}
    // })
  };

  const handleEndMeetingChange = (newValue) => {
    const dayjsNewValue = dayjs(newValue);
    const unixTimestamp = formatDate('endMeetingTime', newValue);
    setEndMeeting(dayjsNewValue);
    setEndMeetingP(unixTimestamp);
    // setForm((prev) => {
    //   return {...prev, endMeetingTime: dayjsNewValue}
    // })
  };

const getSeriesCategory=async()=>{
  let results = await window.Platform.database.getSeriesCategory();
  setSeries(results.data)

}
const fetchColleague=async(data)=>{
  let results = await window.Platform.database.getManager({ownerId:data});
  setColleagueUser(results.data)

}

const getWorkDone=async()=>{
  let results = await window.Platform.database.getWorkDone();
  setWorkDone(results.data)

}

const getNextStep=async()=>{
  let results = await window.Platform.database.getNextStep();
  setNextStep(results.data)

}

const getFeedback=async()=>{
  let results = await window.Platform.database.getFeedback();
  setFeedback(results.data)

}
const getVisitType=async()=>{
  let results = await window.Platform.database.getVisitType();
  setVisitType(results.data)

}

const getVisitOutcomes = async () => {
  try {

    setLoading(true);
    let results = await window.Platform.database.getVisitOutcomes();
    setVisitOutcomes(results.data);
    
    // const jsonArrayWithId = results?.data?.map((obj, index) => ({
    //   ...obj,
    //   id: index + 1,
    // }));

    // setAccessChanges(jsonArrayWithId);
    setLoading(false);

  } catch (error) {
    window.NotificationUtils.showError("Error Fetching Data");
    setLoading(false);
  }
}

const fetchProductById=async(data,data1)=>{
  setLoading(true)
  console.log("FECTHERING",data)
  const results = await window.Platform.database.fetchProductById({id:data});
  if(!data1){
setProduct(results.data)}
else{
  setProduct1(results.data)
}
setLoading(false)

 }




  const handleChangeTab = (event, newValue) => {
    console.log("newValue: " , newValue);
    setTabValue(newValue);
  };
   

  console.log("FORMM",form)
 



 
const changeHandler = (fieldName, value) => {
  if(fieldName==="phoneNumber" ||fieldName==="tentativeAmount"||fieldName==="noOfBook"){
    console.log("FIELDNAME",fieldName)
    const sanitizedValue = value.replace(/[^0-9]/g, '').slice(0, 10);
    setForm((prevForm) => ({ ...prevForm, [fieldName]: sanitizedValue }))
  }
  else{
    console.log(value)
    setForm((prevForm) => ({ ...prevForm, [fieldName]: value }));
  }
  };


  // const changeHandler = (fieldName, value) => {
  //    if(fieldName==="tentativeAmount"||fieldName==="noOfBook"){
  //   console.log("FIELDNAME",fieldName)
  //   const sanitizedValue = value.replace(/[^0-9]/g, '').slice(0, 10);
  //   setForm((prevForm) => ({ ...prevForm, [fieldName]: sanitizedValue }))
  // }
  // else{
  //   // console.log(value)
  //   setForm((prevForm) => ({ ...prevForm, [fieldName]: value }));
  // }
  //   // console.log(value)
  // };




  const getDistributor=async(data) => {
    setLoading(true)
    setParty([])
   const results = await window.Platform.database.getDistributor(data);
   setParty(results.data)
   setSelectParty(null)
    if(!status){
     const filteredEmp=results?.data?.filter((data)=>data.distributorID===propData.partyId)
     setSelectParty(filteredEmp[0])
     
    }
   setLoading(false)
  
  }
  const partyTypeHandler=(data)=>{
console.log("PARTYTYPECHECK",data)
    if(data==="cQpLw8vwZf"){
      setfetchedPartyType(1)
      getSchool({ownerId:form?.ownerId})
      setPartyTypeCheck(false)

    }else if(data==="P6E9TGXewd"){
      console.log("DISTRIBUTORCHECK")
      setfetchedPartyType(2)
      setPartyTypeCheck(false)


      getDistributor({ownerId:form?.ownerId})
    }else if(data==="GXjqJfCSLp"){
      setPartyTypeCheck(true)
    }else{
      setPartyTypeCheck(true)

    }
    changeHandler("partyType",data)
  }

  const visitTypeHandler=(data)=>{
    if(data==="NKlytW4260"){
      setVisitTypeCheck(true)
    }else{
      setVisitTypeCheck(false)

    }
    changeHandler("visitEntryType",data)
  }
const renderContent1=()=>{
  return (
    <div style={{ maxHeight:"510px",height:"505px", overflowY:"auto" }}>
    <Stack direction={"row"} spacing={2} padding={2}>
    <Autocomplete
            options={employee}
            // getOptionLabel={}
            // getOptionLabel={(option) => `${option?.name} (${option?.id})`}
            getOptionLabel={(option) => `${option?.name}`}
              getOptionValue={(option) => option?.id || ''}
            style={{ width: "100%" }}
            value={assignedEmp}
            // disabled={loading 
              // || !addNewMode || props.isManager
            // }
            noOptionsText={loading ? "Loading..." : "No option"}
              onChange={(event, value) => {
                console.log("TEST1212",value)
                changeHandler("ownerId", value?.id)
                setForm((prevForm) => ({ ...prevForm, partyType:"" }))
                setSelectParty(null)
                setAssignedEmp(value)
                fetchColleague(value?.id)
                //  updateDataGrid(value);
                
                // if(selectedCity.warehouseCountry === 'India' && warehouseData.attributes.warehouseCountry === 'China')
                // {
                //     setDisableTransfer(false)
                // }
                // else
                // setDisableTransfer(true)

               
                
              }}
              onInputChange={(e, value) => {
                // Check if the user's input matches any option
                // const matchingOption = employee.find((option) => option.name === value.name);

                
              }}
            renderInput={(params) => (
              <TextField
                {...params}
                // disabled={
                //   loading 
                  // || !addNewMode || props.isManager || props.isDeleted
                // }
                label={"Visit Marked For"}
                required
                variant="outlined"
                error={errors.visitMarkedFor ? true : false}
              />
            )}/>
             <FormControl fullWidth>
      <InputLabel id="select-label" required error={errors.visitType ? true : false}>Visit Type</InputLabel>
      <Select
        labelId="select-label"
        id="select"
        value={form.visitEntryType}
        onChange={(e)=>visitTypeHandler(e.target.value)}
        label="Select Option"
        required
        error={errors.visitType ? true : false}
      >
        {visitType?.map((option) => (
        
            <MenuItem key={option.visitTypeId} value={option.visitTypeId}>
              {option.typeName}
            </MenuItem>
   ))} 
      </Select>
    </FormControl>
          <FormControl fullWidth>
      <InputLabel id="select-label" required error={errors.partyType ? true : false}>Party Type</InputLabel>
      <Select
        labelId="select-label"
        id="select"
        value={form.partyType}
        onChange={(e)=>partyTypeHandler(e.target.value)}
        label="Select Option"
        required
        error={errors.partyType ? true : false}
      >
        {partyType?.map((option) => (
        
            <MenuItem key={option.partyTypeId} value={option.partyTypeId}>
              {option.type}
            </MenuItem>
   ))} 
      </Select>
    </FormControl>
  
          </Stack>
         
          <Stack direction={"row"} spacing={2} padding={2}>
          {fetchPartyTypes===1?
    <Autocomplete
            options={party}
            // getOptionLabel={}
            // getOptionLabel={(option) => `${option?.schoolName} (${option?.schoolId})`}
            getOptionLabel={(option) => `${option?.schoolName}`}
              getOptionValue={(option) => option?.schoolId || ''}
            style={{ width: "100%" }}
            value={selectParty}
            // disabled={loading 
              // || !addNewMode || props.isManager
            // }
            disabled={fetchPartyTypes!=1}
            noOptionsText={loading ? "Loading..." : "No option"}
              onChange={(event, value) => {
                console.log("TEST1212",value)
                changeHandler("partyId", value?.schoolId)
                setSelectParty(value)
                //  updateDataGrid(value);
                
                // if(selectedCity.warehouseCountry === 'India' && warehouseData.attributes.warehouseCountry === 'China')
                // {
                //     setDisableTransfer(false)
                // }
                // else
                // setDisableTransfer(true)

               
                
              }}
              onInputChange={(e, value) => {
                // Check if the user's input matches any option

               
              }}
            renderInput={(params) => (
              <TextField
                {...params}
                // disabled={
                //   loading 
                  // || !addNewMode || props.isManager || props.isDeleted
                // }
                label={"Select Party"}
                required
                variant="outlined"
                error={errors.party ? true : false}
              />
            )}/>
            :
            <Autocomplete
            options={party}
            // getOptionLabel={}
            // getOptionLabel={(option) => `${option?.DistributorName} (${option?.distributorID})`}
            getOptionLabel={(option) => `${option?.DistributorName}`}
              getOptionValue={(option) => option?.schoolId || ''}
            style={{ width: "100%" }}
            value={selectParty}
            // disabled={loading 
              // || !addNewMode || props.isManager
            // }
            disabled={fetchPartyTypes!=2}

            noOptionsText={loading ? "Loading..." : "No option"}
              onChange={(event, value) => {
                console.log("TEST1212",value)
                changeHandler("partyId", value?.distributorID)
                setSelectParty(value)
                //  updateDataGrid(value);
                
                // if(selectedCity.warehouseCountry === 'India' && warehouseData.attributes.warehouseCountry === 'China')
                // {
                //     setDisableTransfer(false)
                // }
                // else
                // setDisableTransfer(true)

               
                
              }}
              onInputChange={(e, value) => {
                // Check if the user's input matches any option

               
              }}
            renderInput={(params) => (
              <TextField
                {...params}
                // disabled={
                //   loading 
                  // || !addNewMode || props.isManager || props.isDeleted
                // }
                label={"Select Party"}
                required
                variant="outlined"
                error={errors.party ? true : false}
              />
            )}/>}
   <TextField label="Contact Person Name" value={form?.contactPerson || ""} disabled={visitTypeCheck}  type="text"fullWidth onChange={(e)=>changeHandler("contactPerson",e.target.value)} required error={errors.personName ? true : false}/>

          </Stack>
          <Stack direction={"row"} spacing={2} padding={2}>
          <TextField label="Contact Person Mobile No" disabled={visitTypeCheck} value={form?.phoneNumber || ""} type="text" fullWidth onChange={(e)=>changeHandler("phoneNumber",e.target.value)} required error={errors.contactPerson ? true : false} />
   {/* <TextField label="Work Done" value={form?.workDone || ""} type="text"fullWidth onChange={(e)=>changeHandler("workDone",e.target.value)}/> */}
   <FormControl style={{ width: isMobile?"100%":"100%" }}>
      <InputLabel id="select-label"  required error={errors.status ? true : false}>Status</InputLabel>
      <Select
        labelId="select-label"
        id="select"
        value={form.status||""}
        disabled={visitTypeCheck||partyTypeCheck}  
        onChange={(e)=>changeHandler("status",e.target.value)}
        label="Select Option"
        required 
        error={errors.status ? true : false}
      >
         {statusWork?.map((option, optionIndex) => (
    <MenuItem key={option.statusTypeId} value={option.statusTypeId}>
      {option.statusTypeName}
    </MenuItem>
  ))}
      </Select>
    </FormControl>

          </Stack>
          <Stack direction={"row"} spacing={2} padding={2}>
          <FormControl style={{ width: isMobile?"100%":"100%" }}>
      <InputLabel id="select-label">Next Step</InputLabel>
      <Select
        labelId="select-label"
        id="select"
        disabled={visitTypeCheck||partyTypeCheck||form.status==="Not Intrested"||!form.status} 
        value={form.nextStep||""}
        onChange={(e)=>changeHandler("nextStep",e.target.value)}
        label="Select Option"
      >
         {nextStep?.map((option, optionIndex) => (
    <MenuItem key={option.nextStepTableId} value={option.nextStepTableId}>
      {option.nextStepName}
    </MenuItem>
  ))}
      </Select>
    </FormControl>
    <FormControl style={{ width: isMobile?"100%":"100%" }}>
      <InputLabel id="select-label" required error={errors.workDone ? true : false}>Work Done</InputLabel>
      <Select
        labelId="select-label"
        id="select"
        value={form?.workDone||""}
        onChange={(e)=>changeHandler("workDone",e.target.value)}
        label="Select Option"
        disabled={visitTypeCheck} 
        required 
        error={errors.workDone ? true : false}
      >
         {workDone?.map((option, optionIndex) => (
    <MenuItem key={option.workDoneTableId} value={option.workDoneTableId}>
      {option.workDoneName}
    </MenuItem>
  ))}
      </Select>
    </FormControl>
      

          </Stack>
        
          <Stack direction={"row"} spacing={2} padding={2}>
   <TextField label="Tentative Amount" disabled={visitTypeCheck||partyTypeCheck}   value={form?.tentativeAmount || ""} type="text"fullWidth onChange={(e)=>changeHandler("tentativeAmount",e.target.value)}/>
          <TextField label="No Of Book" disabled={visitTypeCheck||partyTypeCheck}   value={form?.noOfBook || ""} type="text" fullWidth onChange={(e)=>changeHandler("noOfBook",e.target.value)}/>
        
       
          </Stack>
          <Stack direction={"row"} spacing={2} padding={2}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label="Follow Up Date"
              disabled={visitTypeCheck || loading || partyTypeCheck}
              value={form.followUpDate ? dayjs(form.followUpDate) : null}
              sx={{ width: "100%" }}
              format="YYYY/MM/DD"
              onChange={(data) => changeHandler('followUpDate', moment(Number(data)).format('YYYY/MM/DD'))}
              disablePast={form.followUpDate ? false : true}
              readOnly={form.followUpDate ? true : false}
            />
          </LocalizationProvider>
          <FormControl style={{ width: isMobile?"100%":"100%" }}>
      <InputLabel id="select-label">Tag Colleague</InputLabel>
      <Select
        labelId="select-label"
        id="select"
        value={form.tagUser||""}
        disabled={!form.ownerId} 
        onChange={(e)=>changeHandler("tagUser",e.target.value)}
        label="Select Option"
      >
         {colleagueUser?.map((option, optionIndex) => (
    <MenuItem key={option.id} value={option.id}>
      {option.name}
    </MenuItem>
  ))}
      </Select>
    </FormControl>
       
          </Stack>

          <Stack sx={{ width: '100%' }} direction={"row"} spacing={2} padding={2}>
          <Box sx={{ flex: 1 }}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DateTimePicker
                sx={{ width: '100%' }}
                label="Start Visit Time"
                value={form.startTime ? dayjs.unix(form.startTime) : null}
                readOnly={propData?.startTime ? true : false}
                onChange={handleStartVisitChange}
                maxDateTime={now}
              />
            </LocalizationProvider>
          </Box>

          <Box sx={{ flex: 1 }}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DateTimePicker
                sx={{ width: '100%' }}
                label="Start Meeting Time"
                value={form.startMeetingTime ? dayjs.unix(form.startMeetingTime) : null}
                onChange={handleStartMeetingChange}
                disabled={!startVisit}
                readOnly={propData?.start_time_meeting ? true : false}
                maxDateTime={now}
                minDateTime={startVisit}
              />
            </LocalizationProvider>
          </Box>
        </Stack>

        <Stack direction={"row"} spacing={2} padding={2}>
          <Box sx={{ flex: 1 }}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DateTimePicker
                sx={{ width: '100%' }}
                label="End Meeting Time"
                value={form.endMeetingTime ? dayjs.unix(form.endMeetingTime) : null}
                onChange={handleEndMeetingChange}
                disabled={!startMeeting}
                readOnly={propData?.end_time_meeting ? true : false}
                maxDateTime={now}
                minDateTime={startMeeting}
              />
            </LocalizationProvider>
          </Box>

          <Box sx={{ flex: 1 }}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DateTimePicker
                sx={{ width: '100%' }}
                label="End Visit Time"
                value={form.endTime ? dayjs.unix(form.endTime) : null}
                onChange={handleEndVisitChange}
                disabled={!startVisit}
                readOnly={propData?.endTime ? true : false}
                maxDateTime={now}
                minDateTime={endMeeting}
              />
            </LocalizationProvider>
          </Box>
        </Stack>

             
         
     
      
</div>
)}




const formatDate=(type = null, data)=>{
    console.log("DATAAAA",data   )
    const timestamp = new Date(data);
  const datePickerResponse = new Date(data);

    const year = datePickerResponse.getFullYear();
const month = String(datePickerResponse.getMonth() + 1).padStart(2, '0');
const day = String(datePickerResponse.getDate()).padStart(2, '0');
const formattedDate = `${year}/${month}/${day}`;

const unixTimestamp = Math.floor(timestamp.getTime() / 1000);

setDate(data)

  if (type) {
    changeHandler(type, unixTimestamp)
  } else {
    changeHandler("followUpDate",unixTimestamp)
  }
console.log("UNIX",unixTimestamp);
}

  const renderContent = (params) => {
    console.log("CheckTeabRenderContent",tabValue)
    return (
      <div style={{ maxHeight:"510px",height:"505px", overflowY:"auto" }}>
       
          <Stack direction={"row"} spacing={2} padding={2}>
          <Autocomplete
          options={series}
          // getOptionLabel={}
          // getOptionLabel={(option) => `${option?.seriesName} (${option?.seriesTableId})`}
          getOptionLabel={(option) => `${option?.seriesName}`}
            getOptionValue={(option) => option?.seriesTableId || ''}
          style={{ width: "60%" }}
          // value={selectedSeries}
          value={selectedSeries}
          // disabled={loading 
            // || !addNewMode || props.isManager
          // }
          noOptionsText={loading ? "Loading..." : "No option"}
            onChange={(event, value) => {
              console.log("TEST1212",value)
              changeHandler("explainedCat",value?.seriesTableId)
              if(value){
              setSelectedSeries(value)
              fetchProduct(3,value?.seriesTableId)
              }
              else{
              setSelectedSeries(value)

                fetchProduct(3)
              }
              //  updateDataGrid(value);
              
              // if(selectedCity.warehouseCountry === 'India' && warehouseData.attributes.warehouseCountry === 'China')
              // {
              //     setDisableTransfer(false)
              // }
              // else
              // setDisableTransfer(true)

             
              
            }}
            onInputChange={(e, value) => {
              // Check if the user's input matches any option
              const matchingOption = employee.find((option) => option.name === value.name);

             
            }}
          renderInput={(params) => (
            <TextField
              {...params}
              // disabled={
              //   loading 
                // || !addNewMode || props.isManager || props.isDeleted
              // }
              label={"Explained Category"}
              required
              variant="outlined"
              error={errors.explainedCategory ? true : false}
            />
          )}/>

{/* <FormControl fullWidth variant="outlined" error={!!errors.explainedCategory}>
      <InputLabel required>Explained Category</InputLabel>
      <Select
        value={selectedSeries}
        onChange={(event, value) => {
          // const value = series.find(option => option.seriesTableId === event.target.value);
          console.log("Selected Series:", value);
          changeHandler("explainedCat", value?.seriesTableId);

          if(value){
            setSelectedSeries(value)
            fetchProduct(3,value?.seriesTableId)
            }
            else{
            setSelectedSeries(value)

              fetchProduct(3)
            }
        }}
        label="Explained Category"
        disabled={loading}
      >
        {series.length > 0 ? (
          series.map((option) => (
            <MenuItem key={option.seriesTableId} value={option.seriesTableId}>
              {option.seriesName}
            </MenuItem>
          ))
        ) : (
          <MenuItem disabled>{loading ? "Loading..." : "No option"}</MenuItem>
        )}
      </Select>
    </FormControl> */}

            <Autocomplete
            multiple
            options={product}
            // getOptionLabel={}
            // getOptionLabel={(option) => `${option?.nameSku} (${option?.skuId})`}
            getOptionLabel={(option) => `${option?.nameSku}`}
              getOptionValue={(option) => option?.skuId || ''}
            style={{ width: "100%" }}
            value={selectProduct}
            // disabled={loading 
              // || !addNewMode || props.isManager
            // }
            noOptionsText={loading ? "Loading..." : "No option"}
              onChange={(event, value) => {
                console.log("TEST1212",value)
                // changeHandler("productExp", form.productExp.push(value.skuId))
                changeHandler("productExp", value?.skuId)
                setSelectProduct(value)
                //  updateDataGrid(value);
                
                // if(selectedCity.warehouseCountry === 'India' && warehouseData.attributes.warehouseCountry === 'China')
                // {
                //     setDisableTransfer(false)
                // }
                // else
                // setDisableTransfer(true)

               
                
              }}
              onInputChange={(e, value) => {
                // Check if the user's input matches any option
                // const matchingOption = product.find((option) => option.skuId === value.skuId);

                // if (!matchingOption ) {
                //   // If there's no matching option, set the otherValue to null
                // //   setDeliveryCity(null);
                // setSelectProduct();
                // return;
                // }
              }}
            renderInput={(params) => (
              <TextField
                {...params}
                // disabled={
                //   loading 
                  // || !addNewMode || props.isManager || props.isDeleted
                // }
                label={"Explained Product"}
                required
                variant="outlined"
                error={errors.explainedProduct ? true : false}
              />
            )}/>
          
          </Stack>

          <Stack direction={"row"} spacing={2} padding={2}>
          {/* <Autocomplete
          options={series}
          // getOptionLabel={}
          // getOptionLabel={(option) => `${option?.seriesName} (${option?.seriesTableId})`}
          getOptionLabel={(option) => `${option?.seriesName}`}
            getOptionValue={(option) => option?.seriesTableId || ''}
          style={{ width: "60%" }}
          // value={selectedSeries1}
          value={form.interestedCategory}
          // disabled={loading 
            // || !addNewMode || props.isManager
          // }
          noOptionsText={loading ? "Loading..." : "No option"}
            onChange={(event, value) => {
              console.log("TEST1212",value)
              changeHandler("intrestedCat",value?.seriesTableId)

              if(value){
              setSelectedSeries1(value)
              fetchProduct(2,value?.seriesTableId)
              }
              else{
              setSelectedSeries1(value)

                fetchProduct(2)
              }
              //  updateDataGrid(value);
              
              // if(selectedCity.warehouseCountry === 'India' && warehouseData.attributes.warehouseCountry === 'China')
              // {
              //     setDisableTransfer(false)
              // }
              // else
              // setDisableTransfer(true)

             
              
            }}
            onInputChange={(e, value) => {
              // Check if the user's input matches any option
              const matchingOption = employee.find((option) => option.name === value.name);

             
            }}
          renderInput={(params) => (
            <TextField
              {...params}
              // disabled={
              //   loading 
                // || !addNewMode || props.isManager || props.isDeleted
              // }
              label={"Interested Category"}
              required
              variant="outlined"
              error={errors.interestedCategory ? true : false}
            />
          )}/> */}
          
          <FormControl fullWidth variant="outlined" error={!!errors.interestedCategory}>
      <InputLabel required>Interested Category</InputLabel>
      <Select
        value={form.intrestedCat || ''}
        onChange={(event) => {
          const selectedValue = event.target.value;
          console.log("Selected Value:", selectedValue);
          
          const selectedOption = series.find(option => option.seriesTableId === selectedValue);
          changeHandler("intrestedCat", selectedOption?.seriesTableId);

          if (selectedOption) {
            setSelectedSeries1(selectedOption);
            fetchProduct(2, selectedOption.seriesTableId);
          } else {
            setSelectedSeries1(null);
            fetchProduct(2);
          }
        }}
        label="Interested Category"
        disabled={loading}
      >
        {series.length > 0 ? (
          series.map((option) => (
            <MenuItem key={option.seriesTableId} value={option.seriesTableId}>
              {option.seriesName}
            </MenuItem>
          ))
        ) : (
          <MenuItem disabled>{loading ? "Loading..." : "No option"}</MenuItem>
        )}
      </Select>
    </FormControl>

            <Autocomplete
            multiple
            options={product1}
            // getOptionLabel={}
            // getOptionLabel={(option) => `${option?.nameSku} (${option?.skuId})`}
            getOptionLabel={(option) => `${option?.nameSku}`}
              getOptionValue={(option) => option?.skuId || ''}
            style={{ width: "100%" }}
            value={selectProduct1}
            // disabled={loading 
              // || !addNewMode || props.isManager
            // }
            noOptionsText={loading ? "Loading..." : "No option"}
              onChange={(event, value) => {
                console.log("TEST1212",value)
                
                // changeHandler("productInt", form.value.skuId)
                changeHandler("productInt", value?.skuId)
                setSelectProduct1(value)
                //  updateDataGrid(value);
                
                // if(selectedCity.warehouseCountry === 'India' && warehouseData.attributes.warehouseCountry === 'China')
                // {
                //     setDisableTransfer(false)
                // }
                // else
                // setDisableTransfer(true)

               
                
              }}
              onInputChange={(e, value) => {
                // Check if the user's input matches any option
                // const matchingOption = product.find((option) => option.skuId === value.skuId);

                // if (!matchingOption ) {
                //   // If there's no matching option, set the otherValue to null
                // //   setDeliveryCity(null);
                // setSelectProduct1();
                // return;
                // }
              }}
            renderInput={(params) => (
              <TextField
                {...params}
                // disabled={
                //   loading 
                  // || !addNewMode || props.isManager || props.isDeleted
                // }
                label={"Interested Product"}
                required
                variant="outlined"
                error={errors.interestedProduct ? true : false}
              />
            )}/>
          
</Stack>
          
          <Stack direction={"row"} spacing={2} padding={2}>
{/* <TextField label="Feedback" value={form?.feedback} type="text" fullWidth onChange={(e)=>changeHandler("feedback",e.target.value)}/> */}
<FormControl style={{ width: isMobile?"100%":"100%" }}>
      <InputLabel id="select-label">Feedback</InputLabel>
      <Select
        labelId="select-label"
        id="select"
        value={form?.feedback||""}
        onChange={(e)=>changeHandler("feedback",e.target.value)}
        label="Select Option"
      >
         {feedback?.map((option, optionIndex) => (
    <MenuItem key={option.feedbackId} value={option.feedbackId}>
      {option.feedbackName}
    </MenuItem>
  ))}
      </Select>
    </FormControl>
          <TextField label="Remarks" value={form?.remark} type="text" fullWidth onChange={(e)=>changeHandler("remark",e.target.value)}/>
         
              
          </Stack>

          {/* <Stack direction={"row"} spacing={2} padding={2}>
            <Autocomplete
              options={visitOutcomes}
              disabled={loading}
              getOptionLabel={(option) => `${option?.name}`}
              getOptionValue={(option) => option?.id || ''}
              style={{ width: "100%" }}
              value={form.visitOutcomeId}
              noOptionsText={loading ? "Loading..." : "No option"}
              onChange={(event, value) => {
                changeHandler("visitOutcomeId", value?.id);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={"Select Visit Outcome"}
                  variant="outlined"
                />
              )} />
          </Stack> */}

<Stack direction={"row"} spacing={2} padding={2}>
      <FormControl fullWidth variant="outlined" disabled={loading}>
        <InputLabel>Select Visit Outcome</InputLabel>
        <Select
          value={form.visitOutcome || ''}
          onChange={(event) => {
            const selectedValue = event.target.value;
            changeHandler("visitOutcome", selectedValue);
          }}
          label="Select Visit Outcome"
        >
          {visitOutcomes.length > 0 ? (
            visitOutcomes.map((option) => (
              <MenuItem key={option.id} value={option.id}>
                {option.name}
              </MenuItem>
            ))
          ) : (
            <MenuItem disabled>{loading ? "Loading..." : "No option"}</MenuItem>
          )}
        </Select>
      </FormControl>
    </Stack>
         
          {/* <Stack direction={"row"} spacing={2} padding={2}> */}
    
          {/* <TextField label="Extra" value={form?.extra || ""} type="text" fullWidth onChange={(e)=>changeHandler("extra",e.target.value) } disabled={changePassword}/> */}

          {/* </Stack> */}

          {/* </Box> */}
        {/* </DataGridContainer> */}
        <div style={{ position: 'absolute', bottom: '20px', left: '50%', transform: 'translate(-50%, -50%)' }}>
        {status?<Button variant="contained" onClick={()=>onsubmit(form)} >

    Add Visit
 </Button>:<Button variant="contained" onClick={()=>handleEdit(form)} >

Update Visit
</Button>}
 </div>
      </div>
    );
  };

  console.log("FORMMM!",form)
  const fetchEmployees=async()=>{
    try{
      setLoading(true)
    const results = await window.Platform.database.getUsersForWork();
    setEmployee(results.data)
    setAssignedEmp(null)
    if(!status){
     const filteredEmp=results?.data?.filter((data)=>data.id===propData.ownerId)
     console.log("filteredEmp",filteredEmp);
     setAssignedEmp(filteredEmp[0])
    }
    setLoading(false)

  }
  catch (e) {
    setLoading(false)

  console.log(e)
  }
 
   }
   const fetchProduct=async(data,data1)=>{
    console.log("props", data,data1, status)
    try{
      setLoading(true)
      if(!data && status){
    const results = await window.Platform.database.fetchProduct(data);
    setProduct(results.data)
    setProduct1(results.data)

    // console.log("FILTEREDARRAY")
      }
      else if(data&&status){
          if(data===1){
    console.log("FILTEREDARRAY1")

            const results = await window.Platform.database.fetchProduct();
            setProduct(results.data)
          }else if(data===2){
    console.log("FILTEREDARRAY2")

            const results = await window.Platform.database.fetchProductByCategory({id:data1});
            setProduct1(results.data)
          }
          else{
            
            const results = await window.Platform.database.fetchProductByCategory({id:data1});
            setProduct(results.data)
          }
      }
    else if(!status && !data){
      // selectProduct1(null)
      // selectProduct1(null)
      const results = await window.Platform.database.fetchProduct();
      setProduct(results.data)
      setProduct1(results.data)
      const resultArray = propData?.productExplained?.split(',');
      const resultArray1 = propData?.productInterested?.split(',');
      
      const filteredData = results?.data?.filter(item => resultArray.includes(item.skuId));
      const filteredData1 = results?.data?.filter(item => resultArray1.includes(item.skuId));

      setSelectProduct(filteredData)
      setSelectProduct1(filteredData1)
      console.log("FILTEREDARRAY",filteredData)
      
    }
    setLoading(false)
   }
 catch(error){
  console.log(error)
  setLoading(false)

 }}
 

 const getStatus=async()=>{
  try{
    const results = await window.Platform.database.getStatusType(data);
setStatusWork(results.data)

  }
  catch(err){
    console.log(err)
  }
 }

  const validateForm = (data) => {
    const errors = {};

    const explainedProducts = selectProduct?.map(item => item.skuId);
    const interestedProducts = selectProduct1?.map(item => item.skuId);

    if (!data.ownerId?.trim()) {
      errors.visitMarkedFor = "Visit mark is required";
    }

    if (!data.visitEntryType?.trim()) {
      errors.visitType = "Visit type is required";
    }

    if (!data.partyType?.trim()) {
      errors.partyType = "Party type is required";
    }

    if (!data.partyId?.trim()) {
      errors.party = "Party is required";
    }

    if (!data.contactPerson?.trim()) {
      errors.personName = "Person name is required";
    }

    if (!data.phoneNumber?.trim()) {
      errors.contactPerson = "Person contact is required";
    }

    if (!data.status?.trim()) {
      errors.status = "Status is required";
    }

    if (!data.workDone?.trim()) {
      errors.workDone = "Work done is required";
    }

    if (!data.explainedCat?.trim()) {
      errors.explainedCategory = "Explained category is required";
    }

    if (!explainedProducts?.length) {
      errors.explainedProduct = "Explained product is required";
    }

    if (!data.intrestedCat?.trim()) {
      errors.interestedCategory = "Interested category is required";
    }

    if (!interestedProducts?.length) {
      errors.interestedProduct = "Interested product is required";
    }

    return errors;
  };
 
  const onsubmit = async (data) => {
    try {
      //       if(!partyTypeCheck || !visitTypeCheck){
      //         if(!form.ownerId||!form.visitEntryType||!form.partyType||!form.partyId
      //           ||!form.contactPerson||!form.phoneNumber||!form.status||
      //           !form?.workDone
      //           ){
      //       window.NotificationUtils.showWarning("Please fill all required fields")
      // return;
      //           }
      //       }

      const errors = validateForm(data);
      setErrors(errors);

      if (Object.keys(errors).length === 0) {
        setLoading(true);

        const arrayOfIds = selectProduct?.map((item) => item.skuId);
        const arrayOfIds1 = selectProduct1?.map((item) => item.skuId);

        const results = await window.Platform.database.addVisit({
          ...data, ownerId: form.ownerId,
          productExp: arrayOfIds,
          productInt: arrayOfIds1,
          followUpDate: Math.floor(new Date(data.followUpDate).getTime() / 1000)
        });

        if (results.status === false) {
          window.NotificationUtils.showSuccess("Visit added successfully!");
          setLoading(false);
          onClose();
          fetchData();
        } else {
          window.NotificationUtils.showError(results.message);
          setLoading(false);
        }
      } else {
        window.NotificationUtils.showError("Please fill the form correctly.");
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };



      const fetchPartyType=async(details)=>{
        try{
          setLoading(true)
          // console.log("PLANTCODE",plantcode[0].plant_code__c)
          let results = await window.Platform.database.fetchPartyType(details);
    //   console.log("pincode: " ,results.data[0].StateName)
      setPartyType(results.data)
        setLoading(false)
      
      // setDetails(results.data[0]);
      // setProduct(results.data)
      }
      catch(err){
        console.log(err);
      setLoading(false)
       
        }
      }
      
      const getSchool=async(details)=>{
        try{
          setLoading(true)
          setParty([])
          // console.log("PLANTCODE",plantcode[0].plant_code__c)
          const results = await window.Platform.database.getSchool(details);
    //   console.log("pincode: " ,results.data[0].StateName)
    setParty(results.data)
    setSelectParty(null)
    if(!status){
     const filteredEmp=results?.data?.filter((data)=>data.schoolId===propData.partyId)
     setSelectParty(filteredEmp[0])
     
    }
        setLoading(false)
      
      // setDetails(results.data[0]);
      // setProduct(results.data)
      }
      catch(err){
        console.log(err);
      setLoading(false)
       
        }
      }



      const handleEdit=async(data)=>{
        try{

          const errors = validateForm(data);
          setErrors(errors);

          if (Object.keys(errors).length === 0) {
            setLoading(true);
            const arrayOfIds = selectProduct?.map(item => item.skuId);
            const arrayOfIds1 = selectProduct1?.map(item => item.skuId);
            
            const finalData={...data,productExp:arrayOfIds,productInt:arrayOfIds1,id:propData.visitId, followUpDate: Math.floor(new Date(data.followUpDate).getTime() / 1000), startTime: Math.floor(new Date(data.startTime).getTime() / 1000), endTime: Math.floor(new Date(data.endTime).getTime() / 1000), startMeetingTime: Math.floor(new Date(data.startMeetingTime).getTime() / 1000), endMeetingTime: Math.floor(new Date(data.endMeetingTime).getTime() / 1000)};
            let results = await window.Platform.database.updateVisit(finalData);
            
            onClose();
            fetchData();
            setLoading(false);
          } else {
            window.NotificationUtils.showWarning("Please fill all required fields to proceed");
            setLoading(false);
          }
          

        }catch(err){
          console.log(err);
          setLoading(true)
        }
      }
  return (
    <Dialog disableEnforceFocus maxWidth="md" fullWidth={true} open={true} >
     {loading && <OpaqueLoading/>}
    <ModalHeaderContainer style={{marginBottom:2,paddingBottom:3}}>
       <Typography  variant={isMobile?"h6":"h5"}>
         {status?"Add Visit":"Edit Visit"}
         <Tooltip title="Order Id">
 
           {id}
         </Tooltip>
           
           </Typography>
       <IconButton onClick={onClose} >
         <CloseOutlined />
       </IconButton>
     </ModalHeaderContainer>
     <TableContainer>
 
     <TabsContainer>
            <StyledTabs
              variant="fullWidth"
              value={tabValue}
              onChange={ handleChangeTab}
              indicatorColor="primary"
              textColor="primary"
            >
              <Tab label="Visit Details " />
              <Tab label="Additional Details" />
           
            </StyledTabs>
            {tabValue==1? renderContent() :renderContent1()}
 
          </TabsContainer>
   
     </TableContainer>
 
   </Dialog>
  );
};
 
export default VisitModal;
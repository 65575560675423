import {
  Button,
  Card,
  CardContent,
  TextField,
  FormControlLabel,
  Switch,
  Checkbox,
  IconButton,
  Tooltip,
  InputLabel,
  FormControl,
  Select,
  MenuItem,
} from "@mui/material";
import React, { useState, useEffect, useRef, useContext } from "react";
import styled from "styled-components";
import ProfilePictureUploader from "../../components/common/profilePictureUploader/profilePictureUploader";
import { isEmail } from "validator";
import OpaqueLoading from "../../components/opaqueLoading/opaqueLoading";
import {
  DATE_FORMATS,
  ERROR_CODES,
  ROLES,
  TABS,
  USER_STATUS,
} from "../../constants";
import { Autocomplete } from "@mui/material";
import moment from "moment";
import { HelpOutline } from "@mui/icons-material";
import { AuthContext } from "../../components/contextAPI/ContextAPI";

const isMobile = window.innerWidth < 900;

const StyledSelect = styled(Select)({
  textAlign: 'left',
});


const stateNames = [
  "Andhra Pradesh",
  "Arunachal Pradesh",
  "Assam",
  "Bihar",
  "Chhattisgarh",
  "Goa",
  "Gujarat",
  "Haryana",
  "Himachal Pradesh",
  "Jammu and Kashmir",
  "Jharkhand",
  "Karnataka",
  "Kerala",
  "Madhya Pradesh",
  "Maharashtra",
  "Manipur",
  "Meghalaya",
  "Mizoram",
  "Nagaland",
  "Odisha",
  "Punjab",
  "Rajasthan",
  "Sikkim",
  "Tamil Nadu",
  "Telangana",
  "Tripura",
  "Uttarakhand",
  "Uttar Pradesh",
  "West Bengal",
  "Andaman and Nicobar Islands",
  "Chandigarh",
  "Dadra and Nagar Haveli",
  "Daman and Diu",
  "Delhi",
  "Lakshadweep",
  "Puducherry",
];

const DetailsCard = styled(Card)`
  width: 90%;
  position: relative;
`;

const StyledCardContent = styled(CardContent)`
  padding: 16px !important;
`;



const StyledCardActionArea = styled.div`
  display: flex;
  justify-content: space-between;
`;

const StyledButton = styled(Button)`
  margin-right: 10px !important;
`;

const ProfilePictureUploaderContainer = styled.div`
  display: grid;
  grid-template-columns: 90px 1fr 1fr;
  ${isMobile && `grid-template-columns: 1fr;
  row-gap: 10px;`}
  column-gap: 10px;
  align-items: center;
`;

const FieldsContainer = styled.div`
  margin-top: 10px;
  margin-bottom: 10px;
`;

const CardRow = styled.div`
  display: grid;
  ${(props) =>
    !props.singular
      ? `
    grid-template-columns: 1fr 1fr;
    column-gap: 10px;
    `
      : `grid0-template-columns: 1fr;`}
  margin-bottom: 10px;
`;

const ResendInviteButton = styled(Button)`
  margin-right: 10px !important;
`;

const DeleteEmployeeButton = styled(Button)``;

const RightActionButtonsContainer = styled.div`
display: flex;
justify-content: flex-end;
`;



const MemberDetails = (props) => {
  const [details, setDetails] = useState(props.data);
  const detailsRef = useRef();
  detailsRef.current = details;
  const [hasChange, setHasChange] = useState(false);
  const [loading, setLoading] = useState(false);
  const [addNewMode, setAddNewMode] = useState(Boolean(!props.data.id));
  const [roles,setRoles]=useState([])
  const { auth, setAuth } = useContext(AuthContext)
  const [warehouserole,setWarehouseRole]=useState(null);
  const [warehousedata,setWareHousedata]=useState([]);
  const [selectedWarehouse,setSelectedWarehouse]=useState()

  // setWarehouseRole(props?.data?.role)

// console.log("PROSP>DATA",details);
  useEffect(() => {
    // console.log("detailsss",details)
    if (props.data.id !== details.id) {
      setDetails(props.data);
      setAddNewMode(Boolean(!props.data.id));
      setHasChange(false);
    }
    setWarehouseRole(props.data.role)

  }, [props.data]);
  useEffect(()=>{
    if(!addNewMode){
      // console.log("propssss", props)
if (props.data.role==='SCM Executive - China-Warehouse' || props.data.role==='SCM Executive - India-Warehouse'){
setWarehouseRole(props.data.role);
props.selectedTab===1?setSelectedWarehouse(props?.data?.warehouse):setSelectedWarehouse(details?.warehouse?.id)
// setSelectedWarehouse({id:details?.warehouse?.attributes?.id,name:details?.warehouse?.attributes?.name})
}}


  },[props])
// console.log("SELECTEDWWH",selectedWarehouse)
  const detailUpdate = (key, value) => {
    if (!hasChange) {
      setHasChange(!hasChange);
    }
    var newDetails = { ...detailsRef.current };
    if (key === "email") {
      newDetails.email = window.trimEmail(value);
    } else {
      newDetails[key] = value;
    }
    if(key === "warehouse"){
      console.log("sss",newDetails.warehouse);
      if (typeof newDetails.warehouse === "object" && newDetails.warehouse !== null) {
        console.log("sss", newDetails.warehouse);
        // Assuming value is the new id you want to set
        newDetails.warehouse["id"] = value;
    } else {
        // If newDetails.warehouse is not an object, initialize it as an object
        newDetails.warehouse = { id: value };
    }
    }
    setDetails(newDetails);
  };

  const onClickUndo = () => {
    setDetails(props.data);
    setHasChange(false);
  };
  const isValidChinaMobileNumber = (mobileNumber) => {
    const chinaRegex = /^1[0-9]{10}$/;
    return chinaRegex.test(mobileNumber);
  };


  const isValidIndiaMobileNumber = (mobileNumber) => {
    const indiaRegex = /^(\+?91[-\s]?)?[789]\d{9}$/;
    return indiaRegex.test(mobileNumber);
  };


  // const chinaRoles = [
  //   ROLES.SCM_EXECUTIVE_PACKING_LIST_CHINA_AIR_AND_SEA,
  //   ROLES.SCM_EXECUTIVE_CHINA_WAREHOUSE_AIR,
  //   ROLES.SCM_EXECUTIVE_CHINA_WAREHOUSE_SEA,

  // ];


  const isValidMobileNumberForRole = (mobileNumber, role) => {
    // const isChinaRole = chinaRoles.includes(role);
    // if (isChinaRole) {
    //   return isValidChinaMobileNumber(mobileNumber);
    // } else {
      return isValidIndiaMobileNumber(mobileNumber);
    
  };
  const mob = "14375847232";


  const rolecheck = isValidMobileNumberForRole(mob, ROLES.SCM_EXECUTIVE_PACKING_LIST_CHINA_AIR_AND_SEA)

  const checkForErrors = () => {
    if (!details.role) {
      window.NotificationUtils.showError("Please select a role for the user");
      return true;
    }
    if (!details.firstName) {
      window.NotificationUtils.showError("Please enter a first name");
      return true;
    }
    // if (!details.employeeCode) {
    //   window.NotificationUtils.showError(
    //     "Please enter an employee code for the user"
    //   );
    //   return true;
    // }
    if (!details.email || !isEmail(details.email)) {
      window.NotificationUtils.showError(ERROR_CODES.INVALID_EMAIL);
      return true;
    }
    if (details && !details.mobile) {
      window.NotificationUtils.showError(ERROR_CODES.NO_MOBILE);
      return true;
    }

    if (!details.mobile || isValidMobileNumberForRole(details.mobile, details?.role) == false) {
      window.NotificationUtils.showError(ERROR_CODES.INVALID_MOBILE);
      return true;
    }
    if(details && !details.employeeCode){
      window.NotificationUtils.showError("Please fill Employee Code");
      return true;
    }
    // if (
    //   ![ROLES.ADMIN, ROLES.ZONAL_HEAD, ROLES.HME].includes(details.role) &&
    //   !details.reportingManager
    // ) {
    //   window.NotificationUtils.showError(
    //     "Please select a reporting manager for the user"
    //   );
    //   return true;
    // }
    // if (
    //   details &&
    //   details.pinCode &&
    //   window.isValidPincode(details.pinCode) == false
    // ) {
    //   window.NotificationUtils.showError(ERROR_CODES.INVALID_PIN_CODE);
    //   return true;
    // }
    return false;
  };

  const onClickResendInvite = async () => {
    try {
      setLoading(true);
      await window.Platform.database.resendInvite(details);
      window.NotificationUtils.showSuccess("Successfully re-sent invite");
      setLoading(false);
    } catch (error) {
      setLoading(false);
      window.NotificationUtils.showError("Something went wrong");
    }
  };

  const onClickSave = async () => {
    if (checkForErrors()) {
      return;
    }
    let results = {};
    let profileData = { ...details };
    //   let hasReportingManagerChange = Boolean(
    //     profileData.reportingManager !== props.data.reportingManager
    //   );
    if (addNewMode) {
      setLoading(true);
      try {
        // console.log("hello",profileData)
        results = await window.Platform.database.createNewMember(profileData);
        if (results.message === ERROR_CODES.EMAIL_IN_USE) {
          throw ERROR_CODES.EMAIL_IN_USE;
        }
        // if (results.message === ERROR_CODES.EMPLOYEE_CODE_IN_USE) {
        //   throw ERROR_CODES.EMPLOYEE_CODE_IN_USE;
        // }
        if (results.message === ERROR_CODES.MOBILE_NUMBER_IN_USE) {
          throw ERROR_CODES.MOBILE_NUMBER_IN_USE;
        }
        results = results.data;
        setLoading(false);
        setAddNewMode(false);
        setDetails(prev => ({ ...prev, ...results }))
        props.setSelectedMember(results)
        window.NotificationUtils.showSuccess("New employee added");
      } catch (error) {
        console.error(error);
        if (error === ERROR_CODES.EMAIL_IN_USE) {
          window.NotificationUtils.showError("Email is already in use");
          // } else if (error === ERROR_CODES.EMPLOYEE_CODE_IN_USE) {
          //   window.NotificationUtils.showError("Employee Code is already in use");
        } else if (error === ERROR_CODES.MOBILE_NUMBER_IN_USE) {
          window.NotificationUtils.showError("Mobile Number is already in use");
        } else {
          window.NotificationUtils.showError("Error adding new employee");
        }
        setLoading(false);
        return;
      }
      finally{
        props.refreshData();
        props.setSelectedMember(false);
      }
    } else {
      try {
        setLoading(true);
        // console.log("profileData",profileData)
        let temp={email:profileData.email, employeeCode:profileData.employeeCode,firstName:profileData.firstName,id:profileData.id,lastName:profileData.lastName,mobile:profileData.mobile,role:profileData.role,status:profileData.status,username:profileData.username, warehouse: {id: profileData?.warehouse?.id}}        
        
        await window.Platform.database.updateBasicDetails(temp);
        window.NotificationUtils.showSuccess("User profile updated");
        if(props.selectedTab===0){
        props.fetchEmployees();}
        else{
          props.refreshData();
        }
        // props.setSelectedMember(false);

      } catch (error) {
        if (error === ERROR_CODES.EMAIL_IN_USE) {
          window.NotificationUtils.showError("Email is already in use");
        } else if (error === ERROR_CODES.MOBILE_NUMBER_IN_USE) {
          window.NotificationUtils.showError("Mobile Number is already in use");
        } else {
          window.NotificationUtils.showError("Error updating profile");
        }
        setLoading(false);
        return;
      }
    }
    // if (hasReportingManagerChange) {
    //   if (props.handleTabChange) {
    // await props.handleTabChange(
    //   props.isInvite ? TABS.PENDING : TABS.REGISTERED,
    //   true
    // );
    //   await props.handleTabChange(
    //     !([ROLES.BD_FOS, ROLES.RETENTION_FOS, ROLES.FOS_SPECIAL].includes(details.role))? TABS.PENDING : TABS.REGISTERED,
    //      true
    //    );

    // }
    // } else {
    //   updateOfflineData(results);
    // }
    setLoading(false);
    setHasChange(false);
  };

  const updateOfflineData = (results) => {
    let members = [...props.visibleEmployees];
    if (addNewMode) {
      if (
        ![ROLES.BD_FOS, ROLES.RETENTION_FOS, ROLES.FOS_SPECIAL].includes(
          results.role
        )
      ) {
        members.unshift({
          ...results,
          name: `${results.firstName} ${results.lastName ? results.lastName : ""
            }`,
        });
        props.setVisibleEmployees(members);
        props.setSelectedMember(results);
      }
    } else {
      let emp = { ...details };
      if (results && results.id) {
        emp = { ...results };
      }
      let index = members.findIndex((member) => member.id === emp.id);
      if (index !== -1) {
        members[index] = { ...emp };
        if (!props.isInvite) {
          props.setStaffMembers(members);
        }
        props.setVisibleEmployees(members);
      }
    }
  };

  const getUndoButtonText = () => {
    if (addNewMode) {
      return "Cancel";
    }
    return "Undo";
  };

  const getSaveButtonText = () => {
    if (addNewMode) {
      return "Add";
    }
    return "Save";
  };

  const deleteEmployee = async () => {
    try {
      setLoading(true);
      await props.onClickDeleteEmployee();
      setLoading(false);
    } catch (error) {
      window.NotificationUtils.showError("Error deleting employee");
      setLoading(false);
    }
  };

  const confirmDeleteEmployee = () => {
    if (
      // [ROLES.BD_FOS, ROLES.RETENTION_FOS, ROLES.FOS_SPECIAL].includes(
      //   props.data.role
      // ) 

      props.isInvite
    ) {
      deleteEmployee();
      // setTimeout(() => {
      //   props.setShowDeletionReasonPicker(true);
      // }, 500);
    } else {
      // console.log("hello")
      //  props.setShowReplacementPicker(true)

      // setTimeout(() => {
      // window.confirmationModal(
      //   `Would you like to replace the employee being deleted with another reporting manager for his/her peers?`,
      props.setShowDeletionReasonPicker(true)
      //   // deleteEmployee,
      // props.setShowReplacementPicker(true)
      //       {
      //         confirmButtonText: "Deactivate Only",
      //         cancelButtonText: "Deactivate & Replace",
      //       }
      //     );
      // }, 500);
    }
  };

  
  const fetchRoles = () => {
    const roles = props.getUserCreationRoles();
    const filteredRoles = roles.filter(role => role !== "Super Admin");

    const role=auth?.user?.role;
     if(role !== ROLES.ADMIN && role !== ROLES.SUPER_ADMIN ){
      const finalroles=filteredRoles.filter(role => role !== "Admin");

      setRoles(finalroles);
     }
     else{

    setRoles(filteredRoles);
  }
}

  useEffect(()=>{
    fetchRoles()
    // console.log("Fetching roles...",warehouserole);
  },[])


 const fetchWarehouses=async()=>{
  try{
 const results = await window.Platform.database.fetchWarehouses();
//  console.log("RESULTSSS",results)
//  console.log("Fetching data...",api)
if (warehouserole === null) {
  // console.log("FILTERED")
  return;
}

if (warehouserole === 'SCM Executive - India-Warehouse') {
  const filteredData = results.filter((res) => res.warehouseCountry === 'India');
  // console.log("FILTERED", filteredData)
  setWareHousedata(filteredData);
} else if (warehouserole === 'SCM Executive - China-Warehouse') {
  const filteredData = results.filter((res) => res.warehouseCountry === 'China');
  // console.log("FILTERED", filteredData)

  setWareHousedata(filteredData);
}
;
 }
catch(error){
  console.log(error);
}}
 
// console.log("WAREHOUSEDATA: ", selectedWarehouse)

  useEffect(()=>{
   
   fetchWarehouses()
  //  if(!addNewMode){
  //  let data= warehousedata.filter((value)=>value.id===details?.warehouse?.id)
  //  setSelectedWarehouse(data)
  //  }
  //  console.log("WAREHOUSEDATA: ",details?.warehouse?.id)

    // console.log("Fetching roles...",warehouserole);
    // console.log("Fetching roles...",warehousedata);
  },[warehouserole])
// console.log("Fetching roles...",addNewMode)
 

  const onClickDeleteEmployee = () => {
    window.confirmationModal(
      `Are you sure you want to deactivate ${details.firstName}${details.lastName ? ` ${details.lastName}` : ""
      } (${details.employeeCode})?`,
      confirmDeleteEmployee
    );
  };
  const beforeUploadContent = () => {
    return <div style={{ position: 'absolute', top: 40, left: 21 }}>2MB File</div>;
  };

  const chinaWarehouse = [
    { label: "Shanghai", value: 1 },
    { label: "Hong Kong", value: 2 },
  ];
  
  const indiaWarehouse = [
    { label: "Delhi", value: 1 },
    { label: "Mumbai", value: 2 },
  ];

  return (
    <DetailsCard raised>
      {loading && <OpaqueLoading />}
      <StyledCardContent>
        <ProfilePictureUploaderContainer>
          <Tooltip title="Upload file less than 800kb">
            <div>
              <ProfilePictureUploader
                url={details.profilePicURL ? details.profilePicURL : ""}
                onUploadSuccess={detailUpdate}
                height={80}
                uploadTextStyle={{ top: 40, left: 21 }}
                disabled={loading || props.isManager || props.isDeleted}

              /></div>
          </Tooltip>

          {props.selectedTab == TABS.DELETED && (
            <TextField
              value={details.previousEmail || ""}
              onChange={(e) => detailUpdate("previousEmail", e.target.value)}
              variant="outlined"
              label="Email"
              fullWidth
              required
              disabled={loading || props.isManager || props.isDeleted}
            />
          )}
          {props.selectedTab != TABS.DELETED && (
            <TextField
              value={details.email || ""}
              onChange={(e) => detailUpdate("email", e.target.value)}
              variant="outlined"
              label="Email"
              fullWidth
              required
              disabled={loading || props.isManager}
            />
          )}
          <Autocomplete
            options={roles}
            style={{ width: "100%" }}
            value={details.role || ""}
            disabled={loading 
              // || !addNewMode || props.isManager
            }
            renderInput={(params) => (
              <TextField
                {...params}
                disabled={
                  loading 
                  // || !addNewMode || props.isManager || props.isDeleted
                }
                label={"Role"}
                required
                variant="outlined"
              />
            )}
            onChange={(event, value) =>{ 
              if(value==='SCM Executive - China-Warehouse' || value==='SCM Executive - India-Warehouse' ){
                setWarehouseRole(value)
                console.log("hello1")
              }
              else{
                setWarehouseRole(null)
                console.log("hello2")
              }
              detailUpdate("role", value || "")}}
          />
        
        </ProfilePictureUploaderContainer>
        <FieldsContainer>
          {/* <CardRow> */}
          <CardRow>

            <TextField
              value={details.name || ""}
              onChange={(e) => detailUpdate("name", e.target.value)}
              variant="outlined"
              label="First Name"
              fullWidth
              required
              disabled={loading || props.isManager || props.isDeleted}
            />
            {/* </CardRow> */}
            {/* <CardRow> */}

            {/* <TextField
              value={details.lastName || ""}
              onChange={(e) => detailUpdate("lastName", e.target.value)}
              variant="outlined"
              label="Last Name"
              fullWidth
              disabled={loading || props.isManager || props.isDeleted}
              required
            /> */}
          </CardRow>
          <CardRow>

            <TextField
              required
              value={details.mobNo || ""}
              onChange={(e) => {
                const input = e.target.value;
                // Custom validation to limit the input to a maximum of 10 digits
                if (input.length <= 15 && /^\d*$/.test(input)) {
                  detailUpdate("mobile", input);
                }
              }}
              variant="outlined"
              label="Mobile"
              fullWidth
              disabled={loading || props.isManager || props.isDeleted}
              type="text" // Use type="text" for alphanumeric input
            />

            <TextField
              required
              value={details.employeeCode || ""}
              onChange={(e) => detailUpdate("employeeCode", e.target.value)}
              variant="outlined"
              label="Employee Code"
              fullWidth
              disabled={
                loading || !addNewMode || props.isManager || props.isDeleted
              }
            />
          </CardRow>
          <CardRow>
            {addNewMode&&((warehouserole==='SCM Executive - India-Warehouse' || warehouserole==='SCM Executive - China-Warehouse') || props.data.role==='SCM Executive - China-Warehouse' || props.data.role==='SCM Executive - India-Warehouse' )&&
//         <Autocomplete
//         options={warehousedata}
//         style={{ width: "100%" }}
//         value={!addNewMode && details?.warehouse?.attributes|| ""}
//         // autoSelect={!addNewMode&&warehousedata?.filter((value)=>value?.id===details?.warehouse?.id)}
//         // value={!addNewMode&&details?.warehouse?.id}
//         getOptionLabel={(option) => option.name}
//         getOptionValue={(option) => option.id}
//         disabled={loading || props.isManager||!addNewMode}
//         renderInput={(params) => (
//           <TextField
//           {...params}
//           disabled={
//             loading 
//             // || props.isManager || props.isDeleted ||!addNewMode
//           }
//           // value={details.warehouse.id || ""}
//             label={"Assign warehouse"}
//             variant="outlined"
//           />
//         )}
//         onChange={(event, value) =>
// // {console.log("valueee",warehousedata)
// //    console.log("valueee1",details.warehouse.id)
//   //  setSelectedWarehouse({id:value?.id,name:value?.name})
//           detailUpdate("warehouse", value.id)}
//           // detailUpdate("warehouseid", value?value)
//         // }
//       />
<FormControl>
        <InputLabel>Assign Warehouse</InputLabel>
        <StyledSelect
        label="Assign Warehouse"
          value={props.selectedTab===1?props?.data?.warehouse?.id:details?.warehouse||""}
          
        disabled={loading
          // ||!addNewMode
        }
          onChange={(event, value) =>{
            
            console.log("valueee1",props.data.warehouse)
              //  console.log("valueee1",value.props.value)
              //  setSelectedWarehouse({id:value?.id,name:value?.name})
                      detailUpdate("warehouse", value.props.value)}}
        >
        {warehousedata.map((data)=>
        <MenuItem value={data.id}>{data.name}</MenuItem>)}
          {/* Add more time options as needed */}
        </StyledSelect>
      </FormControl>
      }
        {!addNewMode&& ((warehouserole==='SCM Executive - India-Warehouse' || warehouserole==='SCM Executive - China-Warehouse') )&&
            // {addNewMode&&((warehouserole==='SCM Executive - India-Warehouse' || warehouserole==='SCM Executive - China-Warehouse') || props.data.role==='SCM Executive - China-Warehouse' || props.data.role==='SCM Executive - India-Warehouse' )&&

//         <Autocomplete
//         options={warehousedata}
//         style={{ width: "100%" }}
//         value={!addNewMode && details?.warehouse?.attributes|| ""}
//         // autoSelect={!addNewMode&&warehousedata?.filter((value)=>value?.id===details?.warehouse?.id)}
//         // value={!addNewMode&&details?.warehouse?.id}
//         getOptionLabel={(option) => option.name}
//         getOptionValue={(option) => option.id}
//         disabled={loading || props.isManager||!addNewMode}
//         renderInput={(params) => (
//           <TextField
//           {...params}
//           disabled={
//             loading || props.isManager || props.isDeleted ||!addNewMode
//           }
//           // value={details.warehouse.id || ""}
//             label={"Assign warehouse"}
//             variant="outlined"
//           />
//         )}
//         onChange={(event, value) =>
// {console.log("valueee",warehousedata)
//    console.log("valueee1",details.warehouse.id)
//   //  setSelectedWarehouse({id:value?.id,name:value?.name})
//           detailUpdate("assignWarehouse", value.id)}
//           // detailUpdate("warehouseid", value?value)
//         }
//       />}
<FormControl>
        <InputLabel>Assign Warehouse</InputLabel>
        <StyledSelect
        label="Assign Warehouse"
          value={details?.warehouse?.id||""}
          
        disabled={loading
          // ||!addNewMode
        }
          onChange={(event, value) =>{
            
            console.log("valueee2",details)
              //  console.log("valueee1",value.props.value)
              //  setSelectedWarehouse({id:value?.id,name:value?.name})
                      detailUpdate("warehouse", value.props.value)}}
        >
        {warehousedata.map((data)=>
        <MenuItem value={data.id}>{data.name}</MenuItem>)}
          {/* Add more time options as needed */}
        </StyledSelect>
      </FormControl>}
          </CardRow>
         
          {props.selectedTab == TABS.DELETED && (
            <CardRow>
              <TextField
                value={details.deletionReason || ""}
                onChange={(e) =>
                  detailUpdate("deletionReason", e.target.value)
                }
                variant="outlined"
                label="De-Activation Reason"
                fullWidth
                required
                disabled={loading || props.isManager || props.isDeleted}
              />
            
            </CardRow>
          )}
          

        </FieldsContainer>
        <StyledCardActionArea>
          
          <RightActionButtonsContainer>
            {!hasChange && props.isInvite && !addNewMode && (
              <ResendInviteButton
                disabled={loading}
                variant="contained"
                onClick={onClickResendInvite}
                color="primary"
              >
                Resend Invite
              </ResendInviteButton>
            )}
            {!hasChange && !addNewMode && props.selectedTab != TABS.DELETED && (
              <DeleteEmployeeButton
                disabled={loading || props.isManager}
                variant="contained"
                onClick={onClickDeleteEmployee}
                color="primary"
              >
                Deactivate / Block
              </DeleteEmployeeButton>
            )}
            {hasChange && (
              <StyledButton
                disabled={loading || props.isManager}
                onClick={onClickUndo}
                color="primary"
              >
                {getUndoButtonText()}
              </StyledButton>
            )}
            {hasChange && (
              <Button
                disabled={loading || props.isManager}
                onClick={onClickSave}
                color="primary"
              >
                {getSaveButtonText()}
              </Button>
            )}
          </RightActionButtonsContainer>
        </StyledCardActionArea>
      </StyledCardContent>
    </DetailsCard>
  );
};

export default MemberDetails;

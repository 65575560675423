import react, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import {
  DataGrid,
  GridColDef,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton,
  GridValueGetterParams,
  getGridStringOperators,
} from "@mui/x-data-grid";
import styled from "styled-components";
import Drawer from "../../components/common/drawer/drawer";
import { Typography, Button, Checkbox, Fab, IconButton } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import { ERROR_COLOR } from "../../constants";
import { Add, Save, SaveAlt } from "@mui/icons-material";
import WarehouseModal from "../../components/modals/orderLineItemModal";
import OpaqueLoading from "../../components/opaqueLoading/opaqueLoading";
import VisitModal from "../../components/modals/visitModal";
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';

const isMobile = window.innerWidth < 900;

const StaffEditorPageContainer = styled.div`
  height: ${isMobile ? `auto` : `calc(100vh - 105px)`};
  width: 100%;
  background-color: white;
  padding: 10px 15px;
  ${isMobile &&
    `
      position: relative;
  
      `}
`;

const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: ${isMobile ? "flex-start" : "space-between"};
  margin-bottom: 10px;
  flex-direction: ${isMobile ? "column" : "row"};
`;

const HeaderButtonsContainer = styled.div`
  ${isMobile && "margin-top:10px;"}
`;

const TableContainer = styled.div`
  // height: calc(
  //   100vh - ${isMobile ? "56px - 20px - 82.23px" : "112px - 34px - 43.77px"}
  // );
  width: 100%;
  // border: solid 1px lightGrey;
  border-radius: 8px;
`;
const AvatarContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const DataGridContainer = styled.div`
  max-width: 100%; /* Add this line to limit the width */
  margin: 0 auto; /* Center the content horizontally */
  height: 100%;
  width: 100%;
  border: solid 1px lightGrey;
  border-radius: 8px;
  display: ${isMobile ? 'flex' : 'grid'};

  ${isMobile &&
  `
    flex-direction: column;
  `}
`;

const LoadingContainer = styled.div`
  height: calc(100% - 49px);
  display: flex;
  justify-content: center;
  align-items: center;
`;

const TabsContainer = styled.div`
  ${isMobile &&
  `
    height: calc(100vh - 56px - 20px);
  `}
`;

const GenerateReportButton = styled(Button)`
  margin-right: 10px !important;
`;
const BulkChangePinCodeButton = styled(Button)`
  margin-right: 10px !important;
`;

const PinCodeChangeButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const FabStyle = styled(Fab)`
  && {
    z-index: 1};
  }
`;

const StyledHeader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export default function Visitmanagement() {
  const label = { inputProps: { "aria-label": "Checkbox demo" } };

  const [loading, setLoading] = useState(false);
  const [accessChanges, setAccessChanges] = useState("");
  const [showWarehouseFormModal, setShowWarehouseFormModal] = useState();
const [visitModal,setVisitModal]=useState(false);
const [editModal,setEditModal]=useState(false);
const [dataModal,setDataModal]=useState();
const [rowCount,setRowCount] = useState()
const [currentPage, setCurrentPage] = useState(1);
const [paginationModel, setPaginationModel] = useState({
  page: currentPage - 1,
  pageSize: 11,
});
const [filter,setFilter]=useState(false)
const [searchTerm, setSearchTerm]=useState(null)
const [startDate,setStartDate]=useState()
const [endDate,setEndDate]=useState()
const [dateRange1,setDateRange1]=useState(false)

const [startDate1,setStartDate1]=useState()
const [endDate1,setEndDate1]=useState()
const [minDate,setMinDate]=useState()

  const formatDateTime=(inputDateString)=> {
    const inputDate = new Date(inputDateString);

  // Check if the input date is valid
  if (isNaN(inputDate.getTime())) {
    return 'Invalid Date';
  }

  // Extract date and time components
  const year = inputDate.getUTCFullYear();
  const month = String(inputDate.getUTCMonth() + 1).padStart(2, '0');
  const day = String(inputDate.getUTCDate()).padStart(2, '0');
  const hours = String(inputDate.getUTCHours()).padStart(2, '0');
  const minutes = String(inputDate.getUTCMinutes()).padStart(2, '0');
  const seconds = String(inputDate.getUTCSeconds()).padStart(2, '0');

  // Format the date string without 'T' and 'Z'
  const formattedDateString = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;

  return formattedDateString;

  }
  

  const formatTimeStamp = (timestampFromDB) => {
    const options = {
      timeZone: 'Asia/Kolkata',
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,  // Use 12-hour clock
    };
  
    const formattedDate = new Date(timestampFromDB).toLocaleString('en-US', options);
  
    return formattedDate;
  };

  useEffect(() => {
    // setAccessChanges(rows);
    // fetchTest()
    if(!filter){
    fetchVisits()
    }
  }, [paginationModel.page]);
  useEffect(() => {
    if(filter){
      onFilterChange(searchTerm)
      }
  }, [paginationModel.page]);

  const fetchTest = async() =>{
      let results1 = await window.Platform.database.testApi();

  }
  const fetchVisits= async () => {
    try {
      setLoading(true);
      // let results1 = await window.Platform.database.testApi();
      let results = await window.Platform.database.fetchVisits({pageNumber:paginationModel.page});
      console.log("results", results);
      // if(auth?.user?.role === ROLES.ADMIN){
        setRowCount(results.data1)
      const jsonArrayWithId = results?.data?.map((obj, index) => ({ ...obj, id: index + 1 }));

        setAccessChanges(jsonArrayWithId);
      // }
      // setVisibleEmployees(results);
      // setLoading(false);
    } catch (error) {
      console.error(error);
      window.NotificationUtils.showError("Error Fetching Data");
      // setLoading(false);
    }
    finally{
      setLoading(false)
    }
  }

  const showImages = (pictures) => {
    const picturesUrl = pictures.split(',');
    picturesUrl.forEach((url, index) => {
      if (!/^https?:\/\//i.test(url)) {
        url = 'https://' + url;
      }
      setTimeout(() => {
        window.open(url, '_blank');
      }, index * 500);
    });
  }

  const rejectVisit = async (visitId) => {
    try {
      setLoading(true);
      await window.Platform.database.rejectVisit({ id : visitId });
      await fetchVisits();
      setLoading(false);
    } catch (error) {
      console.error(error);
      window.NotificationUtils.showError("Error Rejecting School");
      setLoading(false);
    }
  }

  const getEmployeeColumns = () => {
    const stringOperators = getGridStringOperators().filter((op => ['contains'].includes(op.value)));

  let result = [
    {
      field: "partyName",
      headerName: "Party Name",
      sortable: false,
      width: 200,
      
            filterOperators: stringOperators ,
            editable: false,
            valueGetter: (params) => {
              if (params.row?.schoolName) {
                return (params.row.schoolName || "N/A");
              } else {
                return (params.row.DistributorName || "N/A")
              }
            },
      renderCell: (params) => {

        let name = params.row?.schoolName?params.row.schoolName:params.row.DistributorName

        return (

          <Tooltip title={name || "undefined"}>

            <Typography variant="inherit">{name}</Typography>

          </Tooltip>

        )

      },
    },
    {
      field: "startTime",
      headerName: "Start Time",
      width: 250,
      // height: 10,
      // headerAlign: 'center',
      sortable: false,
      filterable: false,
      valueGetter: (params) => {
        if (params.row.startTime) {
          return (formatTimeStamp(params.row.startTime));
        } else {
          return "N/A"
        }
      },
      // editable: true,
      renderCell: (params) => {

        let name =formatTimeStamp( params.row?.startTime)

        return (

          <Tooltip title={name || "undefined"}>

            <Typography variant="inherit">{name}</Typography>

          </Tooltip>

        )

      },
    },
    {
        field: "endTime",
        headerName: "End Time",
        width: 250,
        // height: 10,
        // headerAlign: 'center',
        sortable: false,
        filterable: false,

        // editable: true,
        valueGetter: (params) => {
          if (params.row.endTime) {
            return (formatTimeStamp(params.row.endTime));
          } else {
            return "N/A"
          }
        },
        renderCell: (params) => {
  
          let name =params.row?.endTime?formatTimeStamp( params.row?.endTime):"N/A"
  
          return (
  
            <Tooltip title={name || "undefined"}>
  
              <Typography variant="inherit">{name}</Typography>
  
            </Tooltip>
  
          )
  
        },
      },
      {
        field: "name",
        headerName: "Visit Done By",
        width: 200,
        // height: 10,
        // headerAlign: 'center',
        sortable: false,
        filterOperators: stringOperators ,

        // editable: true,
        valueGetter: (params) => {
          return (params.row.u_name || "N/A");
        },
        renderCell: (params) => {
  
          let name = params.row?.u_name
  
          return (
  
            <Tooltip title={name || "N/A"}>
  
              <Typography variant="inherit">{name}</Typography>
  
            </Tooltip>
  
          )
  
        },
      },
      {
        field: "VisitType",
        headerName: "Visit Type",
        width: 200,
        // height: 10,
        // headerAlign: 'center',
        sortable: false,
        // filterOperators: stringOperators ,
        filterable: false,

        valueGetter: (params) => {
          return (params.row.typeName || "N/A");
        },
        // editable: true,
        renderCell: (params) => {
  
          let name = params.row?.typeName
  
          return (
  
            <Tooltip title={name || "N/A"}>
  
              <Typography variant="inherit">{name}</Typography>
  
            </Tooltip>
  
          )
  
        },
      },
      // {
      //   field: "visitEntryType",
      //   headerName: "Visit Type",
      //   width: 200,
      //   // height: 10,
      //   // headerAlign: 'center',
      //   sortable: false,
      //   filterable: false,

      //   // editable: true,
      //   renderCell: (params) => {
  
      //     let name = params.row?.visitEntryType
  
      //     return (
  
      //       <Tooltip title={name || "undefined"}>
  
      //         <Typography variant="inherit">{name}</Typography>
  
      //       </Tooltip>
  
      //     )
  
      //   },
      // },
      {
        field: "ContactPerson",
        headerName: "Contact Person",
        width: 200,
        filterable: false,

        // height: 10,
        // headerAlign: 'center',
        sortable: false,
        // editable: true,
        valueGetter: (params) => {
          return (params.row.ContactPerson || "N/A");
        },
        renderCell: (params) => {
  
          let name = params.row?.ContactPerson
  
          return (
  
            <Tooltip title={name || "N/A"}>
  
              <Typography variant="inherit">{name}</Typography>
  
            </Tooltip>
  
          )
  
        },
      },
      {
        field: "PhoneNumber",
        headerName: "Phone Number",
        width: 200,
        filterable: false,

        // height: 10,
        // headerAlign: 'center',
        sortable: false,
        // editable: true,
        valueGetter: (params) => {
          return (params.row.PhoneNumber || "N/A");
        },
        renderCell: (params) => {
  
          let name = params.row?.PhoneNumber
  
          return (
  
            <Tooltip title={name || "N/A"}>
  
              <Typography variant="inherit">{name}</Typography>
  
            </Tooltip>
  
          )
  
        },
      },
    
     
      {
        field: "Status",
        headerName: "Visit Status",
        width: 200,
        filterable: false,

        // height: 10,
        // headerAlign: 'center',
        sortable: false,
        // editable: true,
        valueGetter: (params) => {
          return (params.row.statusTypeName || "N/A");
        },
        renderCell: (params) => {
  
          let name = params.row?.statusTypeName
  
          return (
  
            <Tooltip title={name || "N/A"}>
  
              <Typography variant="inherit">{name}</Typography>
  
            </Tooltip>
  
          )
  
        },
      },
      {
        field: "workDone",
        headerName: "Work Done",
        width: 200,
        filterable: false,

        // height: 10,
        // headerAlign: 'center',
        sortable: false,
        // editable: true,
        valueGetter: (params) => {
          return (params.row.workDoneName || "N/A");
        },
        renderCell: (params) => {
  
          let name = params.row?.workDoneName
  
          return (
  
            <Tooltip title={name || "N/A"}>
  
              <Typography variant="inherit">{name}</Typography>
  
            </Tooltip>
  
          )
  
        },
      },
      {
        field: "numberOfBooks",
        headerName: "No of Books",
        width: 150,
        filterable: false,

        // height: 10,
        // headerAlign: 'center',
        sortable: false,
        // editable: true,
        valueGetter: (params) => {
          return (params.row.numberOfBooks || "N/A");
        },
        renderCell: (params) => {
  
          let name = params.row?.numberOfBooks
  
          return (
  
            <Tooltip title={name || "N/A"}>
  
              <Typography variant="inherit">{name}</Typography>
  
            </Tooltip>
  
          )
  
        },
      },
      {
        field: "tentativeAmount",
        headerName: "Tentative Amount",
        width: 180,
        filterable: false,

        // height: 10,
        // headerAlign: 'center',
        sortable: false,
        // editable: true,
        valueGetter: (params) => {
          return (params.row.tentativeAmount || "N/A");
        },
        renderCell: (params) => {
  
          const name1 = +params.row?.tentativeAmount
          let name=name1.toLocaleString('en-IN');

          return (
  
            <Tooltip title={name || "N/A"}>
  
              <Typography variant="inherit">{name}</Typography>
  
            </Tooltip>
  
          )
  
        },
      },
      // {
      //   field: "productExplained",
      //   headerName: "Product Explained",
      //   width: 200,
      //   // height: 10,
      //   // headerAlign: 'center',
      //   sortable: false,
      //   // editable: true,
      //   renderCell: (params) => {
  
      //     let name = params.row?.productExplained
  
      //     return (
  
      //       <Tooltip title={name || "undefined"}>
  
      //         <Typography variant="inherit">{name}</Typography>
  
      //       </Tooltip>
  
      //     )
  
      //   },
      // },
      // {
      //   field: "productInterested",
      //   headerName: "Product Interested",
      //   width: 200,
      //   // height: 10,
      //   // headerAlign: 'center',
      //   sortable: false,
      //   // editable: true,
      //   renderCell: (params) => {
  
      //     let name = params.row?.productInterested
  
      //     return (
  
      //       <Tooltip title={name || "undefined"}>
  
      //         <Typography variant="inherit">{name}</Typography>
  
      //       </Tooltip>
  
      //     )
  
      //   },
      // },
      {
        field: "followUpDate",
        headerName: "Follow Up Date",
        width: 250,
        filterable: false,

        // height: 10,
        // headerAlign: 'center',
        sortable: false,
        // editable: true,
        valueGetter: (params) => {
          if (params.row.followUpDate) {
            return (formatTimeStamp(params.row.followUpDate));
          } else {
            return "N/A"
          }
        },
        renderCell: (params) => {
  
          let name =params.row?.followUpDate?formatTimeStamp(params.row?.followUpDate):"N/A"
  
          return (
  
            <Tooltip title={name || "undefined"}>
  
              <Typography variant="inherit">{name}</Typography>
  
            </Tooltip>
  
          )
  
        },
      },
      {
        field: "schoolTimeCheck",
        headerName: "School Time Check",
        width: 250,
        filterable: false,

        // height: 10,
        // headerAlign: 'center',
        sortable: false,
        // editable: true,
        valueGetter: (params) => {
          if (params.row.school_time_check) {
            return params.row.school_time_check;
          } else {
            return "N/A"
          }
        },
        renderCell: (params) => {
          // console.log("VISIT PARAMS",params)
  
          let name =params.row?.school_time_check
  console.log("NAME CONSOLED ",name)
          return (
  
            <Tooltip title={name===0?"Out Of School Time":"In School Time" || "undefined"}>
  
              <Typography variant="inherit">{name===0?"Out Of School Time":"In School Time"}</Typography>
  
            </Tooltip>
  
          )
  
        },
      },
      {
        field: "visitTimeCheck",
        headerName: "Time Taken From Last Visit(In Minutes)",
        width: 350,
        filterable: false,

        // height: 10,
        // headerAlign: 'center',
        sortable: false,
        // editable: true,
        valueGetter: (params) => {
          if (params.row.time_taken_last_visit) {
            return params.row.time_taken_last_visit;
          } else {
            return "N/A"
          }
        },
        renderCell: (params) => {
          // console.log("VISIT PARAMS",params)
  
          let name =params.row?.time_taken_last_visit
  console.log("NAME CONSOLED ",name)
          return (
  
            <Tooltip title={name}>
  
              <Typography variant="inherit">{name}</Typography>
  
            </Tooltip>
  
          )
  
        },
      },
      {

        field: "Actions",

        headerName: "Actions",

        editable: false,
        filterable: false,


        hide: true,

        minWidth: 150,
        headerAlign: 'center',
        disableExport: true,
       

        renderCell: (params) => {


            return (

                <>
                  <Tooltip title={"Edit Visit"}>
                      <IconButton  onClick={()=>handleEdit(params.row)}>
                      <DriveFileRenameOutlineIcon/>
                      </IconButton>
                  </Tooltip>
                  <Tooltip title="Click to reject school">
                    <IconButton disabled={!params.row?.status} variant="extended" sx={{ color: "red" }} onClick={(e) => {
                      e.stopPropagation();
                      rejectVisit(params.row.visitId);
                    }} >
                    <ThumbDownIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Click to see pictures">
                    <IconButton variant="extended" disabled={!params.row.picture} color="primary" onClick={(e) => {
                      e.stopPropagation();
                        showImages(params.row.picture);
                      }} >
                      <RemoveRedEyeIcon/>
                    </IconButton>
                  </Tooltip>
                </>

            )

        },

    }
 
  ];


  return result
}

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
        <GridToolbarExport
          csvOptions={{
            fileName: "visits-exports",
          }}
        />
      </GridToolbarContainer>
    );
  }

  const onFilterChange= async(filterModel) => {

    
    try{  
      setLoading(true);
      setFilter(true)
      console.log(filterModel,'filterModel')
      if(filterModel){
      const response = await window.Platform.database.filterVisit({filterField:filterModel?.field,filterValue:filterModel?.value,pageNumber:paginationModel.page,startDate:startDate,endDate:endDate})
      console.log("respponse",response);
      const jsonArrayWithId = response?.data?.map((obj, index) => ({ ...obj, id: index + 1 }));
      setAccessChanges(jsonArrayWithId)
      setRowCount(response.data1 )
      }
      else{
        const response = await window.Platform.database.filterVisit({filterField:"date",pageNumber:paginationModel.page,startDate:startDate,endDate:endDate})
        console.log("respponse",response);
        const jsonArrayWithId = response?.data?.map((obj, index) => ({ ...obj, id: index + 1 }));
        setAccessChanges(jsonArrayWithId)
        setRowCount(response.data1 )
       
      }
      
      setLoading(false);
    }
    catch(err){
  console.error(err)
  setLoading(false);
  window.NotificationUtils.showError("Error While Recieving Data Please Wait and try again");  
    }
    
  };
  const renderContent = () => {
    return (
      <Box sx={{ height: "100%", width: "100%" }}>
        <DataGridContainer style={{ height: !accessChanges?.length ? '200px' : 'auto' }}>
        <DataGrid
          className="payrollGrid"
        //   checkboxSelection={true}
        density="compact"
          rows={accessChanges||[]}
          columns={getEmployeeColumns()}
          disableSelectionOnClick
          disableRowSelectionOnClick
          rowHeight={60}
          disableDensitySelector
          onFilterModelChange={(val)=>
              {
                console.log("vvvv",val);
            
            
            
            if(val?.items[0]?.value?.length>0){
              onFilterChange(val.items[0])
              setSearchTerm(val.items[0])
              console.log("CHECKING...")
            }
            else if(!val.items[0]?.value   && startDate && endDate){
              setSearchTerm(null)
              onFilterChange()
              console.log("CHECKING...1")

            }
            else{
              fetchVisits()
              setFilter(false)
              setSearchTerm(null)
            }
          //   else if(!val.items?.value && !endDate && !startDate && !territoryFilter){
          //     setSearchTerm(null)
          //     fetchData()
          //     console.log("CHECK1")
          //   }
          //   else if(!val.items?.value && endDate && startDate && territoryFilter){
          //     setSearchTerm(null)
  
          //     onFilterChange1()
          //     console.log("checkterr")
          //   }
          //   else if(!val.items?.value && endDate && startDate && !territoryFilter){
          //     setSearchTerm(null)
          //     console.log("check2")
          //     onFilterChange1()
  
  
          //   }
          //   else{
          //     setSearchTerm(null)
          //     console.log("check2")
          //     onFilterChange1()
          //   }
          }
            
          }
          filterMode="server"

rowCount={rowCount}
          
          paginationModel={paginationModel}
          paginationMode="server"
          onPaginationModelChange={setPaginationModel}
          pageSizeOptions={[10]}
          
          
          components={{
            Toolbar: CustomToolbar,
          }}
        />
        </DataGridContainer>
      </Box>
    );
  };



const addVisit=()=>{
  setVisitModal(true)
}
const handleEdit=(data)=>{
setEditModal(true)
setDataModal(data)

}
const formatDate=async(data)=>{
  setMinDate(data)
  setDateRange1(true)
  setEndDate1(null)
  setEndDate(null)
  const datePickerResponse = new Date(data.$d);

const year = datePickerResponse.getFullYear();
const month = String(datePickerResponse.getMonth() + 1).padStart(2, '0');
const day = String(datePickerResponse.getDate()).padStart(2, '0');
const formattedDate = `${year}-${month}-${day}`;

setStartDate1(formattedDate)
setStartDate(formattedDate)
console.log("CHECK!!")
console.log("CHECKENDDATE",endDate)

}
const finalDateRangeFilter=async(data)=>{
  try{
    setLoading(true)
    setFilter(true)

  const datePickerResponse = new Date(data);

  const year = datePickerResponse.getFullYear();
  const month = String(datePickerResponse.getMonth() + 1).padStart(2, '0');
  const day = String(datePickerResponse.getDate()).padStart(2, '0');
const formattedDate = `${year}-${month}-${day}`;
  
  if (data){
  setEndDate(formattedDate)
  setEndDate1(formattedDate)
}
    if(searchTerm){
    const response = await window.Platform.database.filterVisit({filterField:searchTerm.field,filterValue:searchTerm?.value,pageNumber:paginationModel.page,startDate:startDate,endDate:data?formattedDate:endDate })
    const jsonArrayWithId = response?.data?.map((obj, index) => ({ ...obj, id: index + 1 }));
    setAccessChanges(jsonArrayWithId)
    setRowCount(response.data1 )
     
  }
    else{
    const response = await window.Platform.database.filterVisit({filterField:"date",filterValue:searchTerm?.value,pageNumber:paginationModel.page,startDate:startDate,endDate:data?formattedDate:endDate })
    const jsonArrayWithId = response?.data?.map((obj, index) => ({ ...obj, id: index + 1 }));
    setAccessChanges(jsonArrayWithId)
    setRowCount(response.data1 )
    
    }
    setLoading(false)

  }
  catch(err){
    console.log(err)
    setLoading(false)
    window.NotificationUtils.showError("Error While Recieving Data Please Wait and try again");

    fetchVisits()
  
  }
}
const clearDateFilter=async()=>{
  setStartDate(null)
  setEndDate(null)
  setStartDate1(null)
  setEndDate1(null)
  setDateRange1(false)
  if(searchTerm){
    try{
    setLoading(true)
    const response = await window.Platform.database.filterVisit({filterField:searchTerm?searchTerm.field:"date",filterValue:searchTerm?.value,pageNumber:paginationModel.page,startDate:'',endDate:'' })
    const jsonArrayWithId = response?.data?.map((obj, index) => ({ ...obj, id: index + 1 }));
    setAccessChanges(jsonArrayWithId)
      setRowCount(response.data1 )
      
    setLoading(false)
  }
  catch(e){
    console.log(e)
    window.NotificationUtils.showError("Error While Recieving Data Please Wait and try again");
    fetchVisits()  

    }
  }
else{
  setFilter(false)
  fetchVisits()
}
}

  return (
    <>
      
      <StaffEditorPageContainer>
      {loading && <OpaqueLoading/>}
        <HeaderContainer>
        <div style={{width:"60%",display:"flex",flexDirection:"row",gap:"10px"}}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker label="Start Date" value={startDate1} disabled={loading} format="YYYY/MM/DD" onChange={(data)=>formatDate(data)} disableFuture={true} />
            </LocalizationProvider>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker label="End Date"  minDate={minDate} value={endDate1} disabled={!dateRange1||loading} format="YYYY/MM/DD" onChange={(data)=>finalDateRangeFilter(data.$d)} disableFuture={true} />
            </LocalizationProvider>
            <Button variant="text" onClick={()=>clearDateFilter()} disabled={!dateRange1||loading} >Clear</Button>


            </div>
          {/* <Typography variant="h5">Visits Management</Typography> */}
          {/* <FabStyle variant="extended" color="primary" size='small'

onClick={()=>addVisit()}

>

<Add sx={{ mr: 1 }} />

Add Visit

</FabStyle> */}

<Button variant="contained" color="primary" 
             onClick={()=>addVisit()} sx={{ mr: 1, borderRadius: '10px' }}>
  <Add sx={{ mr: 1 }}  />
  Add Visit
</Button>

         
        </HeaderContainer>
        <TableContainer>{renderContent()}</TableContainer>
      </StaffEditorPageContainer>
      {
      visitModal &&
      <VisitModal
      onClose={() => setVisitModal(false)}
      // initialData={regexes.filter(rx => rx.id === showEditFormModal)[0]}
      loading={loading}
      fetchData={()=>fetchVisits()}
      status={true}
    //   onSubmit={updateWarehouseData}
      />
    }
     {
      editModal &&
      <VisitModal
      onClose={() => setEditModal(false)}
      // initialData={regexes.filter(rx => rx.id === showEditFormModal)[0]}
      loading={loading}
      fetchData={()=>fetchVisits()}
      status={false}
      propData={dataModal}
   />
    }
    </>
  );
}

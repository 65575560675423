import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogContent,
  Typography,
  IconButton,
  Button,
  TextField,
} from "@mui/material";
import styled from "styled-components";
import OpaqueLoading from "../opaqueLoading/opaqueLoading";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import moment from "moment";
import MomentUtils from "@date-io/moment";
// import {
//   MuiPickersUtilsProvider,
//   KeyboardDatePicker,
// } from "@mui/lab";
import { DATE_FORMATS } from "../../constants";


const ModalHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
`;

const InputFieldContainer = styled.div`
  margin: 20px 0px;
`;

const ModalActionsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;
const FirstButton = styled(Button)`
  margin-right: 10px !important;
`;

const StyledDialogContent = styled(DialogContent)`
  padding: 16px;
`;

const StyledDialog = styled(Dialog)`
  position: relative;
`;

const CommonOtpModalConsent = (props) => {
    // console.log("COMMONOTP",props.data)
  const [loading, setLoading] = useState(false);

  const [showModal, setShowModal] = useState(true);

  const [value, setValue] = useState('');
  const [reason, setReason] = useState('');
  const [terminationDate, setTerminationDate] = useState(moment(new Date()).startOf("day").unix());
  const [terminationDateString, setTerminationDateString] = useState(null);
  const [resendButtonDisabled, setResendButtonDisabled] = useState(false);
  useEffect(() => {
    let timer;
    if (!resendButtonDisabled) {
      timer = setTimeout(() => {
        setResendButtonDisabled(true);
      }, 60000); // 1 minute in milliseconds
    }
    return () => clearTimeout(timer);
  }, [resendButtonDisabled]);

  const onClickSubmit = async() => {
    try {
        console.log("COMMONOTP", reason)
      setLoading(true);
      if(props.onClickSubmit){ 
        console.log(props.data,"dara")
        await props.onClickSubmit(props.data, reason);
        
    }
    props.closeModal()
      setLoading(false);
    } catch (error) {
      window.NotificationUtils.showError("Internal Error");
      setLoading(false);
    }
  }

  const onClickResend = async() => {
    try {
        console.log("COMMONOTP", reason)
      // setLoading(true);
      // if(props.onClickSubmit){ 
        await props.resendOTP(props.data);
    // }
    // props.closeModal()
      // setLoading(false);
    } catch (error) {
      console.log(error);
      window.NotificationUtils.showError("Internal Error");
      setLoading(false);
    }
  }

  const handleChange = (e) => {
    const { value } = e.target;
    // Allow only digits and limit to 6 characters
    if (/^\d{0,6}$/.test(value)) {
      setReason(value);
    }
  };

  const updateDateValue = (value, stringValue = "") => {
    setTerminationDate(value.startOf("day").unix());
    setTerminationDateString(stringValue);
  };
  
  const dateErrorUpdate = (message) => {
    // if(message){
    //   if(!this.dateError){
    //     this.dateError = true;
    //   }
    // }
    // else{
    //   if(this.dateError){
    //     this.dateError = false;
    //   }
    // }
  }
  
  const renderModalContent = () => {
    return (
      <>
       
        <InputFieldContainer>
            <TextField
                fullWidth={true}
                label="Enter OTP"
                required
                size='medium'
                variant="outlined"
                value={reason || ""}
                type="number"
                onChange={handleChange}
                />
                 <FirstButton
            // disabled={loading||!resendButtonDisabled}
            onClick={onClickResend}
            // variant="contained"
            color="primary"
          >
            resend otp <i class="fa fa-repeat" aria-hidden="true"></i>

          </FirstButton>
        </InputFieldContainer>
        {/* <InputFieldContainer>
            <MuiPickersUtilsProvider
                libInstance={moment}
                utils={MomentUtils}
                >
                <KeyboardDatePicker
                    fullWidth
                    disableFuture
                    required
                    size='medium'
                    onError={dateErrorUpdate}
                    allowKeyboardControl={false}
                    autoOk
                    label="Termination Date"
                    value={
                      moment.unix(terminationDate) || null
                    }
                    onChange={(date, value) => {
                      updateDateValue(date, value)
                      props.setData("terminationDate", value.startOf("day").unix())
                    }}
                    format={DATE_FORMATS.DEFAULT}
                    inputValue={terminationDateString || ""}
                    inputVariant="outlined"
                    variant="inline"
                    PopoverProps={{
                      anchorOrigin: {
                        vertical: "bottom",
                            horizontal: "right",
                          },
                    }}
                    openTo="date"
                    rifmFormatter={(input) => {
                      return input;
                    }}
                    />
            </MuiPickersUtilsProvider>
          </InputFieldContainer> */}
        <ModalActionsContainer>
         
          <Button
            disabled={loading}
            onClick={onClickSubmit}
            variant="contained"
            color="primary"
          >
            Verify OTP
          </Button>
        </ModalActionsContainer>
      </>
    );
  };

  return (
    <StyledDialog maxWidth="xs" fullWidth={true} open={showModal}>
      <StyledDialogContent>
        <ModalHeaderContainer>
          <Typography variant="h5">Enter OTP</Typography>
          <IconButton disabled={loading} onClick={props.closeModal}>
            <CloseOutlinedIcon />
          </IconButton>
        </ModalHeaderContainer>
        {loading && <OpaqueLoading />}
        {renderModalContent()}
      </StyledDialogContent>
    </StyledDialog>
  );
};

export default CommonOtpModalConsent;

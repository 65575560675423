import React, { useContext, useEffect, useState } from "react";
import {
  AppBar,
  Drawer,
  ListItem,
  ListItemText,
  Toolbar,
  List,
  Typography,
  IconButton,
  Badge,
  Stack,
  Avatar,
  Button,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import styled, { keyframes } from 'styled-components';
import { getNavItems } from "../../header";
import { NavLink } from "react-router-dom";
import NotificationsIcon from "@mui/icons-material/Notifications";
import { PRIMARY_COLOR } from "../../../constants";
import { Fab } from "@mui/material";
import { KeyboardArrowLeft, KeyboardArrowRight } from "@mui/icons-material";
import { AuthContext } from "../../contextAPI/ContextAPI";
import Home from './home.svg'
const isMobile = window.innerWidth < 900;

const LogoContainer = styled.div`
  background-color: ${(props) =>
    props.drawerOpen ? "white" : isMobile ? "unset" : "white"};

  padding: ${(props) => (props.drawerOpen ? "0px" : isMobile ? "0px" : "10px")};

  border-radius: ${(props) =>
    props.drawerOpen ? (isMobile ? "20px" : "0px") : "0px"};
`;

const Logo = styled.img`
  width: 100%;

  margin-top: ${(props) =>
    props.drawerOpen ? (isMobile ? "15px" : "0px") : "0px"};

  padding: ${(props) =>
    props.drawerOpen ? (isMobile ? "0px" : "15px") : "0px"};

  height: ${(props) =>
    props.drawerOpen ? (isMobile ? "auto" : "60px") : "30px"};
`;

const DrawerContentContainer = styled.div`
  height: 100vh;
  width: 90%;
  background: ${PRIMARY_COLOR};
  transition: all 0.2s;
  border-radius: ${(props) => (props.drawerOpen ? "20px" : "10px")};
  overflow:hidden;
  border:1px solid ${PRIMARY_COLOR};
`;

const DrawerListsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: calc(100% - 85px);
`;
const DrawerLink = styled(NavLink)`
  border-left: 5px solid transparent;
  transition: all 0.2s;
  &:hover,
  &.active {
    border-left: 5px solid white;
    ${props => props.isMobile && "border-radius:10px;"}
    ${props => props.isMobile && "background: white;"}
   i, span{
    // color: ${props => props.isMobile ? PRIMARY_COLOR : 'white'} !important;
  }
  }
`;
const DrawerItemIcon = styled.i`
  color:white;
`;
const DrawerItemText = styled(ListItemText)`
margin-left: 16;
color:white;
`;
const DrawerAction = styled(ListItem)`
  gap:10px;
  border-left: 5px solid transparent;
  transition: all 0.2s;
  &:hover,
  &.active {
    border-left: 5px solid white;
    ${props => props.isMobile && "border-radius:10px;"}
    ${props => props.isMobile && "background: white;"}
   i, span{
    color: ${props => props.isMobile ? PRIMARY_COLOR : 'white'} !important;
  }
  }
`;
const MarqueeContainer = styled.div`
  overflow: hidden;
  white-space: nowrap;
  width: 100%;
`;

const marqueeLeftRight = keyframes`
  0%, 100% {
    transform: translateX(100%);
  }
  50% {
    transform: translateX(-100%);
  }
`;

const MarqueeText = styled.span`
  color: #21225F;
  display: inline-block;
  animation: ${marqueeLeftRight} 15s linear infinite;
`;
const drawerWidth = 240;
const ComponentWrapper = (props) => {

    console.log("header",props.location, props.header)
  const { auth, setAuth} = useContext(AuthContext)

  const initialDrawerState = JSON.parse(localStorage.getItem('isDrawerOpen'))
  // JSON.parse(localStorage.getItem('isDrawerOpen'))
  const [drawerOpen, setDrawerOpen] = useState(isMobile ? false : initialDrawerState);

  const navItems = getNavItems();

  const onClickLogout = async () => {
    try {
      await window.Platform.database.signOut();

      auth.onAuthUserChanged && auth.onAuthUserChanged();
      console.log("auth",auth)
    } catch (error) {
      window.NotificationUtils.showError("Something Went Wrong");
    }
  };

  const getUserName = () => {
    let result = "";
    if (auth.user) {
      result = auth.user.user?.username + '(' + auth.user.user?.role + ')' || "";
    }
    let length = 25;
    if (result.length > length && isMobile) {
      result = result.slice(0, length - 3) + "...";
    }
    return result;
  };

  const getNotificationCount = () => {
    let result = Number(
      auth.user?.notifications ? auth.user?.notifications.length : 0
    );
    return result;
  };

const handleHomeClick=()=>{
  window.location.href='/dashboard'
}

  const renderDrawerContent = () => {
    return (
      <DrawerContentContainer drawerOpen={drawerOpen}>
        <NavLink to={"/dashboard"}>
          <LogoContainer drawerOpen={drawerOpen}>
            <Logo
              drawerOpen={drawerOpen}
              src={
                drawerOpen
                  ? "logo1.png"
                  : isMobile
                    ? "logo1.png"
                    : "logo512.png"
              }
            />
          </LogoContainer>
        </NavLink>

        <DrawerListsContainer>
          <List>
            {navItems.map((navItem) => {
              return (
                <DrawerLink
                  isMobile={isMobile}
                  key={navItem.title}
                  to={navItem.link}
                  className={"nav-link"}
                >
                  <ListItem style={{ gap: '10px' }}>
                    <DrawerItemIcon
                      className="icon material-icons" as= {navItem.icon}
                    >
                     
                    </DrawerItemIcon>
                    {drawerOpen && (
                      <DrawerItemText
                        primary={navItem.title}
                      />
                    )}
                  </ListItem>
                </DrawerLink>
              );
            })}
          </List>

          <List style={{ marginBottom: 10, width: "100%" }}>
            <DrawerAction
              isMobile={isMobile}
              className="nav-link"
              onClick={onClickLogout}
              style={{ cursor: "pointer" }}
            >
              <DrawerItemIcon className="icon material-icons" >
                {"power_settings_new"}
              </DrawerItemIcon>
              {drawerOpen && (
                <DrawerItemText
                  primary={"Logout"}
                />
              )}
            </DrawerAction>
          </List>
        </DrawerListsContainer>
      </DrawerContentContainer>
    );
  };

  useEffect(() => {
    localStorage.setItem('isDrawerOpen', drawerOpen)
  }, [drawerOpen])

  return (
    <div style={{ display: 'flex', width: '100%' }}>
      {/* <Drawer
        style={{
          width: drawerOpen ? "240px" : "80px",
          transition: "all 0.2s",
        }}
        variant={isMobile ? "temporary" : "permanent"}
        sx={{
          width: drawerOpen ? "240px" : "80px",
          "& .MuiDrawer-paper": {
            padding: "10px",
            border: "none",
            background: isMobile ? PRIMARY_COLOR : "none",
            overflow: "visible",
            width: drawerOpen ? "240px" : "80px",
            transition: "all 0.2s",
          },
        }}
        anchor="left"
        open={drawerOpen}
        onClose={() => setDrawerOpen(false)}
      >
        {!isMobile && (
          <Fab
            onClick={() => setDrawerOpen((prev) => !prev)}
            size="small"
            sx={{
              position: "absolute",
              right: "-7px",
              top: "180px",
              zIndex: "5",
              borderRadius: "40px",
              width: "40px",
              height: "40px",
              boxShadow: "0px 0px 5px 0px rgb(0 0 0 / 26%)",
              // background: ,
              color: PRIMARY_COLOR,
              transition: "all 0.2s",
            }}
          >
            {drawerOpen ? (
              <KeyboardArrowLeft sx={{ fontSize: "28px" }} />
            ) : (
              <KeyboardArrowRight sx={{ fontSize: "28px" }} />
            )}
          </Fab>
        )}
        {renderDrawerContent()}
      </Drawer> */}

      <div style={{ width: "100%" }}>
        <AppBar
          position="sticky"
          style={{ boxShadow: "none", background: "#E6E6E6", top: "0", left: "0",zIndex:0 }}
          sx={{height:"40px"}}
        >
          
          <Toolbar sx={{height:"20px", }}>
            <div
              style={{
                display: "flex",
                gap: "20px",
                width: "100%",
                alignItems: "center",
                marginBottom:"20px"
              }}
            >
              {isMobile && (
                <IconButton onClick={() => setDrawerOpen(true)}>
                  <MenuIcon />
                </IconButton>
              )}
              <Typography
                style={{ color: "black", textTransform: "initial", fontSize:"14px", fontWeight:"400" }}
               

                // variant="caption"

              >
                <IconButton size="medium" onClick={handleHomeClick}><img src={Home} style={{height:"15px", weight:"15.65px", marginBottom: "5px" }}/></IconButton>
                &nbsp;
                &nbsp;
                {props?.header?.name}
              </Typography>
          
              {/* <Typography
                style={{ color: "black", textTransform: "initial",display:"flex", gap: "2px" }}
                // variant="caption"
                fontSize={12}
              >

                <span style={{color: '#666666'}}>Dashboard</span> / <span style={{color: '#2D88D4'}}>
                    {props.header.name}
                    </span>
              </Typography> */}
          
            </div>
          </Toolbar>
        </AppBar>
        <div>
          {props.children}
        </div>
      </div>
    </div>
  );
};

export default ComponentWrapper;

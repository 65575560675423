import {
  Button,
  Fab,
  Tab,
  Tabs,
  Tooltip,
  Typography,
  IconButton,
} from "@mui/material";
import React, { useState, useEffect, useContext } from "react";
import styled from "styled-components";
import Box from "@mui/material/Box";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
} from "@mui/x-data-grid";
import { Add } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import DeleteIcon from '@mui/icons-material/Delete';

import { TABS } from "../../constants";
import { AuthContext } from "../../components/contextAPI/ContextAPI";
import OpaqueLoading from "../../components/opaqueLoading/opaqueLoading";
import "../../App.css";
import ProductModal from "../../components/modals/productModal";
import BulkProductAdd from "../../components/modals/bulkProductAdd";
import SeriesCategory from "../../components/modals/addSeriesCategory";
import ProductMittplusModal from "../../components/modals/productMittplusModal";

const isMobile = window.innerWidth < 900;

const StaffEditorPageContainer = styled.div`
  height: ${isMobile ? `auto` : `calc(100vh - 105px)`};
  width: 100%;
  background-color: white;
  padding: 10px 15px;
  ${isMobile && `position: relative;`}
`;

const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 7px;
`;

const TableContainer = styled.div`
  width: 100%;
  border: solid 1px lightGrey;
  border-radius: 8px;
`;

const StyledTabs = styled(Tabs)`
  border-bottom: solid 1px lightGrey;
`;

const DataGridContainer = styled.div`
  max-width: 100%;
  margin: 0 auto;
  height: 100%;
  width: 100%;
  display: ${isMobile ? "flex" : "grid"};
  border-radius: 4px;
  ${isMobile && `flex-direction: column;`}
`;

const TabsContainer = styled.div`
  ${isMobile && `height: calc(100vh - 56px - 20px - 43.77px);`}
`;

const ProductManagement = (props) => {

  const [currentPage, setCurrentPage] = useState(1);
  const [currentPage1, setCurrentPage1] = useState(1);
  const [paginationModel, setPaginationModel] = useState({
    page: currentPage - 1,
    pageSize: 11,
  });

  const [paginationModel1, setPaginationModel1] = useState({
      page: currentPage1 - 1,
      pageSize: 11,
  });

  const [selectedTab, setSelectedTab] = useState(TABS.REGISTERED);

  const [loading, setLoading] = useState(false);

  const [filter, setFilter] = useState(false);

  const [bulk, setBulk] = useState(false);
  const [series, setSeries] = useState(false);
  const [visitModal, setVisitModal] = useState(false);
  const [mittplusProduct, setMittplusProduct] = useState(false);
  const [accessChanges, setAccessChanges] = useState("");
  const [editModal, setEditModal] = useState(false);
  const [editMittplusProduct, setEditMittplusProduct] = useState(false);
  const [dataModal, setDataModal] = useState();

  const addVisit = () => {
    setDataModal(null);
    setVisitModal(true);
  };

  const addMittplusProduct = () => {
    setDataModal(null);
    setMittplusProduct(true);
  };

  useEffect(() => {
    if (!filter) {
      if (selectedTab === 0) {
        fetchProduct();
      } else {
        fetchProductMittplus();
      }
    }
  }, [selectedTab, paginationModel.page, paginationModel1.page]);

  // useEffect(() => {
  //   fetchProduct();
  // }, []);

  const fetchProduct = async () => {
    try {
      setLoading(true);
      let results = await window.Platform.database.fetchProduct1();
      const jsonArrayWithId = results?.data?.map((obj, index) => ({
        ...obj,
        id: obj.skuId,
      }));
      setAccessChanges(jsonArrayWithId);
      setLoading(false);
    } catch (error) {
      window.NotificationUtils.showError("Error Fetching Data");
      setLoading(false);
    }
  };

  const fetchProductMittplus = async () => {
    try {
      setLoading(true);
      let results = await window.Platform.database.fetchProductMittplus();
      const jsonArrayWithId = results?.data?.map((obj, index) => ({
        ...obj,
        id: obj.id,
      }));
      setAccessChanges(jsonArrayWithId);
      setLoading(false);
    } catch (error) {
      window.NotificationUtils.showError("Error Fetching Data");
      setLoading(false);
    }
  };

  const handleEdit = (data, type)=>{
    if (type === 'MITTPLUS') {
      setEditMittplusProduct(true);
    } else {
      setEditModal(true);
    }
    setDataModal(data);
  }

  const deleteProduct = async (item, type) => {
    try {
      setLoading(true);
      if (type === 'MITTPLUS') {
        await window.Platform.database.deleteMittplusProduct({ id: item.id });
        fetchProductMittplus();
      } else {
        await window.Platform.database.deleteProduct({ id: item.id });
        fetchProduct();
      }
      setLoading(false);
      window.NotificationUtils.showSuccess("Product deleted successfully!")
    }
    catch (error) {
      console.error(error);
    }
  }

  const handleTabChange = async (value) => {
    setSelectedTab(value);
    setAccessChanges([]);
  };

  const formatTimeStamp = (timestampFromDB) => {
    const options = {
      timeZone: 'Asia/Kolkata',
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      hour12: true
    };
  
    const formattedDate = new Date(timestampFromDB).toLocaleString('en-US', options);
    return formattedDate;
  };

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarExport
          csvOptions={{
            fileName: "export-products",
          }}
        />
      </GridToolbarContainer>
    );
  }

  const getEmployeeColumns = () => {
    let result = [
      {
        field: "nameSku",
        headerName: "SKU Name",
        sortable: false,
        width: 300,
        editable: false,
        valueGetter: (params) => {
          return (params.row?.nameSku || "N/A");
        },
        renderCell: (params) => {
          let name = params.row?.nameSku;
          return (
            <Tooltip title={name || "N/A"}>
              <Typography variant="inherit">{name || 'N/A'}</Typography>
            </Tooltip>
          );
        },
      },
      {
        field: "unitPrice",
        headerName: "Unit Price",
        width: 200,
        sortable: false,
        valueGetter: (params) => {
          return (params.row?.unitPrice || "N/A");
        },
        renderCell: (params) => {
          let name = params.row?.unitPrice;
          return (
            <Tooltip title={name || 'N/A'}>
              <Typography variant="inherit">{name || 'N/A'}</Typography>
            </Tooltip>
          );
        },
      },
      {
        field: "mediumName",
        headerName: "Medium Name",
        width: 200,
        sortable: false,
        valueGetter: (params) => {
          return (params.row?.mediumName || "N/A");
        },
        renderCell: (params) => {
          let name = params.row?.mediumName;
          return (
            <Tooltip title={name || 'N/A'}>
              <Typography variant="inherit">{name || 'N/A'}</Typography>
            </Tooltip>
          );
        },
      },
      {
        field: "boardName",
        headerName: "Board Name",
        width: 200,
        sortable: false,
        valueGetter: (params) => {
          return (params.row?.boardName || "N/A");
        },
        renderCell: (params) => {
          let name = params.row?.boardName;
          return (
            <Tooltip title={name || 'N/A'}>
              <Typography variant="inherit">{name || 'N/A'}</Typography>
            </Tooltip>
          );
        },
      },
      {
        field: "className",
        headerName: "Class",
        width: 150,
        sortable: false,
        valueGetter: (params) => {
          return (params.row?.className || "N/A");
        },
        renderCell: (params) => {
          let name = params.row?.className;
          return (
            <Tooltip title={name || 'N/A'}>
              <Typography variant="inherit">{name || 'N/A'}</Typography>
            </Tooltip>
          );
        },
      },
      {
        field: "subjectName",
        headerName: "Subject Name",
        width: 200,
        sortable: false,
        valueGetter: (params) => {
          return (params.row?.subjectName || "N/A");
        },
        renderCell: (params) => {
          let name = params.row?.subjectName;
          return (
            <Tooltip title={name || 'N/A'}>
              <Typography variant="inherit">{name || 'N/A'}</Typography>
            </Tooltip>
          );
        },
      },
      {
        field: "skuCode",
        headerName: "SKU Code",
        width: 200,
        sortable: false,
        valueGetter: (params) => {
          return (params.row?.sku_code || "N/A");
        },
        renderCell: (params) => {
          let name = params.row?.sku_code;
          return (
            <Tooltip title={name || 'N/A'}>
              <Typography variant="inherit">{name || 'N/A'}</Typography>
            </Tooltip>
          );
        },
      },
      {
        field: "Actions",
        headerName: "Actions",
        editable: false,
        hide: true,
        minWidth: 150,
        filterable: false,
        disableExport: true,
        renderCell: (params) => {
          return (
            <>
              <Tooltip title={"Edit Product"}>
                <IconButton onClick={() => handleEdit(params.row, 'MITTSURE')}>
                  <DriveFileRenameOutlineIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title={"Delete Product"}>
                <IconButton onClick={() => deleteProduct(params.row, 'MITTSURE')}>
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
            </>
          );
        },
      },
    ];
    return result;
  };

  const getMittplusColumns = () => {
    let result = [
      {
        field: "productTypeName",
        headerName: "Product Type",
        width: 250,
        sortable: false,
        valueGetter: (params) => {
          return (params.row?.productTypeName || "N/A");
        },
        renderCell: (params) => {
          let name = params.row?.productTypeName;
          return (
            <Tooltip title={name || "N/A"}>
              <Typography variant="inherit">{name || 'N/A'}</Typography>
            </Tooltip>
          );
        },
      },
      {
        field: "productName",
        headerName: "Product Name",
        sortable: false,
        width: 300,
        editable: false,
        valueGetter: (params) => {
          return (params.row?.product_name || "N/A");
        },
        renderCell: (params) => {
          let name = params.row?.product_name;
          return (
            <Tooltip title={name || "N/A"}>
              <Typography variant="inherit">{name || 'N/A'}</Typography>
            </Tooltip>
          );
        },
      },
      {
        field: "invoice",
        headerName: "Invoice",
        width: 200,
        sortable: false,
        valueGetter: (params) => {
          return (params.row?.invoice || "N/A");
        },
        renderCell: (params) => {
          let name = params.row?.invoice;
          return (
            <Tooltip title={name || "N/A"}>
              <Typography variant="inherit">{name || 'N/A'}</Typography>
            </Tooltip>
          );
        },
      },
      {
        field: "class",
        headerName: "Class",
        width: 250,
        sortable: false,
        valueGetter: (params) => {
          return (params.row?.className || "N/A");
        },
        renderCell: (params) => {
          let name = params.row?.className;
          return (
            <Tooltip title={name || "N/A"}>
              <Typography variant="inherit">{name || 'N/A'}</Typography>
            </Tooltip>
          );
        },
      },
      {
        field: "access_code_purchased",
        headerName: "Access Code Purchased",
        width: 200,
        sortable: false,
        valueGetter: (params) => {
          return (params.row?.access_code_purchased || "N/A");
        },
        renderCell: (params) => {
          let name = params.row?.access_code_purchased;
          return (
            <Tooltip title={name || "N/A"}>
              <Typography variant="inherit">{name || 'N/A'}</Typography>
            </Tooltip>
          );
        },
      },
      {
        field: "landingCost",
        headerName: "Landing Cost",
        width: 200,
        sortable: false,
        valueGetter: (params) => {
          return (params.row?.landing_cost || "N/A");
        },
        renderCell: (params) => {
          let name = params.row?.landing_cost;
          return (
            <Tooltip title={name || "N/A"}>
              <Typography variant="inherit">{name || 'N/A'}</Typography>
            </Tooltip>
          );
        },
      },
      {
        field: "validity_end_date",
        headerName: "Validity End Date",
        width: 250,
        sortable: false,
        valueGetter: (params) => {
          return (params.row?.validity_end_date ? formatTimeStamp(params.row?.validity_end_date) : "N/A");
        },
        renderCell: (params) => {
          let name = params.row?.validity_end_date ? formatTimeStamp(params.row?.validity_end_date) : "N/A";
          return (
            <Tooltip title={name || "N/A"}>
              <Typography variant="inherit">{name || 'N/A'}</Typography>
            </Tooltip>
          );
        },
      },
      {
        field: "Actions",
        headerName: "Actions",
        editable: false,
        hide: true,
        minWidth: 150,
        filterable: false,
        disableExport: true,
        renderCell: (params) => {
          return (
            <>
              <Tooltip title={"Edit Mittplus Product"}>
                <IconButton onClick={() => handleEdit(params.row, 'MITTPLUS')}>
                  <DriveFileRenameOutlineIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title={"Delete Mittplus Product"}>
                <IconButton onClick={() => deleteProduct(params.row, 'MITTPLUS')}>
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
            </>
          );
        },
      },
    ];
    return result;
  };

  const renderContent = () => {
    return (
      <Box style={{ height: !accessChanges?.length ? '200px' : 'auto' }}>
        <DataGridContainer>
          <DataGrid
            className="payrollGrid"
            density="compact"
            rows={ accessChanges || [] }
            columns={ getEmployeeColumns() }
            rowHeight={60}
            disableDensitySelector
            disableSelectionOnClick
            disableRowSelectionOnClick
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 10,
                },
              },
            }}
            pageSizeOptions={[10]}
            components={{
              Toolbar: CustomToolbar,
            }}
          />
        </DataGridContainer>
      </Box>
    );
  };

  const renderContentMittplus = () => {
    return (
      <Box style={{ height: !accessChanges?.length ? '200px' : 'auto' }}>
        <DataGridContainer>
          <DataGrid
            className="payrollGrid"
            density="compact"
            rows={ accessChanges || [] }
            columns={ getMittplusColumns() }
            rowHeight={60}
            disableDensitySelector
            disableSelectionOnClick
            disableRowSelectionOnClick
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 10,
                },
              },
            }}
            pageSizeOptions={[10]}
            components={{
              Toolbar: CustomToolbar,
            }}
          />
        </DataGridContainer>
      </Box>
    );
  };

  return (
    <>
      <StaffEditorPageContainer>
        <HeaderContainer>
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              gap: "10px",
            }}
          >
            <Button
              variant="contained"
              color="primary"
              onClick={() => setSeries(true)}
              sx={{ mr: 1, borderRadius: "10px" }}
            >
              <Add sx={{ mr: 1 }} />
              Add Series
            </Button>

            <Button
              variant="contained"
              color="primary"
              onClick={() => setBulk(true)}
              sx={{ mr: 1, borderRadius: "10px" }}
            >
              <Add sx={{ mr: 1 }} />
              Bulk Add
            </Button>

            <Button
              variant="contained"
              color="primary"
              onClick={ selectedTab === 0 ? () => addVisit() : () => addMittplusProduct() }
              sx={{ mr: 1, borderRadius: "10px" }}
            >
              <Add sx={{ mr: 1 }} />
              Add New
            </Button>
          </div>
        </HeaderContainer>

        <TableContainer>
          <TabsContainer>
            <StyledTabs
              variant="fullWidth"
              value={selectedTab}
              onChange={(event, value) => handleTabChange(value)}
              indicatorColor="primary"
              textColor="primary"
            >
              <Tab label="Mittsure" />
              <Tab label="Mittplus" />
            </StyledTabs>
            {loading && <OpaqueLoading />}
            { selectedTab === 0 ? renderContent() : renderContentMittplus() }
          </TabsContainer>
        </TableContainer>
      </StaffEditorPageContainer>
      {
        visitModal && (
          <ProductModal
            onClose={() => setVisitModal(false)}
            loading={loading}
            fetchData={ () => fetchProduct() }
            props = { dataModal }
            status={true}
          />
        )
      }
      {
        editModal && (
          <ProductModal
            onClose={() => setEditModal(false)}
            loading={loading}
            fetchData={ () => fetchProduct() }
            props = { dataModal }
            status={false}
          />
        )
      }
      {
        mittplusProduct && (
          <ProductMittplusModal
            onClose={() => setMittplusProduct(false)}
            loading={ loading }
            props = { dataModal }
            fetchData={ () => fetchProductMittplus() }
            status={ true }
          />
        )
      }
      {
        editMittplusProduct && (
          <ProductMittplusModal
            onClose={() => setEditMittplusProduct(false)}
            loading={ loading }
            props = { dataModal }
            fetchData={ () => fetchProductMittplus() }
            status={false}
          />
        )
      }
      {
        series && (
          <SeriesCategory
            onClose={() => setSeries(false)}
            loading={loading}
            fetchData={ selectedTab === 0 ? () => fetchProduct() : () => fetchProductMittplus() }
            status={true}
          />
        )
      }
      {
        bulk && (
          <BulkProductAdd
            onClose={() => setBulk(false)}
            fetchData={ selectedTab === 0 ? () => fetchProduct() : () => fetchProductMittplus() }
          />
        )
      }
    </>
  );
};

export default ProductManagement;
import React, { useEffect, useState } from "react";
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogContent,
  FormControl,
  FormControlLabel,
  FormGroup,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { CloseOutlined } from "@mui/icons-material";
import { Stack } from "@mui/material";
import styled from "styled-components";
import { ACTIVITY_STATUS } from "../../constants";
import OpaqueLoading from "../opaqueLoading/opaqueLoading";
import { DataGrid,getGridStringOperators } from "@mui/x-data-grid";

const StyledDialogContent = styled(DialogContent)`
  padding: 16px;
`;
const ModalActionsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const StyledDialog = styled(Dialog)`
  // position: relative;
  // max-width: 700px;
  
`;  

  const ModalHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
    `;
  const isMobile=window.innerWidth<900
  
  const SchoolDetailsUpdate = ({ onClose ,propData,fetchData }) => {
   const [data,setData]=useState([])
   const [loading,setLoading]=useState(false)
console.log("DATAAUTH", JSON.parse(propData.newData))
useEffect(()=>{
    getSchoolById({id:propData.partyId})
},[])

const getSchoolById=async(data)=>{
    try{
        setLoading(true)
        let results = await window.Platform.database.getSchoolById(data);
      const jsonArrayWithId = results?.data?.map((obj, index) => ({ ...obj, id: index + 1 }));

        setData(jsonArrayWithId)
        setLoading(false)

    }
    catch(error){
        setLoading(false)

        console.log(error)
    }
}
const formatTimeStamp = (timestampFromDB) => {
    const options = {
      timeZone: 'Asia/Kolkata',
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,  // Use 12-hour clock
    };
  
    const formattedDate = new Date(timestampFromDB).toLocaleString('en-US', options);
  
    return formattedDate;
  };

const schoolColumns =()=>{
    const stringOperators = getGridStringOperators().filter((op => ['contains'].includes(op.value)));

    let result= [

    {

        field: "schoolName",

        headerName: "Name",

        minWidth: 200,

        valueGetter: (params) => {

            let name = params.row?.schoolName
            return name;

        },

        flex: 0.22,

        editable: false,
        filterOperators: stringOperators 
,

        renderCell: (params) => {

            let name = params.row?.schoolName || "N/A"

            return (

                <Tooltip title={name || "undefined"}>

                    <Typography variant="inherit">{name}</Typography>

                </Tooltip>

            )

        },

    },

    {

        field: "name",

        headerName: "Created By",

        minWidth: 200,

        valueGetter: (params) => {

            let name = params.row.name
            return name;

        },

        flex: 0.22,

        editable: false,
        filterOperators: stringOperators 
,

        renderCell: (params) => {

            let name = params.row.name

            return (

                <Tooltip title={name || "undefined"}>

                    <Typography variant="inherit">{name}</Typography>

                </Tooltip>

            )

        },

    },

    {

        field: "email",

        headerName: "School Email",

        minWidth: 250,

        flex: 0.35,

        editable: false,
        filterable: false,


        renderCell: (params) => {

            return (

                <Tooltip title={params.row.email || "undefined"}>

                    <Typography variant="inherit">{params.row.email}</Typography>

                </Tooltip>

            )

        },

    },
    {
        field: "createdAt",
        headerName: "Created At",
        width: 200,
        // height: 10,
        // headerAlign: 'center',
        sortable: false,
        filterable: false,
  
        // editable: true,
        renderCell: (params) => {
  
          let name =formatTimeStamp( params.row.createdAt)||"N/A"
  
          return (
  
            <Tooltip title={name || "N/A"}>
  
              <Typography variant="inherit">{name}</Typography>
  
            </Tooltip>
  
          )
  
        },
      },
      {
          field: "updatedAt",
          headerName: "Updated At",
          width: 200,
          // height: 10,
          // headerAlign: 'center',
          sortable: false,      filterable: false,
  
          // editable: true,
          renderCell: (params) => {
    
            let name =formatTimeStamp( params.row.updatedAt)||"N/A"
    
            return (
    
              <Tooltip title={name || "N/A"}>
    
                <Typography variant="inherit">{name}</Typography>
    
              </Tooltip>
    
            )
    
          },
        },
    {

        field: "Website",

        headerName: "School Website",

        minWidth: 250,

        flex: 0.35,

        editable: false,
        filterable: false,

        renderCell: (params) => {

            return (

                <Tooltip title={params.row.website || "undefined"}>

                    <Typography variant="inherit">{params.row.website}</Typography>

                </Tooltip>

            )

        },

    },
    {

        field: "onboardForErp",

        headerName: "Onboarder For ERP",

        minWidth: 250,

        flex: 0.35,

        editable: false,
        filterable: false,

        renderCell: (params) => {

            return (

                <Tooltip title={params.row.onboardForErp || "undefined"}>

                    <Typography variant="inherit">{params.row.onboardForErp === 1 ? "Yes" : "No"}</Typography>

                </Tooltip>

            )

        },

    },

    {

        field: "makerName",

        headerName: "Decision Maker Name",

        minWidth: 250,

        flex: 0.35,

        editable: false,
        filterable: false,

        renderCell: (params) => {

            return (

                <Tooltip title={params.row.makerName || "undefined"}>

                    <Typography variant="inherit">{params.row.makerName}</Typography>

                </Tooltip>

            )

        },

    },

    {

        field: "makerContact",

        headerName: "Decision Maker Contact",

        minWidth: 250,

        flex: 0.35,

        editable: false,
        filterable: false,

        renderCell: (params) => {

            return (

                <Tooltip title={params.row.makerContact || "undefined"}>

                    <Typography variant="inherit">{params.row.makerContact}</Typography>

                </Tooltip>

            )

        },

    },

    // {

    //   field: "employeeCode",

    //   headerName: "Employee code",

    //   minWidth:200,

    //   flex: 0.17,

    //   editable:false,

    //   renderCell: (params) => {

    //     return (

    //       <Tooltip title={params.row.employeeCode || "undefined"}>

    //         <Typography variant="inherit">{params.row.employeeCode}</Typography>

    //       </Tooltip>

    //     )

    //   },

    // },

    {

        field: "board",

        headerName: "Board",

        minWidth: 200,

        flex: 0.22,

        editable: false,
        filterable: false,

        renderCell: (params) => {

            return (

                <Tooltip title={params.row.board || "undefined"} >

                    <Typography variant="inherit">{params.row.board}</Typography>

                </Tooltip>

            )

        },

    },

    {

        field: "medium",

        headerName: "Medium",

        editable: false,

        hide: true,

        minWidth: 200,
        filterable: false,

        valueGetter: (params) => {

            let name = params.row.medium;

            return name;

        },

        renderCell: (params) => {

            let name = params.row.medium;
            return (

                <Tooltip title={name || "undefined"}>

                    <Typography variant="inherit">{name}</Typography>

                </Tooltip>

            )

        },

    },

    {

        field: "incorporationDate",

        headerName: "Incorporation Date",

        editable: false,

        hide: true,

        minWidth: 200,
        filterable: false,

        valueGetter: (params) => {

            let name = formatTimeStamp(params.row.incorporationDate);

            return name;

        },

        renderCell: (params) => {

            let name = params.row.incorporationDate ? formatTimeStamp(params.row.incorporationDate) : "N/A";
            return (

                <Tooltip title={name || "undefined"}>

                    <Typography variant="inherit">{name}</Typography>

                </Tooltip>

            )

        },

    },

    {

        field: "grade",

        headerName: "Grade",

        editable: false,

        hide: true,

        minWidth: 200,
        filterable: false,

        valueGetter: (params) => {

            let name = params.row.grade;

            return name;

        },

        renderCell: (params) => {

            let name = params.row.grade;

            return (

                <Tooltip title={name || "undefined"}>

                    <Typography variant="inherit">{name}</Typography>

                </Tooltip>

            )

        },

    },

    {

        field: "strength",

        headerName: "Strength",

        editable: false,

        hide: true,

        minWidth: 200,
        filterable: false,

        valueGetter: (params) => {

            let name = params.row.strength;

            return name;

        },

        renderCell: (params) => {

            let name = params.row.strength;

            return (

                <Tooltip title={name || "undefined"}>

                    <Typography variant="inherit">{name}</Typography>

                </Tooltip>

            )

        },

    },
    {

        field: "State",

        headerName: "State",

        editable: false,

        hide: true,

        minWidth: 200,
        filterOperators: stringOperators 
,
        valueGetter: (params) => {

            let name = params.row.State

            return name;

        },

        renderCell: (params) => {

            let name = params.row.State

            return (

                <Tooltip title={name || "undefined"}>

                    <Typography variant="inherit">{name}</Typography>

                </Tooltip>

            )

        },

    },

    {

        field: "District",

        headerName: "District",

        editable: false,

        hide: true,

        minWidth: 200,
        filterOperators: stringOperators 
,
        valueGetter: (params) => {

            let name = params.row.District

            return name;

        },

        renderCell: (params) => {

            let name = params.row.District

            return (

                <Tooltip title={name || "undefined"}>

                    <Typography variant="inherit">{name}</Typography>

                </Tooltip>

            )

        },

    },

    {

        field: "AddressLine1",

        headerName: "Address",

        editable: false,

        hide: true,

        minWidth: 200,
        filterable: false,

        valueGetter: (params) => {

            let name = params.row?.AddressLine1?.toString() + " " + params.row.AddressLine2;

            return name;

        },

        renderCell: (params) => {

            let name = params.row?.AddressLine1?.toString() + " " + params.row.AddressLine2;

            return (

                <Tooltip title={name || "undefined"}>

                    <Typography variant="inherit">{name}</Typography>

                </Tooltip>

            )

        },

    },

    {

        field: "Landmark",

        headerName: "Landmark",

        editable: false,

        hide: true,

        minWidth: 200,
        filterable: false,

        valueGetter: (params) => {

            let name = params.row.Landmark;

            return name;

        },

        renderCell: (params) => {

            let name = params.row.Landmark;

            return (

                <Tooltip title={name || "undefined"}>

                    <Typography variant="inherit">{name}</Typography>

                </Tooltip>

            )

        },

    },



];
return result
}

const convertObjectToArrayWithId = (dataObject) => {
    const dataArray = [{...dataObject,id:1}]
    return dataArray
  };
  
  const renderUpdateColumns = () => {
    const data=JSON.parse(propData.newData);
    const columns = Object.keys(data).map((property) => ({
      field: property.toString(),
      headerName: property.toString(),
      flex: 1,
    }));
  
    console.log("ARRAY CONVERSION", columns);
    return columns;
  };
  
const renderContent = () => {
  return (
    // <Box sx={{ height: "100%", width: "100%" }}>
    <><Stack direction={"column"} spacing={2} padding={1}>
        <div>
            <strong>Old Details</strong>
        </div>
        <div 
      style={{height:isMobile ? "555px":"152px"}}
      >
      <DataGrid
        className="payrollGrid"
      //   checkboxSelection={true}
      density="compact"
        rows={data||[]}
        columns={schoolColumns()}
        rowHeight={60}
        disableSelectionOnClick
        disableRowSelectionOnClick
        pageSizeOptions={[10]}
        // autoPageSize
        // components={{
        //   Toolbar: CustomToolbar,
        // }}
      />
      </div>
      </Stack>
      <Stack direction={"column"} spacing={2} padding={1}>
<div>
    <strong>New Details</strong>
</div>
<div
      style={{height:isMobile ? "555px":"140px"}}>
<DataGrid
              className="updateGrid"
              density="compact"
              rows={convertObjectToArrayWithId(JSON.parse(propData.newData))}
              columns={renderUpdateColumns()}
              rowHeight={60}
              disableSelectionOnClick
              disableRowSelectionOnClick
              pageSizeOptions={[10]}
            //   autoPageSize
            />
</div>
      </Stack>
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', margin:"19px",padding:"5px",gap:"5px" }}>

 <Button variant="contained" onClick={()=>rejectUpdate()} >
    Reject
 </Button>
 <Button variant="contained" onClick={()=>approveUpdate()}>
    Approve
 </Button>
 </div>
  </>
  );
};
    
    // console.log("customerdata", loading)
  
  
    const approveUpdate=async()=>{
        try{
            setLoading(true)
            console.log("DATAAAAPPROVE",propData)
            const newData=JSON.parse(propData.newData)
            const finalData={id:propData?.partyApprovalId,partyId:propData?.partyId,...newData,approvalStatus:1
}
            let results = await window.Platform.database.approveRejectMobileSchoolUpdate(finalData);
            setLoading(false)
            onClose()
            fetchData()

        }
        catch(err){
            setLoading(false)

        }
    }
    const rejectUpdate=async()=>{
        try{
            console.log("DATAAAAPPROVE",propData)
            const newData=JSON.parse(propData.newData)
            const finalData={id:propData?.partyApprovalId,approvalStatus:0
}
            let results = await window.Platform.database.approveRejectMobileSchoolUpdate(finalData);

        }
        catch(err){

        }
    }
 
    return (
      <StyledDialog disableEnforceFocus maxWidth="md" fullWidth={true} open={true}PaperProps={{
      style: {
        minHeight: !isMobile&&600,padding:"10px",borderRadius:"10px"
      },
    }}>
    <StyledDialogContent>
        {loading&&<OpaqueLoading/>}
        <ModalHeaderContainer>
          <Typography variant="h5">School Update Request</Typography>

          <IconButton onClick={onClose}>
            <CloseOutlined />
          </IconButton>
        </ModalHeaderContainer>
        {renderContent()}
          </StyledDialogContent>
    </StyledDialog>
    );
  };
  
  export default SchoolDetailsUpdate;
  
// assets
import { LoginOutlined, ProfileOutlined,CompassOutlined,DatabaseOutlined,AuditOutlined,DollarOutlined,LikeOutlined   } from '@ant-design/icons';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import TourIcon from '@mui/icons-material/Tour';
import EditCalendarIcon from '@mui/icons-material/EditCalendar';
import AppRegistrationIcon from '@mui/icons-material/AppRegistration';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import { ReactComponent as userManagement } from '../../icons/school 1.svg';
import { ReactComponent as accessManagement } from '../../icons/lock 1.svg';
import { ReactComponent as attendance } from '../../icons/fingerprint-scan 1.svg';
import { ReactComponent as order } from '../../icons/7409217_bill_cart_ecommerce_icon 1.svg';
import { ReactComponent as set } from '../../icons/8674892_ic_fluent_layer_regular_icon 1.svg';
import { ReactComponent as product } from '../../icons/5-star-product-quality 1.svg';
import { ReactComponent as routePlan } from '../../icons/6811927_direction_gps_location_map_navigation_icon 1.svg';
import { ReactComponent as collection } from '../../icons/budget 1.svg';
import { ReactComponent as specimen } from '../../icons/split-arrow 1.svg';
import { ReactComponent as visit } from '../../icons/Frame 34849.svg';
import { ReactComponent as party } from '../../icons/7239005_basic_add_new_create_plus_icon 1.svg';
import { ReactComponent as lead } from '../../icons/list (1) 1.svg';
import { Notes } from '@mui/icons-material';
import EmojiTransportationIcon from '@mui/icons-material/EmojiTransportation';
import SummarizeOutlinedIcon from '@mui/icons-material/SummarizeOutlined';
import ChecklistRtlIcon from '@mui/icons-material/ChecklistRtl';












// icons
const icons = {
  LoginOutlined,
  ProfileOutlined,
  AuditOutlined,
  DollarOutlined,
  LikeOutlined ,
  DatabaseOutlined ,
  CompassOutlined 
};

// ==============================|| MENU ITEMS - EXTRA PAGES ||============================== //

const pages = {
  id: 'authentication',
  title: 'Modules',
  type: 'group',
  children: [
    {
      id: 'usermanagement',
      title: 'User Management',
      type: 'item',
      url: '/usermanagement',
      icon: userManagement,
      target: true
    },
    {
      id: 'rolemanagement',
      title: 'Role Management',
      type: 'item',
      url: '/rolemanagement',
      icon: accessManagement,
      target: true
    },
    {
      id: 'visitmanagement',
      title: 'Visit Management',
      type: 'item',
      url: '/visitmanagement',
      icon: visit,
      target: true
    },
    {
      id: 'attendancemanagement',
      title: 'Attendance Management',
      type: 'item',
      url: '/attendancemanagement',
      icon: attendance,
      target: true
    },
    {
      id: 'partymanagement',
      title: 'Party Management',
      type: 'item',
      url: '/partymanagement',
      icon: party,
      target: true
    },
    {
      id: 'ordermanagement',
      title: 'Order Management',
      type: 'item',
      url: '/ordermanagement',
      icon: order,
      target: true
    },
    {
      id: 'product',
      title: 'Product Management',
      type: 'item',
      url: '/productManagement',
      icon: product,
      target: true
    },
    {
      id: 'set',
      title: 'Set Management',
      type: 'item',
      url: '/setManagement',
      icon: set,
      target: true
    },
    {
      id: 'route-plan',
      title: 'Route Plan Management',
      type: 'item',
      url: '/routeplanManagement',
      icon: routePlan,
      target: true
    },
    {
      id: 'collection',
      title: 'Collection Management',
      type: 'item',
      url: '/collectionManagement',
      icon: collection,
      target: true
    },
    {
      id: 'specimen',
      title: 'Specimen Management',
      type: 'item',
      url: '/specimenManagement',
      icon: specimen,
      target: true
    },
    {
      id: 'Lead',
      title: 'Lead Management',
      type: 'item',
      url: '/leadManagement',
      icon: lead,
      target: true
    },
    {
      id: 'Transporter',
      title: 'Transporter Management',
      type: 'item',
      url: '/transporterManagement',
      icon: EmojiTransportationIcon,
      target: true
    },
    {
      id: 'Forecast',
      title: 'Forecast Report',
      type: 'item',
      url: '/forecastReport',
      icon: SummarizeOutlinedIcon,
      target: true
    },
    {
      id: 'Financial',
      title: 'Financial Report',
      type: 'item',
      url: '/financialReport',
      icon: SummarizeOutlinedIcon,
      target: true
    },
    {
      id: 'Pipeline',
      title: 'Pipeline Report',
      type: 'item',
      url: '/pipelineReport',
      icon: SummarizeOutlinedIcon,
      target: true
    },
    {
      id: 'returnReason',
      title: 'Return Reason Report',
      type: 'item',
      url: '/returnReasonReport',
      icon: SummarizeOutlinedIcon,
      target: true
    },
    {
      id: 'returnOrderLineItemReport',
      title: 'Return Order Line Item Report',
      type: 'item',
      url: '/returnOrderLineItemReport',
      icon: SummarizeOutlinedIcon,
      target: true
    },
    {
      id: 'Attendance',
      title: 'Attendance Report',
      type: 'item',
      url: '/attendanceReport',
      icon: icons.AuditOutlined,
      target: true
    },
    {
      id: 'Expense',
      title: 'Expense Management',
      type: 'item',
      url: '/expenseManagement',
      icon: icons.DollarOutlined,
      target: true
    },
    
    {
      id: 'register',
      title: 'Register',
      type: 'item',
      url: '/register',
      icon: icons.ProfileOutlined,
      target: true
    },
    {
      id: 'Audit Logs',
      title: 'Audit Logs',
      type: 'item',
      url: '/auditLogs',
      icon: Notes,
      target: true
    },

    {
      id: 'Total Time Worked Report',
      title: 'TotalTimeWorkedReport',
      type: 'item',
      url: '/TotalTimeWorkedReport',
      icon: Notes,
      target: true
    },
    
           {
            id: '/Work Time School Distributor',
            title: 'Work Time School Distributor',
            type: 'item',
            url: '/workTimeSchoolDistributor',
            icon: Notes,
            target: true

            
           },
     { id: '/partyUpdateApproval',
     title: 'Party Update Approval',
     type: 'item',
     url: '/partyUpdateApproval',
     icon: icons.LikeOutlined,
     target: true
      
             
    
               // hideNavBar:true
           },
           {
            id: '/orderTracking',
     title: 'Order Tracking',
     type: 'item',
     url: '/orderTracking',
     icon: icons.CompassOutlined,
     target: true
      
              
           },
           {
            id: '/specimenAllotment',
            title: 'Specimen Allotment',
            type: 'item',
            url: '/specimenAllotment',
            icon: icons.DatabaseOutlined,
            target: true
             
                     
            
           },
           {
            id: '/returnOrder',
            title: 'Returned Order',
            type: 'item',
            url: '/returnOrder',
            icon: Notes,
            target: true
             
    
               // hideNavBar:true
           },
    
    {
      id: 'Picklist',
      title: 'Picklist',
      type: 'item',
      url: '/picklist',
      icon: ChecklistRtlIcon,
      target: true
    }
  ]
};

export default pages;

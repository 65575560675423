import React, { useEffect, useState } from "react";
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogContent,
  FormControl,
  FormControlLabel,
  FormGroup,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { CloseOutlined } from "@mui/icons-material";
import { Stack } from "@mui/material";
import styled from "styled-components";
import { ACTIVITY_STATUS } from "../../constants";
import OpaqueLoading from "../opaqueLoading/opaqueLoading";
import { DataGrid,getGridStringOperators } from "@mui/x-data-grid";

const StyledDialogContent = styled(DialogContent)`
  padding: 16px;
`;
const ModalActionsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const StyledDialog = styled(Dialog)`
  // position: relative;
  // max-width: 700px;
  
`;  

  const ModalHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
    `;
  const isMobile=window.innerWidth<900
  
  const DistributorDetailsUpdate = ({ onClose ,propData,fetchData }) => {
   const [data,setData]=useState([])
   const [loading,setLoading]=useState(false)
console.log("DATAAUTH", JSON.parse(propData.newData))
useEffect(()=>{
    getDistributorByID({id:propData.partyId})
},[])

const getDistributorByID=async(data)=>{
    try{
        setLoading(true)
        let results = await window.Platform.database.getDistributorByID(data);
      const jsonArrayWithId = results?.data?.map((obj, index) => ({ ...obj, id: index + 1 }));

        setData(jsonArrayWithId)
        setLoading(false)

    }
    catch(error){
        setLoading(false)

        console.log(error)
    }
}
const formatTimeStamp = (timestampFromDB) => {
    const options = {
      timeZone: 'Asia/Kolkata',
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,  // Use 12-hour clock
    };
  
    const formattedDate = new Date(timestampFromDB).toLocaleString('en-US', options);
  
    return formattedDate;
  };



const distributorColumn =()=>{
    const stringOperators = getGridStringOperators().filter((op => ['contains'].includes(op.value)));

    let result= [

    {

        field: "DistributorName",

        headerName: "Name",

        minWidth: 200,

        valueGetter: (params) => {

            let name = params.row.DistributorName

            return name;

        },

        flex: 0.22,

        editable: false,
        filterOperators: stringOperators 
,

        renderCell: (params) => {

            let name = params.row.DistributorName

            return (

                <Tooltip title={name || "undefined"}>

                    <Typography variant="inherit">{name}</Typography>

                </Tooltip>

            )

        },

    },

    {

        field: "GSTno",

        headerName: "GSTno",

        minWidth: 250,

        flex: 0.35,

        editable: false,
        filterable: false,


        valueGetter: (params) => {

            return params.row.GSTno || ""

        },

        renderCell: (params) => {

            return (

                <Tooltip title={params.row.GSTno || "undefined"}>

                    <Typography variant="inherit">{params.row.GSTno}</Typography>

                </Tooltip>

            )

        },

    },



    {

        field: "name",

        headerName: "Created By",

        minWidth: 200,

        flex: 0.22,

        editable: false,
        filterOperators: stringOperators 
        ,

        renderCell: (params) => {

            return (

                <Tooltip title={params.row.name || "undefined"}>

                    <Typography variant="inherit">{params.row.name}</Typography>

                </Tooltip>

            )

        },

    },  {
        field: "createdAt",
        headerName: "Created At",
        width: 200,
        // height: 10,
        // headerAlign: 'center',
        sortable: false,
        filterable: false,
  
        // editable: true,
        renderCell: (params) => {
  
          let name =formatTimeStamp( params.row.createdAt)||"N/A"
  
          return (
  
            <Tooltip title={name || "N/A"}>
  
              <Typography variant="inherit">{name}</Typography>
  
            </Tooltip>
  
          )
  
        },
      },
      {
          field: "updatedAt",
          headerName: "Updated At",
          width: 200,
          // height: 10,
          // headerAlign: 'center',
          sortable: false,      filterable: false,
  
          // editable: true,
          renderCell: (params) => {
    
            let name =formatTimeStamp( params.row.updatedAt)||"N/A"
    
            return (
    
              <Tooltip title={name || "N/A"}>
    
                <Typography variant="inherit">{name}</Typography>
    
              </Tooltip>
    
            )
    
          },
        },



    {

        field: "makerName",

        headerName: "Contact Person Name",

        minWidth: 250,

        flex: 0.35,

        editable: false,
        filterable: false,


        valueGetter: (params) => {

            let name = params.row.makerName

            if (name == null || (name.length == 0)) name = "N/A"

            return name;

        },

        renderCell: (params) => {

            let name = params.row.makerName

            if (name == null || (name.length == 0)) name = "N/A"

            return (

                <Tooltip title={name || "undefined"}>

                    <Typography variant="inherit">{name}</Typography>

                </Tooltip>

            )

        },

    },

    {

        field: "makerContact",

        headerName: "Contact Person Contact",

        minWidth: 250,

        flex: 0.35,

        editable: false,
        filterable: false,

        valueGetter: (params) => {

            // console.log(moment.unix(params.row.makerContact).format("MM/DD/YYYY"))

            let name = params.row.makerContact




            return name;

        },

        renderCell: (params) => {

            let name = params.row.makerContact



            return (

                <Tooltip title={name || "undefined"}>

                    <Typography variant="inherit">{name}</Typography>

                </Tooltip>

            )

        },



    },
    {

        field: "State",

        headerName: "State",

        minWidth: 250,

        flex: 0.35,

        editable: false,
        filterOperators: stringOperators ,


        valueGetter: (params) => {

            // console.log(moment.unix(params.row.State).format("MM/DD/YYYY"))

            let name = params.row.State





            return name;

        },

        renderCell: (params) => {

            let name = params.row.State



            return (

                <Tooltip title={name || "undefined"}>

                    <Typography variant="inherit">{name}</Typography>

                </Tooltip>

            )

        },


    },

    {

        field: "District",

        headerName: "District",

        minWidth: 250,

        flex: 0.35,

        editable: false,
        filterOperators: stringOperators 
        ,

        valueGetter: (params) => {

            // console.log(moment.unix(params.row.District).format("MM/DD/YYYY"))

            let name = params.row.District





            return name;

        },

        renderCell: (params) => {

            let name = params.row.District



            return (

                <Tooltip title={name || "undefined"}>

                    <Typography variant="inherit">{name}</Typography>

                </Tooltip>

            )

        },


    },
    {

        field: "Address",

        headerName: "Address",

        minWidth: 250,

        flex: 0.35,
        filterable: false,

        editable: false,

        valueGetter: (params) => {

            // console.log(moment.unix(params.row.State).format("MM/DD/YYYY"))

            let name = params.row.AddressLine1 + " " + params.row.AddressLine2





            return name;

        },

        renderCell: (params) => {

            let name = params.row.AddressLine1 + " " + params.row.AddressLine2



            return (

                <Tooltip title={name || "undefined"}>

                    <Typography variant="inherit">{name}</Typography>

                </Tooltip>

            )

        },


    },

    {

        field: "Pincode",

        headerName: "Pincode",

        minWidth: 250,

        flex: 0.35,

        editable: false,
        filterable: false,


        valueGetter: (params) => {

            // console.log(moment.unix(params.row.State).format("MM/DD/YYYY"))

            let name = params.row.Pincode





            return name;

        },

        renderCell: (params) => {

            let name = params.row.Pincode



            return (

                <Tooltip title={name || "undefined"}>

                    <Typography variant="inherit">{name}</Typography>

                </Tooltip>

            )

        },


    },


];
return result
}


const convertObjectToArrayWithId = (dataObject) => {
    const dataArray = [{...dataObject,id:1}]
    return dataArray
  };
  
  const renderUpdateColumns = () => {
    const data=JSON.parse(propData.newData);
    const columns = Object.keys(data).map((property) => ({
      field: property.toString(),
      headerName: property.toString(),
      flex: 1,
    }));
  
    console.log("ARRAY CONVERSION", columns);
    return columns;
  };
  
const renderContent = () => {
  return (
    // <Box sx={{ height: "100%", width: "100%" }}>
    <><Stack direction={"column"} spacing={2} padding={1}>
        <div>
            <strong>Old Details</strong>
        </div>
        <div 
      style={{height:isMobile ? "555px":"152px"}}
      >
      <DataGrid
        className="payrollGrid"
      //   checkboxSelection={true}
      density="compact"
        rows={data||[]}
        columns={distributorColumn()}
        rowHeight={70}
        disableSelectionOnClick
        disableRowSelectionOnClick
        autoPageSize
        // components={{
        //   Toolbar: CustomToolbar,
        // }}
      />
      </div>
      </Stack>
      <Stack direction={"column"} spacing={2} padding={1}>
<div>
    <strong>New Details</strong>
</div>
<div
      style={{height:isMobile ? "555px":"152px"}}>
<DataGrid
              className="updateGrid"
              density="compact"
              rows={convertObjectToArrayWithId(JSON.parse(propData.newData))}
              columns={renderUpdateColumns()}
              rowHeight={70}
              disableSelectionOnClick
              disableRowSelectionOnClick
              autoPageSize
              pageSizeOptions={[10]}
            />
</div>
      </Stack>
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', margin:"19px",padding:"5px",gap:"5px" }}>

 <Button variant="contained" onClick={()=>rejectUpdate()} >
    Reject
 </Button>
 <Button variant="contained" onClick={()=>approveUpdate()}>
    Approve
 </Button>
 </div>
  </>
  );
};
    
    // console.log("customerdata", loading)
  
  
    const approveUpdate=async()=>{
        try{
            setLoading(true)
            console.log("DATAAAAPPROVE",propData)
            const newData=JSON.parse(propData.newData)
            const finalData={id:propData?.partyApprovalId,partyId:propData?.partyId,...newData,approvalStatus:1}
            let results = await window.Platform.database.approveRejectMobileDistributorUpdate(finalData);
            setLoading(false)
            onClose()
            fetchData()

        }
        catch(err){
            console.log(err)
            setLoading(false)

        }
    }
    const rejectUpdate=async()=>{
        try{
            setLoading(true)

            console.log("DATAAAAPPROVE",propData)
            const newData=JSON.parse(propData.newData)
            const finalData={id:propData?.partyApprovalId,approvalStatus:0
}
            let results = await window.Platform.database.approveRejectMobileDistributorUpdate(finalData);
            setLoading(false)
            onClose()

        }
        catch(err){
            setLoading(false)

        }
    }
 
    return (
      <StyledDialog disableEnforceFocus maxWidth="md" fullWidth={true} open={true}PaperProps={{
      style: {
        minHeight: !isMobile&&600,padding:"10px",borderRadius:"10px"
      },
    }}>
    <StyledDialogContent>
        {loading&&<OpaqueLoading/>}
        <ModalHeaderContainer>
          <Typography variant="h5">Distributor Update Request</Typography>

          <IconButton onClick={onClose}>
            <CloseOutlined />
          </IconButton>
        </ModalHeaderContainer>
        {renderContent()}
          </StyledDialogContent>
    </StyledDialog>
    );
  };
  
  export default DistributorDetailsUpdate;
  
import react, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import {
  DataGrid,
  GridColDef,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton,
  GridValueGetterParams,
  getGridStringOperators,
} from "@mui/x-data-grid";
import styled from "styled-components";
import Drawer from "../../components/common/drawer/drawer";
import { Typography, Button, Checkbox, Fab, IconButton, Stack, Tab, Tabs, CircularProgress, Link } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import { ERROR_COLOR } from "../../constants";
import { Add, Save, SaveAlt } from "@mui/icons-material";
import OrderLineItemModal from "../../components/modals/orderLineItemModal";
import OpaqueLoading from "../../components/opaqueLoading/opaqueLoading";
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import AddOrderModal from "../../components/modals/addOrder";
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import AddOrderModalDistributor from "../../components/modals/addOrderDistributor";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import CommonOtpModal from "../../components/modals/commonOtpModal";
import RepartitionIcon from '@mui/icons-material/Repartition';
import ReturnOrderModal from "../../components/modals/returnOrderModal";
import { useNavigate } from "react-router-dom";
import PreviewIcon from '@mui/icons-material/Preview';

const isMobile = window.innerWidth < 900;

const StaffEditorPageContainer = styled.div`
  height: ${isMobile ? `auto` : `calc(100vh - 105px)`};
  width: 100%;
  background-color: white;
  padding: 10px 15px;
  ${isMobile &&
    `
      position: relative;
  
      `}
`;

const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: ${isMobile ? "flex-start" : "space-between"};
  margin-bottom: 10px;
  flex-direction: ${isMobile ? "column" : "row"};
`;

const HeaderButtonsContainer = styled.div`
  ${isMobile && "margin-top:10px;"}
`;

const TableContainer = styled.div`
// height: calc(
//   100vh - ${isMobile ? "56px - 20px - 82.23px" : "128px - 28px - 51.77px"}
// );
  width: 100%;
  border: solid 1px lightGrey;
  border-radius: 8px;
`;
const AvatarContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const DataGridContainer = styled.div`
  max-width: 100%; /* Add this line to limit the width */
  margin: 0 auto; /* Center the content horizontally */
  height: 100%;
  width: 100%;
  // border: solid 1px lightGrey;
  border-radius: 8px;
  display: ${isMobile ? 'flex' : 'grid'};

  ${isMobile &&
  `
    flex-direction: column;
  `}
`;

const LoadingContainer = styled.div`
  height: calc(100% - 49px);
  display: flex;
  justify-content: center;
  align-items: center;
`;

const TabsContainer = styled.div`
  ${isMobile &&
  `
    height: calc(100vh - 56px - 20px);
  `}
`;

const GenerateReportButton = styled(Button)`
  margin-right: 10px !important;
`;
const BulkChangePinCodeButton = styled(Button)`
  margin-right: 10px !important;
`;

const PinCodeChangeButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const FabStyle = styled(Fab)`
  && {
    z-index: 1};
  }
`;

const StyledHeader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledTabs = styled(Tabs)`

  border-bottom: solid 1px lightGrey;

`;

export default function OrderManagement() {
  const navigation = useNavigate()

  const label = { inputProps: { "aria-label": "Checkbox demo" } };

  const [loading, setLoading] = useState(false);
  const [accessChanges, setAccessChanges] = useState([]);
  const [showWarehouseFormModal, setShowWarehouseFormModal] = useState();
  const [modal,setModal]=useState(false)
  const [modalData,setModalData]=useState()
  const [addModal,setAddModal] = useState(false)
  const [addModal1,setAddModal1] = useState(false)

  const [editModal,setEditModal] = useState(false)
  const [editModal1,setEditModal1] = useState(false)

  const [editModalData,setEditModalData] = useState(false)
  const [rowCount,setRowCount] = useState()
const [currentPage, setCurrentPage] = useState(1);
const [paginationModel, setPaginationModel] = useState({
  page: currentPage - 1,
  pageSize: 11,
});

const [currentPage1, setCurrentPage1] = useState(1);
const [paginationModel1, setPaginationModel1] = useState({
  page: currentPage1 - 1,
  pageSize: 11,
});

const [selectedTab, setSelectedTab] = useState(0);
const [filter,setFilter]=useState(false)
const [filter1,setFilter1]=useState(false)

const [searchTerm, setSearchTerm]=useState(null)
const [searchTerm1, setSearchTerm1]=useState(null)
const [startDate,setStartDate]=useState()
const [endDate,setEndDate]=useState()
const [dateRange1,setDateRange1]=useState(false)

const [startDate1,setStartDate1]=useState()
const [endDate1,setEndDate1]=useState()
const [minDate,setMinDate]=useState()
const [otpModalData,setOtpModalData]=useState()
const [otpModal1,setOtpModal1]=useState(false)
const [otpModalData1,setOtpModalData1]=useState()
const [otpModal2,setOtpModal2]=useState(false)
const [returnData,setReturnData]=useState()
const [returnItems,setReturnItems]=useState(false)


const handleTabChange = async (value) => {

  setStartDate(null)
  setEndDate(null)
  setStartDate1(null)
  setEndDate1(null)
  setDateRange1(false)
  setFilter(false)
  setFilter1(false)


  setSelectedTab(value);


}

  const formatDateTime=(inputDateString)=> {
    const inputDate = new Date(inputDateString);

  // Check if the input date is valid
  if (isNaN(inputDate.getTime())) {
    return 'Invalid Date';
  }

  // Extract date and time components
  const year = inputDate.getUTCFullYear();
  const month = String(inputDate.getUTCMonth() + 1).padStart(2, '0');
  const day = String(inputDate.getUTCDate()).padStart(2, '0');
  const hours = String(inputDate.getUTCHours()).padStart(2, '0');
  const minutes = String(inputDate.getUTCMinutes()).padStart(2, '0');
  const seconds = String(inputDate.getUTCSeconds()).padStart(2, '0');

  // Format the date string without 'T' and 'Z'
  const formattedDateString = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;

  return formattedDateString;

  }
  

 

  useEffect(() => {
    // setAccessChanges(rows);
    if(selectedTab===0 && !filter){
    fetchOrder()
    }
  }, [paginationModel.page,selectedTab]);
  useEffect(() => {
    // setAccessChanges(rows);
    if(selectedTab===0 && filter){
    onFilterChange(searchTerm)
    }
  }, [paginationModel.page,selectedTab]);

  useEffect(() => {
    if(selectedTab===1&& !filter1){
    fetchOrder1()
    }
  }, [paginationModel1.page,selectedTab]);
  useEffect(() => {
    if(selectedTab===1 && filter1){
      onFilterChange1(searchTerm1)
    }
  }, [paginationModel1.page,selectedTab]);


  const fetchOrder= async () => {
    try {
      setLoading(true);
    
      let results = await window.Platform.database.fetchOrder({pageNumber:paginationModel.page});
      console.log("results", results);
      // if(auth?.user?.role === ROLES.ADMIN){
      const jsonArrayWithId = results?.data?.map((obj, index) => ({ ...obj, id: index + 1 }));
setRowCount(results.data1)
        setAccessChanges(jsonArrayWithId);
      // }
      // setVisibleEmployees(results);
      // setLoading(false);
    } catch (error) {
      console.error(error);
      window.NotificationUtils.showError("Error Fetching Data");
      // setLoading(false);
    }
    finally{
      setLoading(false)
    }
  }

  const fetchOrder1= async () => {
    try {
      setLoading(true);
    
      let results = await window.Platform.database.fetchOrder({pageNumber:paginationModel.page,orderStatus:1});
      console.log("results", results);
      // if(auth?.user?.role === ROLES.ADMIN){
      const jsonArrayWithId = results?.data?.map((obj, index) => ({ ...obj, id: index + 1 }));
setRowCount(results.data1)
        setAccessChanges(jsonArrayWithId);
      // }
      // setVisibleEmployees(results);
      // setLoading(false);
    } catch (error) {
      console.error(error);
      window.NotificationUtils.showError("Error Fetching Data");
      // setLoading(false);
    }
    finally{
      setLoading(false)
    }
  }

  const getEmployeeColumns = () => {
    const stringOperators = getGridStringOperators().filter((op => ['contains'].includes(op.value)));
  let result = [ {
    field: "approvalStatus",
    headerName: "Approval Status",
    width: 200,
    filterable: false,

    // height: 10,
    // headerAlign: 'center',
    sortable: false,
    // editable: true,
    renderCell: (params) => {

      let name = params.row?.approvalStatus===0?"Pending": params.row?.approvalStatus===1?"Approved":"Rejected"||"N/A"

      return (

        <Tooltip title={name || "N/A"}>

          <Typography variant="inherit">{name}</Typography>

        </Tooltip>

      )

    },
  },
    {
      field: "name",
      headerName: "Assigned To",
      sortable: false,
      width: 150,
      
      editable: false,
      filterOperators: stringOperators 
,
      renderCell: (params) => {

        let name = params.row?.name||"N/A"

        return (

          <Tooltip title={name || "N/A"}>

            <Typography variant="inherit">{name}</Typography>

          </Tooltip>

        )

      },
    },
    {
      field: "createdAt",
      headerName: "Created At",
      width: 250,
      // height: 10,
      // headerAlign: 'center',
      sortable: false,
      filterable: false,

      // editable: true,
      renderCell: (params) => {

        let name =formatTimeStamp( params.row.createdAt)||"N/A"

        return (

          <Tooltip title={name || "N/A"}>

            <Typography variant="inherit">{name}</Typography>

          </Tooltip>

        )

      },
    },
    {
      field: "orderType",
      headerName: "Order Type",
      width: 180,
      filterOperators: stringOperators 
,
      // height: 10,
      // headerAlign: 'center',
      sortable: false,
      // editable: true,
      renderCell: (params) => {

        let name = params.row?.orderType||"N/A"

        return (

          <Tooltip title={name || "N/A"}>

            <Typography variant="inherit">{name}</Typography>

          </Tooltip>

        )

      },
    },
 
      {
        field: "schoolName",
        headerName: "Party Name",
        width: 350,
        filterOperators: stringOperators 
,
        // height: 10,
        // headerAlign: 'center',
        sortable: false,
        // editable: true,
        renderCell: (params) => {
  
          let name = params.row?.schoolName?params.row?.schoolName:params.row?.DistributorName||"N/A"
  
          return (
  
            <Tooltip title={name || "N/A"}>
  
              <Typography variant="inherit">{name}</Typography>
  
            </Tooltip>
  
          )
  
        },
      },
      {
        field: "totalAmount",
        headerName: "Total Amount",
        width: 180,
        filterable: false,

        // height: 10,
        // headerAlign: 'center',
        sortable: false,
        // editable: true,
        renderCell: (params) => {
  
          const name1 = +params.row?.totalAmount||"N/A"
  
          let name=name1.toLocaleString('en-IN');
          return (
  
            <Tooltip title={name || "N/A"}>
  
              <Typography variant="inherit">{name}</Typography>
  
            </Tooltip>
  
          )
  
        },
      },
  
      {
        field: "emailId",
        headerName: "Party Email Id",
        width: 250,
        filterable: false,

        // height: 10,
        // headerAlign: 'center',
        sortable: false,
        // editable: true,
        renderCell: (params) => {
  
          let name = params.row.emailId||"N/A"
  
          return (
  
            <Tooltip title={name || "N/A"}>
  
              <Typography variant="inherit">{name}</Typography>
  
            </Tooltip>
  
          )
  
        },
      },
    
     
      {
        field: "mobileNo",
        headerName: "Party Mobile No",
        width: 200,
        filterable: false,

        // height: 10,
        // headerAlign: 'center',
        sortable: false,
        // editable: true,
        renderCell: (params) => {
  
          let name = params.row.mobileNo||"N/A"
  
          return (
  
            <Tooltip title={name || "N/A"}>
  
              <Typography variant="inherit">{name}</Typography>
  
            </Tooltip>
  
          )
  
        },
      },
    
      {
        field: "Discount",
        headerName: "Discount(in %)",
        width: 150,
        filterable: false,

        // height: 10,
        // headerAlign: 'center',
        sortable: false,
        // editable: true,
        renderCell: (params) => {
  
          let name = params.row?.Discount||"N/A"
  
          return (
  
            <Tooltip title={name || "N/A"}>
  
              <Typography variant="inherit">{name}</Typography>
  
            </Tooltip>
  
          )
  
        },
      },
      {
        field: "Transport",
        headerName: "Transporter Name",
        width: 200,
        filterable: false,

        // height: 10,
        // headerAlign: 'center',
        sortable: false,
        // editable: true,
        renderCell: (params) => {
  
          let name = params.row?.transporter_name||"N/A"
  
          return (
  
            <Tooltip title={name || "N/A"}>
  
              <Typography variant="inherit">{name}</Typography>
  
            </Tooltip>
  
          )
  
        },
      },
     
      {
        field: "approvedBy",
        headerName: "Approval By",
        width: 200,
        filterable: false,

        // height: 10,
        // headerAlign: 'center',
        sortable: false,
        // editable: true,
        renderCell: (params) => {
  
          let name = params.row?.approvedBy||"N/A"
  
          return (
  
            <Tooltip title={name || "N/A"}>
  
              <Typography variant="inherit">{name}</Typography>
  
            </Tooltip>
  
          )
  
        },
      },
      
      {
        field: "Approve Order",
        headerName: "Actions",
        width: 250,
        filterable: false,
// align:"center",
// headerStyle: { textAlign: 'right' },
        // height: 10,
        headerAlign: 'center',
        sortable: false,
        // editable: true,
        renderCell: (params) => {
  
          let value =params.row?.orderId
          let ownerId=params.row?.ownerId
          let allValues=params.row
          
          return (
  <Stack direction={"row"} spacing={1}>
        <Tooltip title="Click to view order items"><IconButton variant="extended" color="primary" onClick={(e) => {
              e.stopPropagation();
              fetchOrderLineItem(value);}} >
             <RemoveRedEyeIcon/>
            </IconButton></Tooltip>

            <Tooltip title="Click to return order items"><IconButton variant="extended" color="primary" onClick={(e) => {
              e.stopPropagation();
              returnOrder(allValues);}} >
             <RepartitionIcon/>
            </IconButton></Tooltip>
         
      <Tooltip title="Click to edit order"><IconButton variant="extended"
            // disabled={params.row?.approvalStatus===1||params.row?.approvalStatus===2}
            onClick={(e) => {
              e.stopPropagation();
              editData(allValues);}} >
             <DriveFileRenameOutlineIcon/>
            </IconButton></Tooltip>
            <Tooltip title="Click to approve order"><IconButton disabled={params.row?.approvalStatus===1||params.row?.approvalStatus===2} variant="extended" sx={{color:"#008000"}}  onClick={(e) => {
              e.stopPropagation();
              approveRejectOrder({id:value,approvalStatus:"1",ownerId});}} >
             <ThumbUpIcon/>
            </IconButton></Tooltip>
            <Tooltip title="Click to reject order"><IconButton disabled={params.row?.approvalStatus===1||params.row?.approvalStatus===2} variant="extended" sx={{color:"red"}} onClick={(e) => {
              e.stopPropagation();
              approveRejectOrder({id:value,approvalStatus:"2",ownerId});}} >
             <ThumbDownIcon/>
            </IconButton></Tooltip>
            </Stack>
          )
  
        },
      },
 
  ];


  return result
}


const returnOrder=async(data)=>{
  try{
    setLoading(true)
    let sentData={id:data.orderId}
    let results = await window.Platform.database.fetchOrderLineItem(sentData);
    const jsonArrayWithId = results?.data?.map((obj, index) => ({ ...obj, id: index + 1 }));
    setReturnData({product:jsonArrayWithId,order:data})
    setReturnItems(true)
    setLoading(false)

  }
  catch(error){
    console.log(error)
    setLoading(false)

  }
}

const editData=(data)=>{
console.log("EDITDATA",data)

setEditModalData(data)
if(selectedTab===0){
setEditModal(true)
}
else{
  setEditModal1(true)
}
}


const approveRejectOrder=async(val)=>{
    console.log("VALuesss",val)
try{
    setLoading(true)
  const results=await window.Platform.database.approverejectOrder(val)
    window.NotificationUtils.showSuccess("Order Update Success")
    setLoading(false)
    if(selectedTab===0){
    fetchOrder()
    }else{
    fetchOrder1()

    }

}
catch(err){
console.log(err)
window.NotificationUtils.showError("Something Went Wrong!")
setLoading(false)

}
}

const formatTimeStamp = (timestampFromDB) => {
  const options = {
    timeZone: 'Asia/Kolkata',
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,  // Use 12-hour clock
  };

  const formattedDate = new Date(timestampFromDB).toLocaleString('en-US', options);

  return formattedDate;
};

const fetchOrderLineItem=async(val)=>{
try{
    setLoading(true)
    setModalData()
let sentData={id:val}
    let results = await window.Platform.database.fetchOrderLineItem(sentData);
    const jsonArrayWithId = results?.data?.map((obj, index) => ({ ...obj, id: index + 1 }));

setModalData({data:jsonArrayWithId,id:val})
setModal(true)
    setLoading(false)

}
catch(error){
    console.log(error)
    window.NotificationUtils.showError("Something Went Wrong!")
    setLoading(false)



}



}







  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
        <GridToolbarExport
          csvOptions={{
            fileName: "export-order",
          }}
        />
      </GridToolbarContainer>
    );
  }


  const onFilterChange= async(filterModel) => {

    
    try{  
      setLoading(true);
      setFilter(true)
      console.log(filterModel,'filterModel')
      if(filterModel){
      const response = await window.Platform.database.filterOrder({filterField:filterModel.field,filterValue:filterModel.value,pageNumber:paginationModel.page,startDate:startDate,endDate:endDate})
      console.log("respponse",response);
      const jsonArrayWithId = response?.data?.map((obj, index) => ({ ...obj, id: index + 1 }));
      setAccessChanges(jsonArrayWithId)
      setRowCount(response.data1 )
      }
      else{
        const response = await window.Platform.database.filterOrder({filterField:"date",pageNumber:paginationModel.page,startDate:startDate,endDate:endDate})
        console.log("respponse",response);
        const jsonArrayWithId = response?.data?.map((obj, index) => ({ ...obj, id: index + 1 }));
        setAccessChanges(jsonArrayWithId)
        setRowCount(response.data1 )
       
      }
      
      setLoading(false);
    }
    catch(err){
  console.error(err)
  setLoading(false);
  window.NotificationUtils.showError("Error While Recieving Data Please Wait and try again");  
    }
    
  };

  const onFilterChange1= async(filterModel) => {

    
    try{  
      setLoading(true);
      setFilter1(true)
      console.log(filterModel,'filterModel')
      if(filterModel){
      const response = await window.Platform.database.filterOrderDistributor({filterField:filterModel.field,filterValue:filterModel.value,pageNumber:paginationModel1.page,startDate:startDate,endDate:endDate})
      console.log("respponse",response);
      const jsonArrayWithId = response?.data?.map((obj, index) => ({ ...obj, id: index + 1 }));
      setAccessChanges(jsonArrayWithId)
      setRowCount(response.data1 )
      }
      else{
        const response = await window.Platform.database.filterOrderDistributor({filterField:"date",pageNumber:paginationModel1.page,startDate:startDate,endDate:endDate})
        console.log("respponse",response);
        const jsonArrayWithId = response?.data?.map((obj, index) => ({ ...obj, id: index + 1 }));
        setAccessChanges(jsonArrayWithId)
        setRowCount(response.data1 )
       
      }
      
      setLoading(false);
    }
    catch(err){
  console.error(err)
  setLoading(false);
  window.NotificationUtils.showError("Error While Recieving Data Please Wait and try again");  
    }
    
  };



  const renderContent = () => {
  //   if (loading) {

  //     return renderLoading();

  // }
    if(selectedTab===0){
    return (
      <DataGridContainer style={{ height: !accessChanges?.length ? '200px' : 'auto' }}>
        <DataGrid
          className="payrollGrid"
        //   checkboxSelection={true}
        density="compact"
          rows={accessChanges}
          columns={getEmployeeColumns()}
          disableSelectionOnClick
          disableRowSelectionOnClick
          rowHeight={60}
          disableDensitySelector
          pageSizeOptions={[10]}
          onFilterModelChange={(val)=>
              {
          //       console.log("vvvv",val);
            
            
            
            if(val?.items[0]?.value?.length>0){
              onFilterChange(val.items[0])
              setSearchTerm(val.items[0])
            
            
  
            }
            else if(!val.items[0]?.value   && startDate && endDate){
              setSearchTerm(null)
              onFilterChange()
              console.log("CHECKING...1")

            }
            else{
              fetchOrder()
              setSearchTerm(null)
              setFilter(false)

            }
          //   else if(!val.items?.value && !endDate && !startDate && !territoryFilter){
          //     setSearchTerm(null)
          //     fetchData()
          //     console.log("CHECK1")
          //   }
          //   else if(!val.items?.value && endDate && startDate && territoryFilter){
          //     setSearchTerm(null)
  
          //     onFilterChange1()
          //     console.log("checkterr")
          //   }
          //   else if(!val.items?.value && endDate && startDate && !territoryFilter){
          //     setSearchTerm(null)
          //     console.log("check2")
          //     onFilterChange1()
  
  
          //   }
          //   else{
          //     setSearchTerm(null)
          //     console.log("check2")
          //     onFilterChange1()
          //   }
          }
            
          }
          filterMode="server"

rowCount={rowCount}
          
          paginationModel={paginationModel}
          paginationMode="server"
          onPaginationModelChange={setPaginationModel}
          
          components={{
            Toolbar: CustomToolbar,
          }}
        />
      </DataGridContainer>
    );
        }
        else if(selectedTab===1){
          return (
            <DataGridContainer style={{ height: !accessChanges?.length ? '200px' : 'auto' }}>
              <DataGrid
                className="payrollGrid"
              //   checkboxSelection={true}
              density="compact"
                rows={accessChanges}
                columns={getEmployeeColumns()}
                disableSelectionOnClick
                disableRowSelectionOnClick
                rowHeight={60}
                pageSizeOptions={[10]}
                disableDensitySelector
                onFilterModelChange={(val)=>
                    {
                //       console.log("vvvv",val);
                  
                  
                  
                  if(val?.items[0]?.value?.length>0){
                    onFilterChange1(val.items[0])
                    setSearchTerm1(val.items[0])
                  }
                  else if(!val.items[0]?.value   && startDate && endDate){
                    setSearchTerm1(null)
                    onFilterChange1()
                    console.log("CHECKING...1")
      
                  }
                  else{
                    fetchOrder1()
                    setSearchTerm1(null)
                    setFilter1(false)
                  }
                //   else if(!val.items?.value && !endDate && !startDate && !territoryFilter){
                //     setSearchTerm(null)
                //     fetchData()
                //     console.log("CHECK1")
                //   }
                //   else if(!val.items?.value && endDate && startDate && territoryFilter){
                //     setSearchTerm(null)
        
                //     onFilterChange1()
                //     console.log("checkterr")
                //   }
                //   else if(!val.items?.value && endDate && startDate && !territoryFilter){
                //     setSearchTerm(null)
                //     console.log("check2")
                //     onFilterChange1()
        
        
                //   }
                //   else{
                //     setSearchTerm(null)
                //     console.log("check2")
                //     onFilterChange1()
                //   }
                }
                  
                }
                filterMode="server"
      
      rowCount={rowCount}
                
                paginationModel={paginationModel1}
                paginationMode="server"
                onPaginationModelChange={setPaginationModel1}
                
                components={{
                  Toolbar: CustomToolbar,
                }}
              />
            </DataGridContainer>
          );

        }
  };

  const formatDate=async(data)=>{
    setMinDate(data)
    setDateRange1(true)
    setEndDate1(null)
    setEndDate(null)
    const datePickerResponse = new Date(data.$d);
  
  const year = datePickerResponse.getFullYear();
  const month = String(datePickerResponse.getMonth() + 1).padStart(2, '0');
  const day = String(datePickerResponse.getDate()).padStart(2, '0');
  const formattedDate = `${year}-${month}-${day}`;
  
  setStartDate1(formattedDate)
  setStartDate(formattedDate)
  console.log("CHECK!!")
  console.log("CHECKENDDATE",endDate)
  
  }
  const finalDateRangeFilter=async(data)=>{
    try{
      setLoading(true)
      setFilter(true)
  
    const datePickerResponse = new Date(data);
  
    const year = datePickerResponse.getFullYear();
    const month = String(datePickerResponse.getMonth() + 1).padStart(2, '0');
    const day = String(datePickerResponse.getDate()).padStart(2, '0');
  const formattedDate = `${year}-${month}-${day}`;
    
    if (data){
    setEndDate(formattedDate)
    setEndDate1(formattedDate)
  }
      if(searchTerm||searchTerm1){
        if(selectedTab===0&&searchTerm){
      const response = await window.Platform.database.filterOrder({filterField:searchTerm.field,filterValue:searchTerm?.value,pageNumber:paginationModel.page,startDate:startDate,endDate:data?formattedDate:endDate })
      const jsonArrayWithId = response?.data?.map((obj, index) => ({ ...obj, id: index + 1 }));
      setAccessChanges(jsonArrayWithId)
      setRowCount(response.data1 )
        }
        else if(selectedTab===1&&searchTerm1){
            const response = await window.Platform.database.filterOrderDistributor({filterField:searchTerm1.field,filterValue:searchTerm1?.value,pageNumber:paginationModel1.page,startDate:startDate,endDate:data?formattedDate:endDate })
            const jsonArrayWithId = response?.data?.map((obj, index) => ({ ...obj, id: index + 1 }));
            setAccessChanges(jsonArrayWithId)
            setRowCount(response.data1 )
        }
    }
      else{
        if(selectedTab===0){
      const response = await window.Platform.database.filterOrder({filterField:"date",filterValue:searchTerm?.value,pageNumber:paginationModel.page,startDate:startDate,endDate:data?formattedDate:endDate })
      const jsonArrayWithId = response?.data?.map((obj, index) => ({ ...obj, id: index + 1 }));
      setAccessChanges(jsonArrayWithId)
      setRowCount(response.data1 )
        }
        else{
            const response = await window.Platform.database.filterOrderDistributor({filterField:"date",filterValue:searchTerm1?.value,pageNumber:paginationModel1.page,startDate:startDate,endDate:data?formattedDate:endDate })
      const jsonArrayWithId = response?.data?.map((obj, index) => ({ ...obj, id: index + 1 }));
      setAccessChanges(jsonArrayWithId)
      setRowCount(response.data1 )
        }
      }
      setLoading(false)
  
    }
    catch(err){
      console.log(err)
      setLoading(false)
      window.NotificationUtils.showError("Error While Recieving Data Please Wait and try again");
      if(selectedTab===0){
        fetchOrder()
        
      }
      else{
        fetchOrder1()
      }
    }
  }
  const clearDateFilter=async()=>{
    setStartDate(null)
    setEndDate(null)
    setStartDate1(null)
    setEndDate1(null)
    setDateRange1(false)
    if(searchTerm){
      try{
      setLoading(true)
      if(selectedTab===0){
      const response = await window.Platform.database.filterOrder({filterField:searchTerm?searchTerm.field:"date",filterValue:searchTerm?.value,pageNumber:paginationModel.page,startDate:'',endDate:'' })
      const jsonArrayWithId = response?.data?.map((obj, index) => ({ ...obj, id: index + 1 }));
      setAccessChanges(jsonArrayWithId)
      setRowCount(response.data1 )
      }
      else{
        const response = await window.Platform.database.filterOrderDistributor({filterField:searchTerm1?searchTerm1.field:"date",filterValue:searchTerm1?.value,pageNumber:paginationModel1.page,startDate:'',endDate:'' })
      const jsonArrayWithId = response?.data?.map((obj, index) => ({ ...obj, id: index + 1 }));
      setAccessChanges(jsonArrayWithId)
      setRowCount(response.data1 )
      }
      setLoading(false)
    }
    catch(e){
      console.log(e)
      window.NotificationUtils.showError("Error While Recieving Data Please Wait and try again");
      if(selectedTab===0){
        fetchOrder()
      }
      else{
        fetchOrder1()
      }
    }
  }
  else{
    if(selectedTab===0){
      setFilter(false)
      fetchOrder()
      
    }
    else{
      setFilter1(false)

      fetchOrder1()
    }
  }
  }

  const submitOtp=async(data)=>{
    try{
      const response = await window.Platform.database.verifyOtp({otp:data,mobile:otpModalData.mobileNo})
      setLoading(true);
      if (response.status) {
        window.NotificationUtils.showError(response.message);
        setLoading(false);
      } else {
        const response1 = await window.Platform.database.createOrder({...otpModalData})            
        setAddModal(false)

        fetchOrder()
        // const results = await window.Platform.database.createOrder({...form,orders:dataGrid,totalAmount:totalAmount});
        window.NotificationUtils.showSuccess("Order Placed Successfully!")

        setLoading(false)
        // fetchEmployees()
        // window.NotificationUtils.showSuccess("Party Added Successfully");
      }
    }
catch(error){
    console.log(error)
    setLoading(false)

}  
}

  const onClose=async(data)=>{
    // console.log("COMMONOTPMODAL",data.makerContact)
try{
  setLoading(true)
const response = await window.Platform.database.sendOtp({mobile:data.mobileNo})

    setOtpModalData(data)
    setOtpModal1(true)
  setLoading(false)
  window.NotificationUtils.showSuccess("OTP Sent Successfully");


    // setAddModal(false)
    // setEditSchool(false)
  }
catch(error){
console.log(error)
setLoading(false)

}
  }

  const submitOtp1=async(data)=>{
    try{
      const response = await window.Platform.database.verifyOtp({otp:data,mobile:otpModalData1.mobileNo})
      setLoading(true)
      if (response.status) {
        window.NotificationUtils.showError(response.message);
        setLoading(false)
      } else {

        const response1 = await window.Platform.database.createOrder({...otpModalData1})            
        setAddModal1(false)

        fetchOrder1()
        // const results = await window.Platform.database.createOrder({...form,orders:dataGrid,totalAmount:totalAmount});
        window.NotificationUtils.showSuccess("Order Placed Successfully!")

        setLoading(false)
        // fetchEmployees()
      }

    }
catch(error){
    console.log(error)
    setLoading(false)

}  
}

const onClose1=async(data)=>{
  console.log("COMMONOTPMODAL",data.makerContact)
try{
  setLoading(true)

const response = await window.Platform.database.sendOtp({mobile:data.mobileNo})

  setOtpModalData1(data)
  setOtpModal2(true)
  // setEditSchool(false)
  setLoading(false)

}
catch(error){
console.log(error)
setLoading(false)

}
}

const resendOTP=async(data)=>{
  try{
      setLoading(true)
      const response = await window.Platform.database.resendOTP({mobile:otpModalData.mobileNo})
  
      setLoading(false)
        window.NotificationUtils.showSuccess("OTP Sent Successfully");
         
          // setDistributorModal(false)
          // setEditSchool(false)
        }
  catch(error){
  console.log(error)
  }
        }
        const resendOTP1=async(data)=>{
          try{
              setLoading(true)
              const response = await window.Platform.database.resendOTP({mobile:otpModalData1.mobileNo})
          
              setLoading(false)
        window.NotificationUtils.showSuccess("OTP Sent Successfully");
                 
                  // setDistributorModal(false)
                  // setEditSchool(false)
                }
          catch(error){
          console.log(error)
          }
                }
  return (
    <>
      
      <StaffEditorPageContainer>
        <HeaderContainer>
          {/* <Typography variant="h5">Order Management</Typography> */}
          {loading && <OpaqueLoading/>}
          <div style={{width:"60%",display:"flex",flexDirection:"row",gap:"10px"}}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker label="Start Date" value={startDate1} disabled={loading} format="YYYY/MM/DD" onChange={(data)=>formatDate(data)} disableFuture={true} />
            </LocalizationProvider>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker label="End Date"  minDate={minDate} value={endDate1} disabled={!dateRange1||loading} format="YYYY/MM/DD" onChange={(data)=>finalDateRangeFilter(data.$d)} disableFuture={true} />
            </LocalizationProvider>
            <Button variant="text" onClick={()=>clearDateFilter()} disabled={!dateRange1||loading} >Clear</Button>
            </div>
            <Stack spacing={2} direction={"row"}>
            {/* <FabStyle variant="extended" color="primary" 
             size="small"
             onClick={() => navigation('/returnOrder')} 
             >
  <PreviewIcon sx={{ mr: 1 }}  />
  View Returns
</FabStyle>
          { selectedTab===0? <FabStyle variant="extended" color="primary" 
            onClick={()=> setAddModal(true)} size="small"
  //  onClick={}
             >
  <Add sx={{ mr: 1 }}  />
  Add Order School
</FabStyle>:
<FabStyle variant="extended" color="primary" 
            onClick={()=> setAddModal1(true)} size="small"
  //  onClick={}
             >
  <Add sx={{ mr: 1 }}  />
  Add Order Distributor
</FabStyle> } */}

  <Button variant="contained" color="primary" 
    onClick={() => navigation('/returnOrder')} sx={{ mr: 1, borderRadius: '10px' }}>
    <PreviewIcon sx={{ mr: 1 }}  />
    View Returns
  </Button>

  { selectedTab === 0 ?
    <Button variant="contained" color="primary" 
      onClick={()=> setAddModal(true)} sx={{ mr: 1, borderRadius: '10px' }}>
      <Add sx={{ mr: 1 }}  />
      Add Order School
    </Button>
    :

    <Button variant="contained" color="primary" 
      onClick={()=> setAddModal1(true)} sx={{ mr: 1, borderRadius: '10px' }}>
      <Add sx={{ mr: 1 }}  />
      Add Order Distributor
    </Button>
  }

</Stack>

            {/*  */}
                  {/* </FabStyle> */}
            {/* )} */}
        </HeaderContainer>
        <TableContainer>
        <TabsContainer>

<StyledTabs

    variant="fullWidth"

    value={selectedTab}

    onChange={(event, value) => handleTabChange(value)}

    indicatorColor="primary"

    textColor="primary"

>

    <Tab label="School Orders" />


    <Tab label="Distributor Orders" />

</StyledTabs>

{renderContent()}

</TabsContainer>
</TableContainer>
      </StaffEditorPageContainer>
      {
      modal &&
      <OrderLineItemModal
      onCancel={() => setModal(null)}
      loading={loading}
      data={modalData}
      props={accessChanges}
    //   onSubmit={updateWarehouseData}
      />
    }
    {
      addModal && <AddOrderModal onClose1={(data)=>onClose(data)} onClose={()=>setAddModal(false)} fetchData={()=>fetchOrder()} status={true} />
    }
    {
      editModal && <AddOrderModal onClose={()=>setEditModal(false)} fetchData={()=>fetchOrder()} status={false} dataProp={editModalData}  />
    }

    {
      addModal1 && <AddOrderModalDistributor onClose1={(data)=>onClose1(data)} onClose={()=>setAddModal1(false)} fetchData={()=>fetchOrder1()} status={true}/>
    }

{
      editModal1 && <AddOrderModalDistributor onClose={()=>setEditModal1(false)} fetchData={()=>fetchOrder1()} status={false} dataProp={editModalData}  />
    }
      {
                otpModal1&&<CommonOtpModal  closeModal={()=>setOtpModal1(false)} onClickSubmit={(data)=>submitOtp(data)} resendOTP={()=>resendOTP()}/>
            }

{
                otpModal2&&<CommonOtpModal  closeModal={()=>setOtpModal2(false)} onClickSubmit={(data)=>submitOtp1(data)} resendOTP={()=>resendOTP1()}/>
            }

{
                returnItems&&<ReturnOrderModal  onCancel={()=>setReturnItems(false)} data={returnData}/>
            }
    </>
  );
}

import react, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import {
  DataGrid,
  GridColDef,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton,
  GridValueGetterParams,
  getGridStringOperators,
} from "@mui/x-data-grid";
import styled from "styled-components";
import Drawer from "../../components/common/drawer/drawer";
import { Typography, Button, Checkbox, Fab, IconButton, Stack } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import { ERROR_COLOR } from "../../constants";
import { Add, Save, SaveAlt } from "@mui/icons-material";
import WarehouseModal from "../../components/modals/orderLineItemModal";
import OpaqueLoading from "../../components/opaqueLoading/opaqueLoading";
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import AttendanceModal from "../../components/modals/attendanceModal";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import BulkAddLead from "../../components/modals/bulkAddLead";
import AddLeadModal from "../../components/modals/addLeadModal";
import DeleteIcon from '@mui/icons-material/Delete';



const isMobile = window.innerWidth < 900;

const StaffEditorPageContainer = styled.div`
  height: ${isMobile ? `auto` : `calc(100vh - 105px)`};
  width: 100%;
  background-color: white;
  padding: 10px 15px;
  ${isMobile &&
    `
      position: relative;
  
      `}
`;

const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: ${isMobile ? "flex-start" : "space-between"};
  margin-bottom: 10px;
  flex-direction: ${isMobile ? "column" : "row"};
`;

const HeaderButtonsContainer = styled.div`
  ${isMobile && "margin-top:10px;"}
`;

const TableContainer = styled.div`
// height: calc(
//   100vh - ${isMobile ? "56px - 20px - 82.23px" : "112px - 34px - 43.77px"}
// );
  width: 100%;
  // border: solid 1px lightGrey;
  border-radius: 8px;
`;
const AvatarContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const DataGridContainer = styled.div`
  max-width: 100%; /* Add this line to limit the width */
  margin: 0 auto; /* Center the content horizontally */
  // height: 100%;
  width: 100%;
  border: solid 1px lightGrey;
  border-radius: 8px;
  display: ${isMobile ? 'flex' : 'grid'};

  ${isMobile &&
  `
    flex-direction: column;
  `}
`;

const LoadingContainer = styled.div`
  height: calc(100% - 49px);
  display: flex;
  justify-content: center;
  align-items: center;
`;

const TabsContainer = styled.div`
  ${isMobile &&
  `
    height: calc(100vh - 56px - 20px);
  `}
`;

const GenerateReportButton = styled(Button)`
  margin-right: 10px !important;
`;
const BulkChangePinCodeButton = styled(Button)`
  margin-right: 10px !important;
`;

const PinCodeChangeButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const FabStyle = styled(Fab)`
  && {
    z-index: 1};
  }
`;

const StyledHeader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export default function LeadManagement() {
  const label = { inputProps: { "aria-label": "Checkbox demo" } };

  const [loading, setLoading] = useState(false);
  const [accessChanges, setAccessChanges] = useState("");
  const [showWarehouseFormModal, setShowWarehouseFormModal] = useState();
const [attendanceModal, setAttendanceModal] = useState(false)
const [attendanceModal1, setAttendanceModal1] = useState(false)
const [rowCount,setRowCount] = useState()
const [currentPage, setCurrentPage] = useState(1);
const [paginationModel, setPaginationModel] = useState({
  page: currentPage - 1,
  pageSize: 11,
});
const [filter,setFilter]=useState(false)
const [searchTerm, setSearchTerm]=useState(null)
const [startDate,setStartDate]=useState()
const [endDate,setEndDate]=useState()
const [dateRange1,setDateRange1]=useState(false)

const [startDate1,setStartDate1]=useState()
const [endDate1,setEndDate1]=useState()
const [minDate,setMinDate]=useState()


useEffect(() => {
  // setAccessChanges(rows);

    getPartyLeadAdmin()
}, []);


  const formatDateTime=(inputDateString)=> {
    const inputDate = new Date(inputDateString);

  // Check if the input date is valid
  if (isNaN(inputDate.getTime())) {
    return 'Invalid Date';
  }

  // Extract date and time components
  const year = inputDate.getUTCFullYear();
  const month = String(inputDate.getUTCMonth() + 1).padStart(2, '0');
  const day = String(inputDate.getUTCDate()).padStart(2, '0');
  const hours = String(inputDate.getUTCHours()).padStart(2, '0');
  const minutes = String(inputDate.getUTCMinutes()).padStart(2, '0');
  const seconds = String(inputDate.getUTCSeconds()).padStart(2, '0');

  // Format the date string without 'T' and 'Z'
  const formattedDateString = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;

  return formattedDateString;

  }
  
  const formatTimeStamp = (timestampFromDB) => {
    const options = {
      timeZone: 'Asia/Kolkata',
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,  // Use 12-hour clock
    };
  
    const formattedDate = new Date(timestampFromDB).toLocaleString('en-US', options);
  
    return formattedDate;
  };

 



  const getPartyLeadAdmin= async () => {
    try {
      setLoading(true);
      let results = await window.Platform.database.getPartyLeadAdmin();
      console.log("results", results);
      setRowCount(results.data1);
      // if(auth?.user?.role === ROLES.ADMIN){
      const jsonArrayWithId = results?.data?.map((obj, index) => ({ ...obj, id: index + 1 }));

        setAccessChanges(jsonArrayWithId);
      // }
      // setVisibleEmployees(results);
      setLoading(false);
    } catch (error) {
      console.error(error);
      window.NotificationUtils.showError("Error Fetching Data");
      // setLoading(false);
    }
    finally{
      setLoading(false)
    }
  }

  const getEmployeeColumns = () => {
    const stringOperators = getGridStringOperators().filter((op => ['contains'].includes(op.value)));
  let result = [
    {
      field: "name",
      headerName: "Employee Name",
      sortable: false,
      width: 200,
      filterOperators: stringOperators 
,
      editable: false,
      valueGetter: (params) => {
        return (params.row?.name || "N/A");
      },
      renderCell: (params) => {

        let name = params.row?.name||"N/A"

        return (

          <Tooltip title={name || "N/A"}>

            <Typography variant="inherit">{name}</Typography>

          </Tooltip>

        )

      },
    },
    // {
    //   field: "leadId",
    //   headerName: "Lead Id ",
    //   width: 200,    
    //           filterable: false,

    //   // height: 10,
    //   // headerAlign: 'center',
    //   sortable: false,
    //   // editable: true,
    //   renderCell: (params) => {

    //     let name =params.row.leadId

    //     return (

    //       <Tooltip title={name || "N/A"}>

    //         <Typography variant="inherit">{name}</Typography>

    //       </Tooltip>

    //     )

    //   },
    // },
    {
        field: "leadName",
        headerName: "Lead Name",
        width: 200,
        filterable: false,

        // height: 10,
        // headerAlign: 'center',
        sortable: false,
        // editable: true,
        valueGetter: (params) => {
          return (params.row?.leadName || "N/A");
        },
        renderCell: (params) => {
  
          let name =params.row.leadName||"N/A"
  
          return (
  
            <Tooltip title={name || "N/A"}>
  
              <Typography variant="inherit">{name}</Typography>
  
            </Tooltip>
  
          )
  
        },
      },
      {
        field: "leadSource",
        headerName: "Lead Source",
        width: 200,
        filterable: false,
        sortable: false,
        valueGetter: (params) => {
          return (params.row?.source_lead || "N/A");
        },
        renderCell: (params) => {
          let leadSource = params.row.source_lead || "N/A";
          return (
            <Tooltip title={leadSource || "N/A"}>
              <Typography variant="inherit">{leadSource}</Typography>
            </Tooltip>
          );
        },
      },
      {
        field: "leadRating",
        headerName: "Lead Rating",
        width: 200,
        filterable: false,
        sortable: false,
        valueGetter: (params) => {
          return (params.row?.rating_lead || "N/A");
        },
        renderCell: (params) => {
          let leadRating =params.row.rating_lead || "N/A"
          return (
            <Tooltip title={leadRating || "N/A"}>
              <Typography variant="inherit">{leadRating}</Typography>
            </Tooltip>
          )
        },
      },
      {
        field: "leadStatus",
        headerName: "Lead Status",
        width: 200,
        filterable: false,
        sortable: false,
        valueGetter: (params) => {
          return (params.row?.status_lead || "N/A");
        },
        renderCell: (params) => {
          let leadStatus =params.row.status_lead || "N/A"
          return (
            <Tooltip title={leadStatus || "N/A"}>
              <Typography variant="inherit">{leadStatus}</Typography>
            </Tooltip>
          )
        },
      },

      {
        field: "created",
        headerName: "Created At",
        width: 250,
        filterable: false,

        // height: 10,
        // headerAlign: 'center',
        sortable: false,
        // editable: true,
        valueGetter: (params) => {
          return (params.row?.createdAt ? formatTimeStamp(params.row?.createdAt) : "N/A");
        },
        renderCell: (params) => {
  
          let name = formatTimeStamp(params.row.createdAt) || "N/A";
  
          return (
  
            <Tooltip title={name || "N/A"}>
  
              <Typography variant="inherit">{name}</Typography>
  
            </Tooltip>
  
          )
  
        },
      },
      // {
      //   field: "updated",
      //   headerName: "Updated At",
      //   width: 200,
      //   filterable: false,

      //   // height: 10,
      //   // headerAlign: 'center',
      //   sortable: false,
      //   // editable: true,
      //   renderCell: (params) => {
  
      //     let name =params.row.updatedAt||"N/A"
  
      //     return (
  
      //       <Tooltip title={name || "N/A"}>
  
      //         <Typography variant="inherit">{name}</Typography>
  
      //       </Tooltip>
  
      //     )
  
      //   },
      // },
      {
        field: "status",
        headerName: "Status",
        width: 200,            filterable: false,

        // height: 10,
        // headerAlign: 'center',
        sortable: false,
        // editable: true,
        valueGetter: (params) => {
          return (params.row?.status === 0 ? "Lead" : "Converted to party");
        },
        renderCell: (params) => {
  
          let name = params.row?.status===0?"Lead":"Converted to party"
  
          return (
  
            <Tooltip title={name || "N/A"}>
  
              <Typography variant="inherit">{name}</Typography>
  
            </Tooltip>
  
          )
  
        },
      },
      {
        field: "action",
        headerName: "Actions",
        width: 200,            filterable: false,

        // height: 10,
        // headerAlign: 'center',
        sortable: false,
        // editable: true,
        disableExport: true,
        renderCell: (params) => {
  
  
          return (
  
            <Tooltip title={" Delete Lead"}>
  
  <IconButton onClick={()=>handleDeleteLead(params.row.leadId)}>
<DeleteIcon/>
               </IconButton>
  
            </Tooltip>
  
          )
  
        },
      },

 
  ];


  return result
}

const handleDeleteLead = async (data) => {
  const isConfirmed = window.confirm("Are you sure you want to delete this lead?");
  
  if (!isConfirmed) {
    return;
  }

  try {
    setLoading(true);
    let results = await window.Platform.database.deleteLead({ id: data });
    setLoading(false);
    
    window.NotificationUtils.showSuccess("Lead deleted successfully!");
    getPartyLeadAdmin();
  } catch (error) {
    console.error(error);
    setLoading(false);
  }
};

const openPackaging = (val) => {
  window.open(val, '_blank');
};

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
        <GridToolbarExport
          csvOptions={{
            fileName: "export-leads",
          }}
        />
      </GridToolbarContainer>
    );
  }

  const onFilterChange= async(filterModel) => {

    
    try{  
      setLoading(true);
      setFilter(true)
      console.log(filterModel,'filterModel')
      if(filterModel){
      const response = await window.Platform.database.filterAttendance({filterField:filterModel.field,filterValue:filterModel.value,pageNumber:paginationModel.page,startDate:startDate,endDate:endDate})
      console.log("respponse",response);
      const jsonArrayWithId = response?.data?.map((obj, index) => ({ ...obj, id: index + 1 }));
      setAccessChanges(jsonArrayWithId)
      setRowCount(response.data1 )
      }
      else{
        const response = await window.Platform.database.filterAttendance({filterField:"date",pageNumber:paginationModel.page,startDate:startDate,endDate:endDate})
        console.log("respponse",response);
        const jsonArrayWithId = response?.data?.map((obj, index) => ({ ...obj, id: index + 1 }));
        setAccessChanges(jsonArrayWithId)
        setRowCount(response.data1 )
       
      }
      
      setLoading(false);
    }
    catch(err){
  console.error(err)
  setLoading(false);
  window.NotificationUtils.showError("Error While Recieving Data Please Wait and try again");  
    }
    
  };

  const renderContent = () => {
    return (
      <DataGridContainer style={{ height: !accessChanges?.length ? '200px' : 'auto' }}>
        <DataGrid
          className="payrollGrid"
        //   checkboxSelection={true}
        density="compact"
          rows={accessChanges||[]}
          columns={getEmployeeColumns()}
          disableSelectionOnClick
          disableRowSelectionOnClick
          rowHeight={60}
          disableDensitySelector
          // autoPageSize
//           onFilterModelChange={(val)=>
//               {
//           //       console.log("vvvv",val);
            
            
            
//             if(val?.items[0]?.value?.length>0){
//               onFilterChange(val.items[0])
//               setSearchTerm(val.items[0])
//             } else if(!val.items[0]?.value   && startDate && endDate){
//               setSearchTerm(null)
//               onFilterChange()
//               console.log("CHECKING...1")

//             }
//             else{
//               fetchVisits()
//               setSearchTerm(null)
//               setFilter(false)
//             }
//           //   else if(!val.items?.value && !endDate && !startDate && !territoryFilter){
//           //     setSearchTerm(null)
//           //     fetchData()
//           //     console.log("CHECK1")
//           //   }
//           //   else if(!val.items?.value && endDate && startDate && territoryFilter){
//           //     setSearchTerm(null)
  
//           //     onFilterChange1()
//           //     console.log("checkterr")
//           //   }
//           //   else if(!val.items?.value && endDate && startDate && !territoryFilter){
//           //     setSearchTerm(null)
//           //     console.log("check2")
//           //     onFilterChange1()
  
  
//           //   }
//           //   else{
//           //     setSearchTerm(null)
//           //     console.log("check2")
//           //     onFilterChange1()
//           //   }
//           }
            
//           }
//           filterMode="server"

// rowCount={rowCount}
          
//           paginationModel={paginationModel}
//           paginationMode="server"
//           onPaginationModelChange={setPaginationModel}
          
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 10,
              },
            },
          }}
          pageSizeOptions={[10]}
          components={{
            Toolbar: CustomToolbar,
          }}
        />
      </DataGridContainer>
    );
  };



  const startDay=()=>{
    setAttendanceModal(true)
  }
  
  const endDay=()=>{
    setAttendanceModal1(true)
  }

//   const formatDate=async(data)=>{
//     setMinDate(data)
//     setDateRange1(true)
//     setEndDate1(null)
//     setEndDate(null)
//     const datePickerResponse = new Date(data.$d);
  
//   const year = datePickerResponse.getFullYear();
//   const month = String(datePickerResponse.getMonth() + 1).padStart(2, '0');
//   const day = String(datePickerResponse.getDate()).padStart(2, '0');
//   const formattedDate = `${year}-${month}-${day}`;
  
//   setStartDate1(formattedDate)
//   setStartDate(formattedDate)
//   console.log("CHECK!!")
//   console.log("CHECKENDDATE",endDate)
  
//   }
//   const finalDateRangeFilter=async(data)=>{
//     try{
//       setLoading(true)
//       setFilter(true)
  
//     const datePickerResponse = new Date(data);
  
//     const year = datePickerResponse.getFullYear();
//     const month = String(datePickerResponse.getMonth() + 1).padStart(2, '0');
//     const day = String(datePickerResponse.getDate()).padStart(2, '0');
//   const formattedDate = `${year}-${month}-${day}`;
    
//     if (data){
//     setEndDate(formattedDate)
//     setEndDate1(formattedDate)
//   }
//       if(searchTerm){
//       const response = await window.Platform.database.filterAttendance({filterField:searchTerm.field,filterValue:searchTerm?.value,pageNumber:paginationModel.page,startDate:startDate,endDate:data?formattedDate:endDate })
//       const jsonArrayWithId = response?.data?.map((obj, index) => ({ ...obj, id: index + 1 }));
//       setAccessChanges(jsonArrayWithId)
//       setRowCount(response.data1 )
       
//     }
//       else{
//       const response = await window.Platform.database.filterAttendance({filterField:"date",filterValue:searchTerm?.value,pageNumber:paginationModel.page,startDate:startDate,endDate:data?formattedDate:endDate })
//       const jsonArrayWithId = response?.data?.map((obj, index) => ({ ...obj, id: index + 1 }));
//       setAccessChanges(jsonArrayWithId)
//       setRowCount(response.data1 )
      
//       }
//       setLoading(false)
  
//     }
//     catch(err){
//       console.log(err)
//       setLoading(false)
//       window.NotificationUtils.showError("Error While Recieving Data Please Wait and try again");
  
//       fetchVisits()
    
//     }
//   }
//   const clearDateFilter=async()=>{
//     setStartDate(null)
//     setEndDate(null)
//     setStartDate1(null)
//     setEndDate1(null)
//     setDateRange1(false)
//     if(searchTerm){
//       try{
//       setLoading(true)
//       const response = await window.Platform.database.filterAttendance({filterField:searchTerm?searchTerm.field:"date",filterValue:searchTerm?.value,pageNumber:paginationModel.page,startDate:'',endDate:'' })
//       const jsonArrayWithId = response?.data?.map((obj, index) => ({ ...obj, id: index + 1 }));
//       setAccessChanges(jsonArrayWithId)
//         setRowCount(response.data1 )
        
//       setLoading(false)
//     }
//     catch(e){
//       console.log(e)
//       window.NotificationUtils.showError("Error While Recieving Data Please Wait and try again");
//       fetchVisits()  
  
//       }
//     }
//   else{
//     setFilter(false)
//     fetchVisits()
//   }
//   }


  return (
    <>
   
      <StaffEditorPageContainer>
          {loading && <OpaqueLoading/>}
        <HeaderContainer>
          {/* <Typography variant="h5">Attendance Management</Typography> */}
          <div style={{width:"60%",display:"flex",flexDirection:"row",gap:"10px"}}>
            {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker label="Start Date" value={startDate1} disabled={loading} format="YYYY/MM/DD" onChange={(data)=>formatDate(data)} />
            </LocalizationProvider>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker label="End Date"  minDate={minDate} value={endDate1} disabled={!dateRange1||loading} format="YYYY/MM/DD" onChange={(data)=>finalDateRangeFilter(data.$d)} />
            </LocalizationProvider>
            <Button variant="text" onClick={()=>clearDateFilter()} disabled={!dateRange1||loading} >Clear</Button> */}
            </div>
            <Stack spacing={1} direction={"row"}>
           {/* <FabStyle variant="extended" color="primary" size='small'

onClick={()=>startDay()}

>

<Add sx={{ mr: 1 }} />

Bulk Add

</FabStyle>
<FabStyle variant="extended" color="primary" size='small'

onClick={()=>endDay()}

>

<Add sx={{ mr: 1 }} />

Add Lead

</FabStyle> */}

  <Button variant="contained" color="primary" 
    onClick={() => startDay()} sx={{ mr: 1, borderRadius: '10px' }}>
    <Add sx={{ mr: 1 }}  />
    Bulk Add
  </Button>

  <Button variant="contained" color="primary" 
    onClick={() => endDay()} sx={{ mr: 1, borderRadius: '10px' }}>
    <Add sx={{ mr: 1 }}  />
    Add Lead
  </Button>

  </Stack>
        </HeaderContainer>
        <TableContainer>{renderContent()}</TableContainer>
      </StaffEditorPageContainer>
      {attendanceModal && <BulkAddLead onClose={()=>setAttendanceModal(false)} fetchData={()=>getPartyLeadAdmin()}/>}
      {attendanceModal1 && <AddLeadModal onClose={()=>setAttendanceModal1(false)} fetchData={()=>getPartyLeadAdmin()}/>}

    </>
  );
}

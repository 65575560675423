import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogContent,
  FormControl,
  FormControlLabel,
  FormGroup,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  Fab,
  DialogActions,
  Tabs,
  Tab,
  Autocomplete
} from "@mui/material";
import ForwardToInboxIcon from '@mui/icons-material/ForwardToInbox';
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

import Tooltip from "@mui/material/Tooltip";
import { Check as CheckIcon, Clear as ClearIcon, Close, CropSharp, Details } from "@mui/icons-material";
import { CloseOutlined } from "@mui/icons-material";
import { Stack } from "@mui/material";
import styled from "styled-components";
import { ACTIVITY_STATUS, ROLES } from "../../constants";
import moment from "moment";
import OpaqueLoading from "../opaqueLoading/opaqueLoading";
import { DataGrid, GridToolbarColumnsButton, GridToolbarContainer, GridToolbarDensitySelector, GridToolbarExport, GridToolbarFilterButton } from "@mui/x-data-grid";
import { AuthContext } from "../contextAPI/ContextAPI";
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";


 
const isMobile = window.innerWidth < 900;
 
const TotalAmountTypography = styled(Typography)`
 
  position: absolute;
 
  bottom: 10px;
 
  right: 10px;
 
  font-size: ${isMobile ? "14px" : "inherit"};
 
  bottom: ${isMobile ? "85px" : "70px"};
 
  right: ${isMobile ? "15px" : "70px"};
 
`;
const ModalHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
  padding: 10px;
`;
const TableContainer = styled.div`
  // height: calc(
  //   100vh - ${isMobile ? "106px - 20px - 82.23px" : "100px - 20px - 23.77px"}
  // );
  width: ${isMobile?"100%":"98%"};
  // border: solid 1px lightGrey;
  border-radius: 8px;
  margin-left:${isMobile?"0px":"10px"};
  margin-bottom:${isMobile?"0px":"10px"};

`;
const InputFieldContainer = styled.div`
  margin: 20px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  label {
    margin-bottom: 0px;
  }
`;
const TabsContainer = styled.div`
  ${isMobile && `
    height: calc(100vh - 176px - 20px - 43.77px);
  `}
`
const StyledTabs = styled(Tabs)`
  border-bottom: solid 1px lightGrey;
`;
 
const ModalActionsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 20px;
`;
 
const FirstButton = styled(Button)`
  margin-right: 10px !important;
`;
 
const StyledDialogContent = styled(DialogContent)`
  padding: 16px;
`;
 
const StyledDialog = styled(Dialog)`
  position: relative;
`;
 
const FabStyle = styled(Fab)`
  && {
    z-index: 1;
  }
`;
 
const DataGridContainer = styled.div`
  width: 100%;
 
 
`;
const DataGridContainer1 = styled.div`
  width: 100%;
  margin-bottom:${!isMobile&& "20px"};
 
//   height: 500px;
`;
 
const NoteText = styled(Typography)`
  margin-top: 10px !important;
`;
 
const Input = styled.input`
  display: none;
`;
 
 
const AddSeriesModal = ({ props,onClose,remarks,data,id,distributorId,fetchData,selectedTab}) => {
  console.log("DATA",data);
  const [tabValue, setTabValue] = useState(0);
  const { auth, setAuth } = useContext(AuthContext);
  console.log("DATAAUTH",auth.user.plant_master__c );
  const [loading,setLoading]=useState(false)
// const [product,setProduct]=useState()

 const [form,setForm]=useState({})
 const [form1,setForm1]=useState({})

 const [employee,setEmployee]=useState([])

const [product,setProduct]=useState([])
const [product1,setProduct1]=useState([])

const [selectProduct,setSelectProduct]=useState(null)
const [selectProduct1,setSelectProduct1]=useState()
const [board,setBoard]=useState()
const [medium,setMedium]=useState()
const [className,setClassName]=useState()
const [selectDataFilter,setSelectDataFilter]=useState({
    board:'',class:'',medium:'',subject:'',series:''
})
const [getSubjects,setGetSubject]=useState()

const [getSeriesCategory,setgetSeriesCategory]=useState()




console.log("SELECTEDFILTER",selectProduct)

const getSubject=async(data)=>{
  let results = await window.Platform.database.getSubject();
  setGetSubject(results.data)


}


  const handleChangeTab = (event, newValue) => {
    console.log("newValue: " , newValue);
    setTabValue(newValue);
  };
   

  console.log("FORMM",form)
  
 
useEffect(()=>{
    getMedium()
    getBoard()
    fetchProduct()
    getClass()
    getSubject()
    getSeriesCategoryData()


console.log("currentTab", tabValue)
},[])
useEffect(()=>{
    updateDataGrid()
    console.log("productSSS",product)
    console.log("productSSsData",selectDataFilter)



},[selectDataFilter])
 
const changeHandler = (fieldName, value) => {
    // console.log(value)
    setForm((prevForm) => ({ ...prevForm, [fieldName]: value }));
  };

  const updateDataGrid = (selectedContainer) => {
    if (!selectDataFilter.board&&!selectDataFilter.medium&&!selectDataFilter.class && !selectDataFilter.subject && !selectDataFilter.series) {
      // If no container is selected, display all data
      setProduct(product1);
    } else {
      // Filter the data based on the selected container
    //   const filteredData = product.filter(item => item.mediumTableId === selectDataFilter.medium || item.classId===selectDataFilter.class || item.boardId===selectDataFilter.board );
    const filteredData = product1.filter(item => {
        const boardMatch = !selectDataFilter.board || item.boardId === selectDataFilter.board;
        const mediumMatch = !selectDataFilter.medium || item.mediumTableId === selectDataFilter.medium;
        const classMatch = !selectDataFilter.class || item.classId === selectDataFilter.class;
        const subjectMatch = !selectDataFilter.subject || item.subjectId === selectDataFilter.subject;
        const seriesMatch = !selectDataFilter.series || item.seriesCategory === selectDataFilter.series;


  
        // Return true only if the filter conditions are met based on filter presence
        return (
          (!selectDataFilter.board || boardMatch) &&
          (!selectDataFilter.medium || mediumMatch) &&
          (!selectDataFilter.class || classMatch) && (!selectDataFilter.subject||subjectMatch)&&
        (!selectDataFilter.series || seriesMatch)

        );
      });
    setProduct(filteredData);
    }
  };

  const getColumns = () => {
    let result = [
      // {
      //   field: "skuId",
      //   headerName: "SKU Id",
      //   sortable: false,
      //   width: 200,
        
      //   editable: false,
      //   renderCell: (params) => {
  
      //     let name = params.row?.skuId||"N/A"
  
      //     return (
  
      //       <Tooltip title={name || "N/A"}>
  
      //         <Typography variant="inherit">{name}</Typography>
  
      //       </Tooltip>
  
      //     )
  
      //   },
      // },
      {
        field: "nameSku",
        headerName: "SKU Name",
        width: 170,
        // height: 10,
        // headerAlign: 'center',
        sortable: false,
        // editable: true,
        renderCell: (params) => {
  
          let name =params.row.nameSku||"N/A"
  
          return (
  
            <Tooltip title={name || "N/A"}>
  
              <Typography variant="inherit">{name}</Typography>
  
            </Tooltip>
  
          )
  
        },
      },
      {
          field: "Price",
          headerName: "Unit Price",
          width: 150,
          // height: 10,
          // headerAlign: 'center',
          sortable: false,
          // editable: true,
          renderCell: (params) => {
    
            let name =params.row.unitPrice||"N/A"
    
            return (
    
              <Tooltip title={name || "N/A"}>
    
                <Typography variant="inherit">{name}</Typography>
    
              </Tooltip>
    
            )
    
          },
        },
  
       
  
   
    ];
  
  
    return result
  }


const boardHandler=(data)=>{
    changeHandler("board",data)
    setSelectDataFilter((prevForm) =>({...prevForm,board:data}))
}
const mediumHandler=(data)=>{
    changeHandler("medium",data)
    setSelectDataFilter((prevForm) =>({...prevForm,medium:data}))
}
const classHandler=(data)=>{
    changeHandler("classId",data)
    setSelectDataFilter((prevForm) =>({...prevForm,class:data}))
}
const subjectHandler=(data)=>{
  changeHandler("subject",data)
setSelectDataFilter((prevForm) =>({...prevForm,subject:data}))

}

const renderContent1=()=>{
  return (
    <div style={{ overflowY: "auto" }}>
    <Stack direction={"row"} spacing={2} padding={1}>
    <TextField label="Set Name" value={form?.name || ""} type="text"fullWidth onChange={(e)=>changeHandler("name",e.target.value)}/>
  </Stack>
  <Stack direction={"row"} spacing={2} padding={1}>
  <FormControl variant="outlined" style={{ width: isMobile?"100%":"30%" }}>
          <InputLabel > Board</InputLabel>
          <Select
            labelId={`Product`}
            label="School Board"
            value={form?.board||""}
            onChange={(e) => boardHandler(e.target.value)}
          >
            {board?.map((option, optionIndex) => (
    <MenuItem key={option.boardId} value={option.boardId}>
      {option.boardName}
    </MenuItem>
  ))}
          </Select>
        </FormControl>
        <FormControl variant="outlined" style={{ width: isMobile?"100%":"30%" }}>
          <InputLabel id={`select-label`}> Medium</InputLabel>
          <Select
            labelId={`Product`}
            label="School Medium"
            value={form?.medium||""}
            onChange={(e) => mediumHandler( e.target.value)}
          >
            {medium?.map((option, optionIndex) => (
    <MenuItem key={option.mediumTableId} value={option.mediumTableId}>
      {option.mediumName}
    </MenuItem>
  ))}
          </Select>
    </FormControl>
    <FormControl style={{ width: isMobile?"100%":"30%" }}>
      <InputLabel id="select-label">Class</InputLabel>
      <Select
        labelId="select-label"
        id="select"
        value={form.classId||""}
        onChange={(e)=>classHandler(e.target.value)}
        label="Select Option"
      >
         {className?.map((option, optionIndex) => (
    <MenuItem key={option.classId} value={option.classId}>
      {option.className}
    </MenuItem>
  ))}
      </Select>
    </FormControl>
    <FormControl style={{ width: isMobile?"100%":"30%" }}>
      <InputLabel id="select-label">Subject</InputLabel>
      <Select
        labelId="select-label"
        id="select"
        value={form.subject||""}
        onChange={(e)=>subjectHandler(e.target.value)}
        label="Select Option"
      >
         {getSubjects?.map((option, optionIndex) => (
    <MenuItem key={option.subjectId} value={option.subjectId}>
      {option.subjectName}
    </MenuItem>
  ))}
      </Select>
    </FormControl>
    <FormControl style={{ width: isMobile?"100%":"30%" }}>
      <InputLabel id="select-label">Series</InputLabel>
      <Select
        labelId="select-label"
        id="select"
        value={form.seriesCategory||""}
        onChange={(e)=>seriesHandler(e.target.value)}
        label="Select Option"
      >
         {getSeriesCategory?.map((option, optionIndex) => (
    <MenuItem key={option.seriesTableId} value={option.seriesTableId}>
      {option.seriesName}
    </MenuItem>
  ))}
      </Select>
    </FormControl>
    <Button variant="text" onClick={()=>clearFilter()} >
     Clear 
  </Button>
   
          </Stack>
         
          <Stack direction={"row"} spacing={2} padding={2}>

       
         <DataGrid
         style={{ height: !product.length ? '200px' : 'auto' }}
        className="payrollGrid"
      //   checkboxSelection={true}
      // sx={{height:isMobile ? "555px":"300px"}}
      density="compact"
      checkboxSelection={true}
        rows={product ||[]}
        columns={getColumns()}
        rowHeight={60}
        onRowSelectionModelChange={handleSelectionModelChange}
        disableSelectionOnClick
        disableRowSelectionOnClick
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 10,
            },
          },
        }}
        pageSizeOptions={[10]}        // autoPageSize
        // components={{
        //   Toolbar: CustomToolbar,
        // }}
      />
             
         
    </Stack> 
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>

 <Button variant="contained" disabled={!form.name||!selectProduct?.length} onClick={()=>onsubmit(form)} >
    Create
 </Button>
 </div>
      
</div>
)}

const handleSelectionModelChange = (newSelectionModel) => {
console.log("NEWSELECTION",newSelectionModel)

setSelectProduct(newSelectionModel)  

};

const getSeriesCategoryData=async(data)=>{
  let results = await window.Platform.database.getSeriesCategory();
  setgetSeriesCategory(results.data)


}
const seriesHandler=(data)=>{
      changeHandler("seriesCategory",data)
      console.log("Series",data)
      console.log("Series1234",selectDataFilter)
      setSelectDataFilter((prevForm) =>({...prevForm,series:data}))
}

const getBoard=async(data)=>{
    let results = await window.Platform.database.getBoard();
    setBoard(results.data)


  }
  const getMedium=async(data)=>{
    let results = await window.Platform.database.getMedium();
    const finalData=results?.data?.filter(medium=>medium.mediumTableId!="buCiAvMirI")

    setMedium(finalData)


  }

  const clearFilter=()=>{
    setSelectProduct(null)
    setSelectDataFilter({board:null, medium:null, class:null,series:null,subject:null})

    setForm((prevForm) => ({ ...prevForm, board:null, medium: null, classId:null,series:null,subject:null }));
  }

  console.log("FORMMM!",form1)

   const fetchProduct=async(data)=>{
    const results = await window.Platform.database.fetchProduct(data);
    const jsonArrayWithId = results?.data?.map((obj, index) => ({ ...obj, id: index + 1 }));

    setProduct(jsonArrayWithId)
    setProduct1(jsonArrayWithId)

   }

   const getClass=async(data)=>{
    const results = await window.Platform.database.getClass(data);
    const jsonArrayWithId = results?.data?.map((obj, index) => ({ ...obj, id: index + 1 }));

    setClassName(jsonArrayWithId)

   }
 
 
  const onsubmit=async(data)=>{
    try{
        
    setLoading(true)
    console.log(form)
    const filteredArray1 = product1.filter(item1 =>
        selectProduct?.some(item2 => item2 === item1.id)
      );
      const totalPriceSum = filteredArray1.reduce((accumulator, currentItem) => {
        return accumulator + (+currentItem.unitPrice);
      }, 0);
    const arrayOfIds = filteredArray1?.map(item => item.skuId);


console.log("FILTEREDAARRR",filteredArray1)
let results;
if(arrayOfIds){
   results = await window.Platform.database.addSeries({...data,item:arrayOfIds,unitPrice:totalPriceSum});
}
    if (results.status) {
      window.NotificationUtils.showError(results.message);
    } else {
      window.NotificationUtils.showSuccess("Set added successfully!");
    }
    setLoading(false)
    onClose()
    fetchData()

  }
    catch(error){
    console.log(error)
    setLoading(false)

    }
      }



  return (
    <Dialog disableEnforceFocus maxWidth="md" fullWidth={true} open={true} >
     {loading && <OpaqueLoading/>}
    <ModalHeaderContainer style={{marginBottom:2,paddingBottom:3}}>
       <Typography  variant={isMobile?"h6":"h5"}>
         Add Set
         <Tooltip title="Order Id">
 
           {id}
         </Tooltip>
           
           </Typography>
       <IconButton onClick={onClose} >
         <CloseOutlined />
       </IconButton>
     </ModalHeaderContainer>
     <TableContainer>
 
     <TabsContainer>
            <StyledTabs
              variant="fullWidth"
              value={tabValue}
              onChange={ handleChangeTab}
              indicatorColor="primary"
              textColor="primary"
            >
              <Tab label="Set Details " />
            
           
            </StyledTabs>
            {tabValue==0&&renderContent1()}
 
          </TabsContainer>
   
     </TableContainer>
 
   </Dialog>
  );
};
 
export default AddSeriesModal;
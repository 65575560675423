import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogContent,
  FormControl,
  FormControlLabel,
  FormGroup,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  Fab,
  DialogActions,
  Tabs,
  Tab,
  Autocomplete,
  InputAdornment
} from "@mui/material";
import ForwardToInboxIcon from '@mui/icons-material/ForwardToInbox';
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

import Tooltip from "@mui/material/Tooltip";
import { Check as CheckIcon, Clear as ClearIcon, Close, CropSharp, Details } from "@mui/icons-material";
import { CloseOutlined } from "@mui/icons-material";
import { Stack } from "@mui/material";
import styled from "styled-components";
import { ACTIVITY_STATUS, ROLES } from "../../constants";
import moment from "moment";
import OpaqueLoading from "../opaqueLoading/opaqueLoading";
import { DataGrid, GridToolbarColumnsButton, GridToolbarContainer, GridToolbarDensitySelector, GridToolbarExport, GridToolbarFilterButton } from "@mui/x-data-grid";
import { AuthContext } from "../contextAPI/ContextAPI";
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from "dayjs";
import CommonOtpModal from "./commonOtpModal";
import { TimePicker } from '@mui/x-date-pickers/TimePicker';

 
const isMobile = window.innerWidth < 900;
 
const TotalAmountTypography = styled(Typography)`
 
  position: absolute;
 
  bottom: 10px;
 
  right: 10px;
 
  font-size: ${isMobile ? "14px" : "inherit"};
 
  bottom: ${isMobile ? "85px" : "70px"};
 
  right: ${isMobile ? "15px" : "70px"};
 
`;
const ModalHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
  padding: 10px;
`;
const TableContainer = styled.div`
  // height: calc(
  //   100vh - ${isMobile ? "106px - 20px - 82.23px" : "100px - 20px - 23.77px"}
  // );
  width: ${isMobile?"100%":"98%"};
  border: solid 1px lightGrey;
  border-radius: 8px;
  margin-left:${isMobile?"0px":"10px"};
  margin-bottom:${isMobile?"0px":"10px"};

`;
const InputFieldContainer = styled.div`
  margin: 20px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  label {
    margin-bottom: 0px;
  }
`;
const TabsContainer = styled.div`
  ${isMobile && `
    height: calc(100vh - 176px - 20px - 43.77px);
  `}
`
const StyledTabs = styled(Tabs)`
  border-bottom: solid 1px lightGrey;
`;
 
const ModalActionsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 20px;
`;
 
const FirstButton = styled(Button)`
  margin-right: 10px !important;
`;
 
const StyledDialogContent = styled(DialogContent)`
  padding: 16px;
`;
 
const StyledDialog = styled(Dialog)`
  position: relative;
`;
 
const FabStyle = styled(Fab)`
  && {
    z-index: 1;
  }
`;
 
const DataGridContainer = styled.div`
  width: 100%;
 
 
`;
const DataGridContainer1 = styled.div`
  width: 100%;
  margin-bottom:${!isMobile&& "20px"};
 
//   height: 500px;
`;
 
const NoteText = styled(Typography)`
  margin-top: 10px !important;
`;
 
const Input = styled.input`
  display: none;
`;
 
 
const SchoolModal = ({ props,onClose,onClose1,remarks,dataProp,id,distributorId,fetchData,status}) => {
  console.log("DATA",dataProp);
  const [changePassword, setChangePassword] = useState(false)
  const [tabValue, setTabValue] = useState(0);
  const { auth, setAuth } = useContext(AuthContext);
  console.log("DATAAUTH",dataProp );
 
  const [details, setDetails] = useState(dataProp);
  const [employee,setEmployee]=useState([])

  const [schoolCategories, setSchoolCategories] = useState([]);

 const [roles, setRoles] = useState([])
  const [plantCode,setPlantCode]=useState("")
  const [loading,setLoading]=useState(false)
const [product,setProduct]=useState()
 const [deletedField,setDeletedFields]=useState([])
 const [hasChanges, setHasChanges] = useState(false);
 const [remark,setRemrak]=useState(remarks)
 const [form,setForm]=useState({id:dataProp?.schoolId||"",
   
  "schoolName":""||dataProp?.schoolName,
  "parentSchoolName":""||dataProp?.parentSchoolName,
  "board":""||dataProp?.board,
  "medium":""||dataProp?.medium,
  "incorporationDate":"" ,
  "email":""||dataProp?.email,
  "website":""||dataProp?.website,
  "gstNo":""||dataProp?.gstNo,
  "grade":""||dataProp?.grade,
  "strength":""||dataProp?.strength  ,
  "onboardforErp":""||dataProp?.onboardForErp  ,
  "pincode":""||dataProp?.Pincode  ,
"state":""||dataProp?.State,
"district":""||dataProp?.District,
"addressLine1":""||dataProp?.AddressLine1,
"addressLine2":""||dataProp?.AddressLine2,
"landmark":""||dataProp?.Landmark ,
"location":""||dataProp?.Location,
"makerName":""||dataProp?.makerName,
"makerContact":""||dataProp?.makerContact,
"ownerId":""||dataProp?.ownerId,
"distributorId":""||dataProp?.distributorID,
"makerRole":""||dataProp?.makerRole,
"affiliationPan":""||dataProp?.affiliation_or_pan,
"registrationNo":""||dataProp?.registratioNo,
"bankName":""||dataProp?.bankName,
"holderName":""||dataProp?.accountHolderName,
"branchName":""||dataProp?.branchName,
"accountNo":""||dataProp?.accountNo,
"ifsc":""||dataProp?.ifscCode,
"makerRole":""||dataProp?.decisionMakerRole
})

  const [errors, setErrors] = useState({});
 const [date,setDate]=useState()
 const [assignedEmp,setAssignedEmp]=useState(null)
 const [distributor,setDistributor]=useState([])
 const [selectDistributor,setSelectDistributor]=useState(null)
 const [board,setBoard]=useState()
 const [medium,setMedium]=useState()
 const [lead,setLead]=useState([])
 const [selectedLead,setSelectedLead]=useState(null)
 const [contactPersonRole,setContactPersonRole]=useState([])
 const [grade,setGrade]=useState([])
 const [selectedGrade,setSelectedGrade]=useState([])

 const [startTime, setStartTime] = useState(null);
 const [endTime, setEndTime] = useState(null);

 const [selectedCategory, setSelectedCategory] = useState(dataProp?.schoolCategory || '');


 const handleChange = (event) => {
   setSelectedCategory(event.target.value);
   changeHandler("schoolCategory", event.target.value);
 };

 const handleClear = () => {
   setSelectedCategory('');
   changeHandler("schoolCategory", '');
 };





 

 
  const handleChangeTab = (event, newValue) => {
    console.log("newValue: " , newValue);
    setTabValue(newValue);
  };

  const storeSelectedGrades = (value) => {
    setSelectedGrade(value);
  }

  console.log("FORMM",form)

  const handleStartTimeChange = (newValue) => {
    setStartTime(newValue);
    const formattedTime = dayjs(newValue).format("HH:mm:ss");
    setForm((prevForm) => ({ ...prevForm, 'startTime' : formattedTime }));
    if (endTime && newValue && dayjs(endTime).isBefore(newValue)) {
      setEndTime(null);
    }
  };

  const handleEndTimeChange = (newValue) => {
    setEndTime(newValue);
    const formattedTime = dayjs(newValue).format("HH:mm:ss");
    setForm((prevForm) => ({ ...prevForm, 'endTime' : formattedTime }));
  };

  const getSchoolCategories = async () => {
    try {

      setLoading(true);
      let results = await window.Platform.database.getSchoolCategories();
      setSchoolCategories(results.data);
      
      // const jsonArrayWithId = results?.data?.map((obj, index) => ({
      //   ...obj,
      //   id: index + 1,
      // }));

      // setAccessChanges(jsonArrayWithId);
      setLoading(false);

    } catch (error) {
      window.NotificationUtils.showError("Error Fetching Data");
      setLoading(false);
    }
  };

  useEffect(()=>{
    
  fetchEmployees()
  fetchDistributors()
  getBoard()
  getMedium()
  getContactPersonRole()
  getGrade()
  getSchoolCategories();
    console.log("dataprop", dataProp)
  if(dataProp) {
    const createdAt = dayjs(dataProp?.createdAt);
    if (dataProp?.start_time) {
      exctractingTimePickers('startTime', createdAt, dataProp?.start_time);
    }

    if(dataProp?.end_time) {
      exctractingTimePickers('endTime', createdAt, dataProp?.end_time);
    }
  }
  },[])
 
useEffect(()=>{
// if(tabValue===1){
//   fetchProduct(plantCode,distributorId)
// }

console.log("first", dataProp)

if(!status){
  const newDate=new Date(dataProp.incorporationDate)
  formatDate(dayjs(newDate))
const resultArray = dataProp?.grade?.split(',');
setSelectedGrade(resultArray)
// console.log(resultArray);
  
}
console.log("currentTab", tabValue)
},[])

  const exctractingTimePickers = (fieldName, createdAt, time) => {
    const [hours, minutes, seconds] = time?.split(':');

    const parsedTime = createdAt
      .hour(parseInt(hours, 10))
      .minute(parseInt(minutes, 10))
      .second(parseInt(seconds, 10));
    
    if (fieldName === "startTime") {
      setStartTime(parsedTime);
    } else {
      setEndTime(parsedTime);
    }
    
    changeHandler(fieldName, parsedTime);
  }
 
const changeHandler = (fieldName, value) => {

  if (fieldName === 'accountNo') {
    const sanitizedValue = value.replace(/[^0-9]/g, '').slice(0, 17);
      setForm((prevErrors) => ({
        ...prevErrors,
        accountNo: sanitizedValue,
      }));
      return;
  }

  if(fieldName==="makerContact" ||fieldName==="strength" ){
    console.log("FIELDNAME",fieldName)
    const sanitizedValue = value.replace(/[^0-9]/g, '').slice(0, 10);
    setForm((prevForm) => ({ ...prevForm, [fieldName]: sanitizedValue }))
  }
  else{
    // console.log(value)
    setForm((prevForm) => ({ ...prevForm, [fieldName]: value }));
  }
  };

  const schoolBoard=[{id:1,value:"CBSE"},{id:2,value:"ICSE"},{id:3,value:"State Board"}]
  const schoolMedium=[{id:1,value:"Hindi"},{id:2,value:"English"}]
  console.log("assignedEmp",assignedEmp)

const renderContent1=()=>{
  return (
    <div style={{ maxHeight:"510px",height:"505px", overflowY:"auto" }}>
    <Stack direction={"row"} spacing={2} padding={2}>
    <Autocomplete
            options={employee}
            disabled={loading}

            // getOptionLabel={}
            // multiple
            // getOptionLabel={(option) => `${option?.name} (${option?.id})`}
            getOptionLabel={(option) => `${option?.name}`}
              getOptionValue={(option) => option?.id || ''}
            style={{ width: "100%" }}
            value={assignedEmp}
            // disabled={loading 
              // || !addNewMode || props.isManager
            // }
            noOptionsText={loading ? "Loading..." : "No option"}
              onChange={(event, value) => {
                console.log("TEST1212",value)
                changeHandler("ownerId", value?.id)
                setAssignedEmp(value);
                getLeadParty(value?.id)

                //  updateDataGrid(value);
                
                // if(selectedCity.warehouseCountry === 'India' && warehouseData.attributes.warehouseCountry === 'China')
                // {
                //     setDisableTransfer(false)
                // }
                // else
                // setDisableTransfer(true)

               
                
              }}
              onInputChange={(e, value) => {
                // Check if the user's input matches any option
                // const matchingOption = employee.find((option) => option.name === value.name);

                // if (!matchingOption ) {
                //   // If there's no matching option, set the otherValue to null
                // //   setDeliveryCity(null);
                // setAssignedEmp();
                // return;
                // }
              }}
            renderInput={(params) => (
              <TextField
                {...params}
                // disabled={
                //   loading 
                  // || !addNewMode || props.isManager || props.isDeleted
                // }
                
                label={"Assign To"}
                required
                variant="outlined"
                error={errors.assignTo ? true : false}
              />
            )}/>
{status &&
<Autocomplete
            options={lead}
            disabled={!form.ownerId||loading}

            // getOptionLabel={}
            // multiple
            // getOptionLabel={(option) => `${option?.leadName} (${option?.leadId})`}
            getOptionLabel={(option) => `${option?.leadName}`}
              getOptionValue={(option) => option?.leadId || ''}
            style={{ width: "100%" }}
            value={selectedLead}
            // disabled={loading 
              // || !addNewMode || props.isManager
            // }
            noOptionsText={loading ? "Loading..." : "No option"}
              onChange={(event, value) => {
                console.log("TEST1212",value)
                changeHandler("leadId", value?.leadId)
                setSelectedLead(value);
                changeHandler("schoolName", value?.leadName)

                //  updateDataGrid(value);
                
                // if(selectedCity.warehouseCountry === 'India' && warehouseData.attributes.warehouseCountry === 'China')
                // {
                //     setDisableTransfer(false)
                // }
                // else
                // setDisableTransfer(true)

               
                
              }}
              onInputChange={(e, value) => {
                // Check if the user's input matches any option
                // const matchingOption = employee.find((option) => option.name === value.name);

                // if (!matchingOption ) {
                //   // If there's no matching option, set the otherValue to null
                // //   setDeliveryCity(null);
                // setAssignedEmp();
                // return;
                // }
              }}
            renderInput={(params) => (
              <TextField
                {...params}
                // disabled={
                //   loading 
                  // || !addNewMode || props.isManager || props.isDeleted
                // }
                label={"Select From Lead"}
                
                variant="outlined"
              />
            )}/>
            }
          </Stack>
          <Stack direction={"row"} spacing={2} padding={2}>
          <TextField label="School Name" value={form?.schoolName || ""} type="text" fullWidth onChange={(e)=>changeHandler("schoolName",e.target.value)} required error={errors.schoolName ? true : false}/>
            {console.log('categ', form?.categoryId)}
            
            <FormControl fullWidth variant="outlined" disabled={loading}>
              <InputLabel id="school-category-label">School Category</InputLabel>
              <Select
                labelId="school-category-label"
                label="School Category"
                value={selectedCategory}
                onChange={handleChange}
                endAdornment={
                  selectedCategory && (
                    <InputAdornment position="end">
                      <IconButton onClick={handleClear} size="small">
                        <ClearIcon />
                      </IconButton>
                    </InputAdornment>
                  )
                }
              >
                {loading ? (
                  <MenuItem disabled>
                    <CircularProgress size={24} />
                  </MenuItem>
                ) : (
                  schoolCategories.map((option) => (
                    <MenuItem key={option.id} value={option.name}>
                      {option.name}
                    </MenuItem>
                  ))
                )}
                {!loading && schoolCategories.length === 0 && (
                  <MenuItem disabled>No options</MenuItem>
                )}
              </Select>
            </FormControl>
          </Stack>
          <Stack direction={"row"} spacing={2} padding={2}>
            <TextField label="Parent School Name" value={form?.parentSchoolName || ""} type="text" fullWidth onChange={(e)=>changeHandler("parentSchoolName",e.target.value)}/>
   <TextField label="Email" value={form?.email || ""} type="email"fullWidth onChange={(e)=>changeHandler("email",e.target.value)} error={errors.email ? true : false} />
          </Stack>
          <Stack direction={"row"} spacing={2} padding={2}>
            <TextField label="Website" value={form?.website || ""} type="text" fullWidth onChange={(e)=>changeHandler("website",e.target.value)}/>
   <TextField label="Decision Maker Name" value={form?.makerName || ""} type="text"fullWidth onChange={(e)=>changeHandler("makerName",e.target.value)}/>
          </Stack>
          <Stack direction={"row"} spacing={2} padding={2}>
            <TextField label="Decision Maker Mobile No" value={form?.makerContact || ""} type="text" fullWidth onChange={(e)=>changeHandler("makerContact",e.target.value)}/>
            {/* <TextField label="Decision Maker Role" value={form?.makerRole || ""} type="text" fullWidth onChange={(e)=>changeHandler("makerRole",e.target.value)}/> */}
            <FormControl variant="outlined" style={{ width: isMobile?"100%":"100%" }}>
              <InputLabel style={{ background: 'white' }} >Decision Maker Role</InputLabel>
              <Select
                labelId={`Product`}
                label="School Board"
                value={form?.makerRole||""}
                onChange={(e) => changeHandler("makerRole",e.target.value)}
              >
                {contactPersonRole?.map((option, optionIndex) => (
                  <MenuItem key={option.contactPersonRoleId} value={option.roleName}>
                    {option.roleName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Stack>
          <Stack direction={"row"} spacing={2} padding={2}>
          <FormControl required variant="outlined" style={{ width: isMobile?"100%":"50%" }}>
          <InputLabel >School Board</InputLabel>
          <Select
            labelId={`Product`}
            label="School Board"
            value={form?.board||""}
            onChange={(e) => changeHandler("board",e.target.value)}
            required
            error={errors.board ? true : false}
          >
            {board?.map((option, optionIndex) => (
    <MenuItem key={option.boardId} value={option.boardName}>
      {option.boardName}
    </MenuItem>
  ))}
          </Select>
        </FormControl>
        <FormControl required variant="outlined" style={{ width: isMobile?"100%":"50%" }}>
          <InputLabel id={`select-label`}>School Medium</InputLabel>
          <Select
            labelId={`Product`}
            label="School Medium"
            value={form?.medium}
            onChange={(e) => changeHandler("medium", e.target.value)}
            required
            error={errors.medium ? true : false}
          >
            {medium?.map((option, optionIndex) => (
    <MenuItem key={option.mediumTableId} value={option.mediumName}>
      {option.mediumName}
    </MenuItem>
  ))}
          </Select>
        </FormControl>       
          </Stack>
          <Stack direction={"row"} spacing={2} padding={2}>
            <TextField label="Strength" value={form?.strength || ""} type="text" fullWidth onChange={(e)=>changeHandler("strength",e.target.value)}/>
             <FormControl variant="outlined" required style={{ width: isMobile?"100%":"100%" }}>
          <Autocomplete
            multiple
            options={grade}
            getOptionLabel={(option) => `${option?.gradeName}`}
            getOptionValue={(option) => option?.gradeId || ''}
            style={{ width: "100%" }}
            value={selectedGrade}
            noOptionsText={loading ? "Loading..." : "No option"}
            onChange={(event, value) => {
              storeSelectedGrades(value)
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label={"Grade"}
                required
                variant="outlined"
                error={errors.grade ? true : false}
              />
            )} />
        </FormControl>
  {/* <TextField label="Grade" value={form?.grade || ""} type="text" fullWidth onChange={(e)=>changeHandler("grade",e.target.value)}/> */}

          </Stack>

      <Stack
        direction="row"
        spacing={2}
        padding={2}
        sx={{ width: '100%' }}
      >
        <Box sx={{ flex: 1 }}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <TimePicker
              label="School Start Time"
              fullWidth
              sx={{ width: '100%' }}
              value={dayjs(startTime)}
              onChange={handleStartTimeChange}
              readOnly={ status ? false : true }
            />
          </LocalizationProvider>
        </Box>

        <Box sx={{ flex: 1 }}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <TimePicker
              label="School End Time"
              value={dayjs(endTime)}
              onChange={handleEndTimeChange}
              fullWidth
              sx={{ width: '100%' }}
              disabled={!startTime}
              readOnly={ status ? false : true }
              minTime={startTime}
            />
          </LocalizationProvider>
        </Box>
      </Stack>

          <Stack direction={"row"} spacing={2} padding={2}>
        <TextField label="School Affiliation Number/Pan Number" value={form?.affiliationPan || ""} type="text" fullWidth onChange={(e)=>changeHandler("affiliationPan",e.target.value)} required error={errors.affiliationPan ? true : false} inputProps={{ maxLength: 10, style: { textTransform: "uppercase" } }} />
        <TextField label="School Registration Number"  value={form?.registrationNo || ""} type="text" fullWidth onChange={(e)=>changeHandler("registrationNo",e.target.value)}/>
            
            </Stack>
          <Stack direction={"row"} spacing={2} padding={2}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker label="Incorporation Date*" value={date} sx={{width:"100%"}}  disabled={loading} format="YYYY/MM/DD" onChange={(data)=>formatDate(data)} required error={errors.incorporationDate ? true : false} />
            </LocalizationProvider>
            <Autocomplete
            disabled={loading}
            options={distributor}
            // getOptionLabel={}
            // getOptionLabel={(option) => `${option?.DistributorName} (${option?.distributorID})`}
            getOptionLabel={(option) => `${option?.DistributorName}`}
              getOptionValue={(option) => option?.distributorID || ''}
            style={{ width: "100%" }}
            value={selectDistributor}
            // disabled={loading 
              // || !addNewMode || props.isManager
            // }
            noOptionsText={loading ? "Loading..." : "No option"}
              onChange={(event, value) => {
                console.log("TEST1212",value)
                changeHandler("distributorId", value.distributorID)
                setSelectDistributor(value)
                //  updateDataGrid(value);
                
                // if(selectedCity.warehouseCountry === 'India' && warehouseData.attributes.warehouseCountry === 'China')
                // {
                //     setDisableTransfer(false)
                // }
                // else
                // setDisableTransfer(true)

               
                
              }}
              onInputChange={(e, value) => {
                // Check if the user's input matches any option
              
              }}
            renderInput={(params) => (
              <TextField
                {...params}
                // disabled={
                //   loading 
                  // || !addNewMode || props.isManager || props.isDeleted
                // }
                label={"Assign Distributor"}
                variant="outlined"
              />
            )}/>
           
          </Stack>
         <Stack direction={"row"} spacing={5} padding={2}>
{status&&
         <Stack direction={"row"} spacing={1} sx={{width:"100%"}} >
              <Typography paddingTop={2} variant="contained">Onboard For ERP ?</Typography>
            <Checkbox variant="contained" checked={changePassword} onChange={(e)=> handleCheckbox(!changePassword)} />
          </Stack>
}
          <TextField label="GST No" value={form?.gstNo || ""} sx={{width:"100%"}} type="text"  onChange={(e)=>changeHandler("gstNo",e.target.value)} inputProps={{ maxLength: 15, style: { textTransform: "uppercase" }}} error={errors.gstNo ? true : false} />

          </Stack>

             
         
     
      
</div>
)}

const handleCheckbox=(val)=>{

setChangePassword(val)
console.log("CHECKVAL",val)
changeHandler("onboardforErp",val)

}

const formatDate=(data)=>{
    console.log("DATAAAA",data   )
    // setDate(data)
    const timestamp = new Date(data.$d);
    
    const datePickerResponse = new Date(data.$d);

    const year = datePickerResponse.getFullYear();
const month = String(datePickerResponse.getMonth() + 1).padStart(2, '0');
const day = String(datePickerResponse.getDate()).padStart(2, '0');
const formattedDate = `${year}-${month}-${day}`;
const unixTimestamp = Math.floor(timestamp.getTime() / 1000);
setDate(data)


changeHandler("incorporationDate",unixTimestamp)
console.log("UNIX",unixTimestamp);
}


  const renderContent = (params) => {
    console.log("CheckTeabRenderContent",tabValue)
    return (
      <div style={{ maxHeight:"510px",height:"505px", overflowY:"auto" }}>
       
          <Stack direction={"row"} spacing={2} padding={2}>
  
          <TextField label="Pincode" value={form?.pincode || ""} type="text" fullWidth 
           required
           error={errors.pincode ? true : false}
           onChange={async(e) => {
            const inputValue = e.target.value;
            const sanitizedValue = inputValue.replace(/[^0-9]/g, '').slice(0, 6);
            if (sanitizedValue.length <= 7) {
              if (sanitizedValue.length === 6) {
        console.log("logg", sanitizedValue);
        await fetchPinCode(sanitizedValue);
      }
       changeHandler("pincode", sanitizedValue);
    }
  
          }}
          
           />
          <TextField required label="State" disabled InputLabelProps={{
            shrink: true,
          }} value={form?.state} type="text" fullWidth />
          </Stack>

          <Stack direction={"row"} spacing={2} padding={2}>

            <TextField required label="District" disabled InputLabelProps={{
              shrink: true,
            }} value={form?.district} type="text" fullWidth />
          

<TextField label="Address Line 1" value={form?.addressLine1} type="text" fullWidth onChange={(e)=>changeHandler("addressLine1",e.target.value)} required error={errors.addressLine1 ? true : false}/>
</Stack>
          <Stack direction={"row"} spacing={2} padding={2}>
            <TextField label="Address Line 2" value={form?.addressLine2} type="text" fullWidth onChange={(e)=>changeHandler("addressLine2",e.target.value)}/>
            <TextField label="Landmark" value={form?.landmark} type="text" fullWidth onChange={(e)=>changeHandler("landmark",e.target.value)}/>
          </Stack>          
          {/* <Stack direction={"row"} spacing={2} padding={1}>

          <TextField label="Location" value={form?.location || ""} type="text" fullWidth onChange={(e)=>changeHandler("location",e.target.value) } />
         
              
          </Stack> */}
          <Stack direction={"row"} spacing={2} padding={2}>
<strong>Bank Details</strong>
</Stack>
         
          <Stack direction={"row"} spacing={2} padding={2}>
          <TextField label="Bank Name" value={form?.bankName || ""} type="text" fullWidth onChange={(e)=>changeHandler("bankName",e.target.value) } required error={errors.bankName ? true : false}/>
          <TextField label="Bank Account Holder name" value={form?.holderName || ""} type="text" fullWidth onChange={(e)=>changeHandler("holderName",e.target.value) } required error={errors.holderName ? true : false} />    

          </Stack>
          <Stack direction={"row"} spacing={2} padding={2}>
            <TextField label="Branch Name" value={form?.branchName || ""} type="text" fullWidth onChange={(e)=>changeHandler("branchName",e.target.value) } required error={errors.branchName ? true : false} />
    
    <TextField label="Bank Account Number" value={form?.accountNo || ""} type="text" fullWidth onChange={(e)=>changeHandler("accountNo",e.target.value) } required error={errors.accountNo ? true : false} />
    </Stack>
            
            <Stack direction={"row"} spacing={2} padding={2}>
              <TextField label="IFSC Code" value={form?.ifsc || ""} type="text" fullWidth onChange={(e)=>changeHandler("ifsc",e.target.value) } required error={errors.ifsc ? true : false} />
            </Stack>

          {/* </Box> */}
        {/* </DataGridContainer> */}
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', margin:"19px" }}>
{status?
 <Button variant="contained" onClick={()=>onsubmit(form)} >
    Create
 </Button>:<Button variant="contained" onClick={()=>onUpdate(form)} >
    Update
 </Button>}
 </div>
      </div>
    );
  };

  const getLeadParty=async(data)=>{
    try{
      setLoading(true)
      const results = await window.Platform.database.getLeadParty({ownerId: data});
      setLead(results.data)
      setLoading(false)

    }
    catch(err){
      console.log(err)
      setLoading(false)

    }
  }

  const getGrade=async(data)=>{
    try{
      setLoading(true)
      const results = await window.Platform.database.getGrade();
      setGrade(results.data)
      setLoading(false)

    }
    catch(err){
      console.log(err)
      setLoading(false)

    }
  }

  const getContactPersonRole=async(data)=>{
    try{
      setLoading(true)
      const results = await window.Platform.database.getContactPersonRole();
      setContactPersonRole(results.data)
      setLoading(false)

    }
    catch(err){
      console.log(err)
      setLoading(false)

    }
  }
  const fetchEmployees=async()=>{
    try{
    setLoading(true)
   const results = await window.Platform.database.getUsersForWork();
   setEmployee(results.data)
   setAssignedEmp(null)
   if(!status){
    if(dataProp.onboardForErp===1){
      setChangePassword(true)
    }
    const filteredEmp=results?.data?.filter((data)=>data.id===dataProp.ownerId)
    console.log("filteredEmp",filteredEmp);
    setAssignedEmp(filteredEmp[0])
   }
   setLoading(false)
  }
  catch(err){
    console.log(err)
  }


  }

  console.log("ASSIGNEDEMP",assignedEmp)
  const fetchDistributors=async(data)=>{
    try{
      setLoading(true)
    const results = await window.Platform.database.getDistributor();
    setDistributor(results.data)
    setSelectDistributor(null)
    if(!status){
      const filteredEmp=results?.data?.filter((data)=>data.distributorID===dataProp.distributorID)
    console.log("filteredEmp",filteredEmp);

      setSelectDistributor(filteredEmp[0])

    }
    setLoading(false)

  }catch(err){
    setLoading(false)

    console.log(err)
  }

  }
  const getBoard=async(data)=>{
    let results = await window.Platform.database.getBoard();
    setBoard(results.data)


  }
  const getMedium=async(data)=>{
    let results = await window.Platform.database.getMedium();
    setMedium(results.data)


  }

  const validateForm = (data) => {
    const gstRegex = /^[0-9]{2}[A-Z]{3}[ABCFGHLJPTF]{1}[A-Z]{1}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/;
    let panRegex = /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/;
    const ifscRegex = /^[A-Z]{4}0[A-Z0-9]{6}$/;
    const errors = {};
 
    if (!data.ownerId?.trim()) {
      errors.assignTo = "Assign to is required";
    }
    
    if (!/\S+@\S+\.\S+/.test(data.email)) {
      errors.email = 'Email is invalid';
    }
 
    if (!data.schoolName?.trim()) {
      errors.schoolName = "School name is required";
    }

    if (!data.incorporationDate) {
      errors.incorporationDate = "Incorporation date is required";
    }
 
    if (!data.board?.trim()) {
      errors.board = "School board is required";
    }

    if (!data.medium?.trim()) {
      errors.medium = "School medium is required";
    }

    if (!selectedGrade?.length) {
      errors.grade = "Grade is required";
    }

    if (!data.affiliationPan?.trim()) {
      errors.affiliationPan = "PAN is required";
    }

    if (data.gstNo?.trim()?.length > 0) {
      if (data.gstNo?.trim()?.length !== 15) {
        errors.gstNo = "GST number must be 15 characters long";
      } else if (!gstRegex.test((data.gstNo?.trim()))) {
        errors.gstNo = "Please type valid GST number";
      }
    }

    if (data.affiliationPan?.trim()?.length !== 10) {
      errors.affiliationPan = "PAN number must be 10 characters long";
    } else if (!panRegex.test((data.affiliationPan?.trim()?.toUpperCase()))) {
      errors.affiliationPan = "Please type valid PAN number";
    }

    if (!data.pincode?.trim()) {
      errors.pincode = "Pin code is required";
    } else if (data.pincode.length < 6) {
      errors.pincode = "Pin should be 6 digits long.";
    }

    if (!data.addressLine1?.trim()) {
      errors.addressLine1 = "AddressLine1 is required";
    }

    if (!data.bankName?.trim()) {
      errors.bankName = "Bank name is required";
    }

    if (!data.holderName?.trim()) {
      errors.holderName = "Holder name is required";
    }

    if (!data.branchName?.trim()) {
      errors.branchName = "Branch name is required";
    }

    if (!data.accountNo?.trim()) {
      errors.accountNo = "Account no is required";
    }

    if (!data.ifsc?.trim()) {
      errors.ifsc = "IFSC is required";
    } else if (!ifscRegex.test((data.ifsc?.trim()?.toUpperCase()))) {
      errors.ifsc = "Please type valid IFSC Code";
    }

    console.log("errors: ", errors)
      
    return errors;
  };

  const onsubmit=async(data)=>{
try{
  // if(!form?.schoolName||!form?.board||!form?.medium||!form?.pincode||!form?.state
  //   ||!form?.district||!form?.addressLine1||!selectedGrade.length||!form.affiliationPan
  //   ||!form?.bankName||!form?.branchName||!form?.accountNo||!form?.ifsc||!form?.holderName){
  //     window.NotificationUtils.showWarning("Please fill all required fields to proceed");
      
  //     return
  //   }
  //   else if(!form.makerContact){
  //     window.NotificationUtils.showWarning("Please Fill Decision Maker Contact For OTP Verification");
      
  //     return
  //   }

    const errors = validateForm(form);
    setErrors(errors);

    form.grade = selectedGrade;

    if(Object.keys(errors).length === 0) {
      setLoading(true);

      if(!form.makerContact){
        window.NotificationUtils.showWarning("Please Fill Decision Maker Contact For OTP Verification");
        setLoading(false);
        return;
      }

      let finalData={...data,onboardforErp:changePassword,grade:selectedGrade};
      // const results = await window.Platform.database.addSchool({...data,onboardforErp:changePassword,grade:selectedGrade});

      console.log(finalData);

      fetchData();
      onClose1(finalData);
      setLoading(false);
      // setOtpModal(true)
    } else {
      setLoading(false);
      window.NotificationUtils.showWarning("Please fill all required fields to proceed");
    }

}
catch(error){
  setLoading(false)
console.log(error)
}
  }

  const onUpdate = async(data) => {
    console.log("EDITDATA",data)
    try{
      // if(!form?.schoolName||!form?.board||!form?.medium||!form?.pincode||!form?.state
      //   ||!form?.district||!form?.addressLine1||!selectedGrade.length||!form.affiliationPan
      //   ||!form?.bankName||!form?.branchName||!form?.accountNo||!form?.ifsc||!form?.holderName){
      //     window.NotificationUtils.showWarning("Please fill all required fields to proceed");
          
      //     return
      //   }

      const errors = validateForm(form);
      setErrors(errors);

      // if (form.startTime || form.endTime) {
      //   form.startTime = dayjs(form.startTime).format("HH:mm:ss");
      //   form.endTime = dayjs(form.endTime).format("HH:mm:ss");
      // }

      if (Object.keys(errors).length === 0) {
        setLoading(true);
        const obj = {...data,id:dataProp.schoolId,onboardforErp:changePassword,grade:selectedGrade};
        delete obj["startTime"];
        delete obj["endTime"];
        const results = await window.Platform.database.editPartySchool(obj);
        window.NotificationUtils.showSuccess("Party updated succesfully.");
        onClose();
        fetchData();
        setLoading(false); 
      } else {
        setLoading(false);
        window.NotificationUtils.showWarning("Please fill all required fields to proceed");
      }
    
    }
    catch(err){
      setLoading(false);
    console.log(err)
    }
    
      }
    
  const fetchPinCode=async(details)=>{
    try{
      setLoading(true)
      // console.log("PLANTCODE",plantcode[0].plant_code__c)
      let results = await window.Platform.database.fetchPinCode(details);
  console.log("pincode: " ,results.data[0].StateName)
        setForm({...form,state:results.data[0].StateName,district:results.data[0].District});
    setLoading(false)
  
  // setDetails(results.data[0]);
  // setProduct(results.data)
  }
  catch(err){
    console.log(err);
  setLoading(false)
   
    }
  }
 

  return (
    <>
    <Dialog disableEnforceFocus maxWidth="md" fullWidth={true} open={true} >
     {loading && <OpaqueLoading/>}
    <ModalHeaderContainer style={{marginBottom:2,paddingBottom:3}}>
       <Typography  variant={isMobile?"h6":"h5"}>
         {status?"Add School":"Edit School"}
         <Tooltip title="Order Id">
 
           {id}
         </Tooltip>
           
           </Typography>
       <IconButton onClick={onClose} >
         <CloseOutlined />
       </IconButton>
     </ModalHeaderContainer>
     <TableContainer>
 
     <TabsContainer>
            <StyledTabs
              variant="fullWidth"
              value={tabValue}
              onChange={ handleChangeTab}
              indicatorColor="primary"
              textColor="primary"
            >
              <Tab label="School Details" />
              <Tab label="Address/Bank Details" />
           
            </StyledTabs>
            {tabValue === 0 && renderContent1()}
{tabValue === 1 && renderContent()}
          </TabsContainer>
   
     </TableContainer>
 
   </Dialog>
   </>
  );
};
 
export default SchoolModal;